import { Schema, DOMParser } from 'prosemirror-model';
import { schema } from 'prosemirror-schema-basic';
import { addListNodes } from 'prosemirror-schema-list';
import { baseKeymap, toggleMark } from 'prosemirror-commands';
import { keymap } from 'prosemirror-keymap';
import { history } from 'prosemirror-history';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import { buildKeymap, buildInputRules } from 'prosemirror-example-setup';
import { wrapInList } from 'prosemirror-schema-list';


const heading = {
    attrs: {
      level: { default: 1 },
      style: { default: '' },
    },
    content: 'inline*',
    group: 'block',
    defining: true,
    parseDOM: [
      { tag: 'h1', getAttrs: node => ({ level: 1, style: node.style.cssText }) },
      { tag: 'h2', getAttrs: node => ({ level: 2, style: node.style.cssText }) },
      { tag: 'h3', getAttrs: node => ({ level: 3, style: node.style.cssText }) },
    ],
    toDOM: node => [
      'h' + node.attrs.level,
      {
        style:
          node.attrs.style +
          (node.attrs.level === 1
            ? 'font-size: 2.2rem;'
            : node.attrs.level === 2
            ? 'font-size: 1.6rem; font-weight: 600;'
            : 'font-size: 1.3rem; font-weight: 600;'),
      },
      0,
    ],
  };
  
  // Define the backgroundColor mark
  const backgroundColor = {
    attrs: {},
    parseDOM: [
      {
        style: 'background-color',
        getAttrs: value => ({ color: '#25ff41' }),
      },
    ],
    toDOM: mark => ['span', { style: `background-color: #25ff41;` }, 0],
  };
  
  // Define the underline mark
  const underline = {
    parseDOM: [
      {
        tag: 'u',
      },
      {
        style: 'text-decoration',
        getAttrs: value => value === 'underline' && null,
      },
    ],
    toDOM: () => ['u', 0],
  };
  
  // Define the bold mark
  const bold = {
    parseDOM: [
      { tag: 'strong' },
      { tag: 'b', getAttrs: value => ({ strong: true }) },
      { style: 'font-weight', getAttrs: value => value === 'bold' && null },
    ],
    toDOM: () => ['strong', 0],
  };
  
  // Define the italics mark
  const italic = {
    parseDOM: [
      { tag: 'i' },
      { tag: 'em' },
      {
        style: 'font-style',
        getAttrs: value => value === 'italic' && null,
      },
    ],
    toDOM: () => ['em', 0],
  };
  
  // Define the quote node
  const blockquote = {
    content: 'block+',
    group: 'block',
    defining: true,
    parseDOM: [{ tag: 'blockquote' }],
    toDOM: () => ['blockquote', 0],
  };
  
  // Define the ordered list node
  const ordered_list = {
    content: 'list_item+',
    group: 'block',
    parseDOM: [{ tag: 'ol' }],
    toDOM: () => ['ol', 0],
  };
  
  // Define the bullet list node
  const bullet_list = {
    content: 'list_item+',
    group: 'block',
    parseDOM: [{ tag: 'ul' }],
    toDOM: () => ['ul', 0],
  };
  
  // Define the list item node
  const list_item = {
    content: 'paragraph block*',
    parseDOM: [{ tag: 'li' }],
    toDOM: () => ['li', 0],
  };
  
  // Create the schema with all features
  const mySchema = new Schema({
    nodes: addListNodes(schema.spec.nodes, 'paragraph block*', 'block').append({
      heading,
      blockquote,
      ordered_list,
      bullet_list,
      list_item,
    }),
    marks: {
      ...schema.spec.marks,
      backgroundColor,
      underline,
      bold,
      italic,
    },
  });
  

// Extract necessary plugins from exampleSetup
const plugins = [
    buildInputRules(mySchema),
    keymap(buildKeymap(mySchema)),
    keymap(baseKeymap),
    dropCursor(),
    gapCursor(),
    history(),
  ];

  export { mySchema, plugins }