import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import {
  VStack,
  Box,
  Heading,
  Text,
  Card,
  Flex,
  Input,
  List,
  ListItem,
  IconButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Kbd,
  Tooltip,
} from '@chakra-ui/react';
import { SearchIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { renderAsync } from 'docx-preview';
import ProseMirrorEditor from './../../components/ProseMirror/index.jsx';
import { handleExportNotes } from '../../components/ProseMirror/exportNotes.jsx';
import ActionButtons from '../../components/TextActions/index.jsx';
import OmniSearch from '../../components/OmniSearch/index.jsx';
import {
  useNoteTakerDispatch,
  useNoteTakerState,
} from '../../components/ProseMirror/NoteTakerState.js';
import VisualTimeline from './VisualTimeline.jsx';
import { highlightAndScrollToText, removeAllSpaces } from './../../helpers/DocHighlightHelper.js'
import './index.scss';

const MultiDocResults = ({ globalState, setGlobalState }) => {
  const { caseID } = useParams();
  const selectionRef = useRef(null);
  const selectionWrapperRef = useRef(null); // Shows the onSelect actions only within this ref
  const docPreviewRef = useRef(null);

  const searchRef = useRef(null);
  const { notes } = useNoteTakerState();

  const [searchParams] = useSearchParams();
  const dispatch = useNoteTakerDispatch();

  const [selectedFunction, setSelectedFunction] = useState(null);
  const [functionResult, setFunctionResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedText, setSelectedText] = useState('');
  const [previewSelectedDocID, setPreviewSelectedDocID] = useState(null);
  const [isTextActionLoading, setIsTextActionLoading] = useState(false);
  const [highlightedText, setHighlightedText] = useState('');
  const [selectedTextAction, setSelectedTextAction] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(0);

  const [isCommandBarOpen, setIsCommandBarOpen] = useState(false);

  const [timelineData, setTimelineData] = useState(null);
  const [isMock, setIsMock] = useState(false);
  const [allTags, setAllTags] = useState([]); // For Omnisearch auto-complete

  useEffect(() => {
    const tagsList = globalState?.documents?.flatMap(doc => doc.tags) || [];
    setAllTags(tagsList);
  }, [globalState.documents]);

  useEffect(() => {
    let isMock = searchParams.get('is_mock');
    if (!isMock) isMock = false;
    else isMock = isMock.toLowerCase() === 'true' ? true : false;
    setIsMock(isMock);
  }, [searchParams]);

  useEffect(() => {
    if (globalState?.documents?.length > 0 && selectedFunction) {
      const firstDoc = globalState.documents[0];
      setPreviewSelectedDocID(firstDoc.id);
      if (docPreviewRef.current) {
        renderAsync(firstDoc.file, docPreviewRef.current).catch(err =>
          console.error(err)
        );
      }
    }
  }, [globalState.documents, selectedFunction]);

  useEffect(() => {
    if (globalState?.documents?.length && previewSelectedDocID) {
      const selectedDoc = globalState.documents.find(
        doc => doc.id === previewSelectedDocID
      );
      if (selectedDoc && docPreviewRef.current) {
        setPreviewSelectedDocID(selectedDoc.id);
        renderAsync(selectedDoc.file, docPreviewRef.current).catch(err =>
          console.error(err)
        );
      }
    }
  }, [previewSelectedDocID, globalState]);

  useEffect(() => {
    if (highlightedText) {
      highlightAndScrollToText(docPreviewRef.current, highlightedText);
    }
  }, [highlightedText]);

  useEffect(() => {
    const handleKeyDown = event => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        toggleCommandBar();
      }
      if (event.key === 'Escape' && isCommandBarOpen) {
        setIsCommandBarOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isCommandBarOpen]);

  const toggleCommandBar = () => {
    setIsCommandBarOpen(!isCommandBarOpen);
  };

  const goToDoc = (doc_id, doc_text) => {
    setPreviewSelectedDocID(doc_id);
    setTimeout(() => {
      const processedText = removeAllSpaces(doc_text);
      highlightAndScrollToText(docPreviewRef.current, processedText);
    }, 100);
  };

  useEffect(() => {
    const fetchFunctionResult = async () => {
      if (selectedFunction) {
        setIsLoading(true);
        try {
          const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/fetch_result`;
          const response = await axios.get(url, {
            params: {
              case_id: caseID,
              key: selectedFunction,
              mock: isMock,
            },
          });
          if (selectedFunction === 'date_events') {
            setTimelineData(response.data.timeline);
            setFunctionResult(response.data.table);
          } else {
            setFunctionResult(response.data);
          }
          setError(null);
        } catch (error) {
          setError('Failed to fetch the result.');
        }
        setIsLoading(false);
      }
    };

    fetchFunctionResult();
  }, [selectedFunction, caseID]);

  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();
      const selectedText = selection.toString();
      const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;

      if (selectedText && range) {
        const commonAncestor = range.commonAncestorContainer;

        if (selectionWrapperRef?.current?.contains(commonAncestor)) {
          // Check if selection is within the specific element
          const rect = range.getBoundingClientRect();
          selectionRef.current.style.top = `${rect.bottom + window.scrollY}px`;
          selectionRef.current.style.left = `${rect.left + window.scrollX}px`;
          setSelectedText(selectedText);
        } else {
          setSelectedText('');
        }
      } else {
        setSelectedText('');
      }
    };

    document.addEventListener('mouseup', handleMouseUp);
    return () => document.removeEventListener('mouseup', handleMouseUp);
  }, []);

  useEffect(() => {
    setFilteredDocs(
      globalState.documents.filter(doc => {
        return doc.file.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  }, [searchTerm, globalState.documents]);

  const appendNotes = async => {
    dispatch({ type: 'ADD_TO_NOTES', payload: selectedText.trim() });
  };

  const appendText = async action => {
    setSelectedTextAction(action);
    if (!selectedText.trim()) return;
    try {
      setIsTextActionLoading(true);
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/text_actions`;
      const payload = {
        text: selectedText,
        action: action,
        caseID: caseID,
        mock: isMock,
        source_function: selectedFunction,
      };

      const response = await axios.post(url, payload);

      if (response.data && response.data.result) {
        const range = window.getSelection().getRangeAt(0);
        const breakBefore = document.createElement('br');
        const newNode = document.createElement('span');

        if (action === 'fact_check' && response.data.citations) {
          let resultText = response.data.result;
          const citationMap = new Map();
          response.data.citations.forEach(citation => {
            const citationText = citation.citation_text;
            const citationID = citation.id;
            const docID = citation.doc_id;
            const docText = citation.doc_text;
            // Use encodeURIComponent and btoa for robust encoding
            const encodedDocText = btoa(encodeURIComponent(docText));
            if (!citationMap.has(citationText)) {
              citationMap.set(citationText, []);
            }
            citationMap
              .get(citationText)
              .push({ citationID, docID, encodedDocText });
            const underlinedText = `<u class="citation-text" data-citation-text="${encodeURIComponent(
              citationText
            )}">${citationText}</u><sup class="citation-id" data-citation-id="${citationID}" data-doc-id="${docID}" data-doc-text="${encodedDocText}">[${citationID}]</sup>`;
            resultText = resultText.replace(
              new RegExp(citationText, 'g'),
              underlinedText
            );
          });
          newNode.innerHTML = resultText;
          newNode.citationMap = citationMap; // Attach the map to the node for later use
        } else {
          newNode.textContent = response.data.result;
        }

        newNode.className = 'text-appended-highlight';
        const breakAfter = document.createElement('br');

        setIsTextActionLoading(false);
        range.collapse(false);
        range.insertNode(breakAfter);
        range.insertNode(newNode);
        range.insertNode(breakBefore);

        // Add event listeners after inserting the node into the DOM
        const citationElements = newNode.querySelectorAll(
          '.citation-text, .citation-id'
        );
        citationElements.forEach(element => {
          element.addEventListener('click', event => {
            event.preventDefault(); // Prevent default behavior
            event.stopPropagation(); // Stop event from bubbling up

            let docID, encodedDocText;
            if (element.classList.contains('citation-text')) {
              const citationText = decodeURIComponent(
                element.getAttribute('data-citation-text')
              );
              const citations = newNode.citationMap.get(citationText);
              if (citations && citations.length > 0) {
                ({ docID, encodedDocText } = citations[0]);
              }
            } else {
              // citation-id
              docID = element.getAttribute('data-doc-id');
              encodedDocText = element.getAttribute('data-doc-text');
            }

            if (docID && encodedDocText) {
              const docText = decodeURIComponent(atob(encodedDocText));
              goToDoc(docID, docText);
            }
          });
        });
      }
      setSelectedText('');
    } catch (error) {
      setIsTextActionLoading(false);
      console.error('Error when calling endpoint:', error);
      setError(error.message);
    }
  };

  const handleSearchKeyboardInteractions = e => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex(prevIndex =>
        Math.min(prevIndex + 1, filteredDocs.length - 1)
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex(prevIndex => Math.max(prevIndex - 1, 0));
    } else if (e.key === 'Enter') {
      setPreviewSelectedDocID(filteredDocs[highlightedIndex].id);
      setSearchTerm('');
    }
  };

  const handleExportCompleteAnalysis = async () => {
    try {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/export?case_id=${caseID}`;
      const response = await axios.get(url, {
        responseType: 'blob', // Important: set the response type to blob
      });
      // Create a Blob from the response data
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      // Create a link element and trigger the download
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = response.headers['content-disposition']
        ? response.headers['content-disposition']
            .split('filename=')[1]
            .replace(/"/g, '')
        : 'case_analysis.docx';
      document.body.appendChild(link);
      link.click();
      link.remove();

      // Clean up the URL object
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error exporting analysis:', error);
      // Handle the error (e.g., show an error message to the user)
    }
  };

  return (
    <div className="page-content-margin audit-view">
      <OmniSearch
        caseID={caseID}
        documents={globalState.documents}
        isOpen={isCommandBarOpen}
        onClose={() => setIsCommandBarOpen(false)}
        allTags={allTags}
      />

      <VStack spacing={4} align="stretch">
        <Heading className="case-title" fontSize={'1.5rem'} color={'#00613c'}>
          {globalState?.case_title || 'NA'}
        </Heading>
        <Box className="export-options">
          <HStack>
            <Tooltip
              label={
                <Box px={1} py={1}>
                  Use
                  <Kbd ml={2} variant={'dark'}>
                    {/Mac|iPod|iPhone|iPad/.test(navigator.userAgent)
                      ? 'Cmd'
                      : 'Ctrl'}
                  </Kbd>{' '}
                  + <Kbd variant={'dark'}>K</Kbd>
                </Box>
              }
            >
              <Button
                px={8}
                variant={'secondary'}
                onClick={toggleCommandBar}
                leftIcon={<SearchIcon mr={0} />}
              >
                Search
              </Button>
            </Tooltip>
            <Menu>
              <MenuButton
                variant={'outline'}
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                Export
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleExportCompleteAnalysis} minH="48px">
                  <span>Export Complete Analysis</span>
                </MenuItem>
                <MenuItem minH="40px" onClick={() => handleExportNotes(notes)}>
                  <span>Export Notes</span>
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Box>
        {selectedFunction ? (
          <Flex>
            <Box className="results-card" w={'100%'} py={0} px={16}>
              <HStack justify={'space-between'}>
                <Box w={'20%'}>
                  <Text
                    className="go-back"
                    mb={6}
                    onClick={() => setSelectedFunction(null)}
                    cursor="pointer"
                  >
                    <svg
                      className="arrow-icon"
                      width="16"
                      height="12"
                      viewBox="0 0 20 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.292893 7.29289C-0.097631 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34314C8.46159 1.95262 8.46159 1.31946 8.07107 0.928931C7.68054 0.538407 7.04738 0.538407 6.65685 0.928931L0.292893 7.29289ZM20 7L1 7L1 9L20 9L20 7Z"
                        fill="#6a6a6a"
                      />
                    </svg>
                    <span>Go Back</span>
                  </Text>
                </Box>
                <Heading w={'80%'} size="sm" mb={4}>
                  Test title
                </Heading>
              </HStack>
              <Box>
                {isLoading ? (
                  <Text>Loading...</Text>
                ) : error ? (
                  <Text color="red">{error}</Text>
                ) : functionResult && selectedFunction ? (
                  <>
                    {selectedFunction === 'date_events' ? (
                      <>
                        <Tabs variant={'default'}>
                          <TabList>
                            <Tab>Visual</Tab>
                            <Tab>Text</Tab>
                          </TabList>

                          <TabPanels>
                            <TabPanel p={0} mt={4}>
                              <VisualTimeline
                                goToDoc={() => goToDoc}
                                timelineData={timelineData}
                              />
                            </TabPanel>
                            <TabPanel p={0} mt={4}>
                              <div
                                ref={selectionWrapperRef}
                                className="results-wrapper"
                                dangerouslySetInnerHTML={{
                                  __html: functionResult,
                                }}
                              />
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </>
                    ) : (
                      <div
                        ref={selectionWrapperRef}
                        className="results-wrapper"
                        dangerouslySetInnerHTML={{ __html: functionResult }}
                      />
                    )}
                  </>
                ) : null}
              </Box>
            </Box>
            <Box className="preview-container">
              <div
                className="topbar"
                style={{ display: 'flex', position: 'relative' }}
              >
                <Box
                  className="file-select-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    overflowY: 'auto',
                  }}
                >
                  {globalState.documents.map(doc => (
                    <div
                      className={`file-select ${
                        doc.id === previewSelectedDocID ? 'selected' : ''
                      }`}
                      key={doc.id}
                      onClick={() => setPreviewSelectedDocID(doc.id)}
                    >
                      {doc.file.name}
                    </div>
                  ))}
                </Box>
                <Box style={{ position: 'relative' }}>
                  {!isExpanded && (
                    <IconButton
                      icon={<SearchIcon />}
                      onClick={() => setIsExpanded(true)}
                      style={{ marginLeft: 'auto', marginRight: '40px' }}
                    />
                  )}
                  {isExpanded && (
                    <motion.div
                      initial={{ width: 0 }}
                      animate={{ width: 200 }}
                      transition={{ duration: 0.3 }}
                      exit={{
                        width: 0,
                        transition: { ease: 'easeInOut', duration: 5 },
                      }}
                    >
                      <Input
                        variant={'default'}
                        placeholder="Search documents"
                        value={searchTerm}
                        onBlur={() => setIsExpanded(false)}
                        onChange={e => setSearchTerm(e.target.value)}
                        onKeyDown={handleSearchKeyboardInteractions}
                        autoFocus={true}
                        ref={searchRef}
                        style={{
                          marginLeft: 'auto',
                          marginRight: '40px',
                          width: '260px',
                        }}
                      />
                      {searchTerm && (
                        <List
                          spacing={2}
                          position="fixed"
                          zIndex={1000}
                          bg="white"
                          width="260px"
                          maxHeight="200px"
                          overflowY="auto"
                          right={'60px'}
                          style={{ border: '1px solid #ccc' }}
                        >
                          {filteredDocs.map((doc, index) => (
                            <ListItem
                              key={doc.id}
                              bg={
                                index === highlightedIndex
                                  ? 'gray.100'
                                  : 'white'
                              }
                              onMouseEnter={() => setHighlightedIndex(index)}
                              p={['4px', '12px']}
                              onClick={() => {
                                setIsExpanded(false);
                                setPreviewSelectedDocID(doc.id);
                                setSearchTerm('');
                              }}
                            >
                              {doc.file.name}
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </motion.div>
                  )}
                </Box>
              </div>
              <Box
                height={'calc(100vh - 124px)'}
                overflowY={'auto'}
                ref={docPreviewRef}
              ></Box>
            </Box>
          </Flex>
        ) : (
          <Flex>
            <Box w={'30%'} mt={14}>
              {/* {globalState.available_functions.map(func => (
                <Card
                  className="function-card"
                  key={func.key}
                  p={4}
                  mx={8}
                  mt={4}
                  borderWidth={1}
                  borderRadius="md"
                  border={'1px solid #dfdfdf'}
                  onClick={() => setSelectedFunction(func.key)}
                  cursor="pointer"
                >
                  <Heading color={'#00613c'} fontSize={'1.4rem'}>
                    {func.title}
                  </Heading>
                  <Text
                    lineHeight={'1.55'}
                    color={'#6a6a6a'}
                    fontWeight={600}
                    mt={2}
                  >
                    {func.description}
                  </Text>
                </Card>
              ))} */}
            </Box>
            <Box w={'calc(70% - 40px)'} h={'100vh'} mt={'73px'}>
              <Card position={'sticky'} top={'50px'} h={'calc(100% - 80px)'}>
                <ProseMirrorEditor isMock={isMock} caseID={caseID} />
              </Card>
            </Box>
          </Flex>
        )}
      </VStack>

      <ActionButtons
        isTextActionLoading={isTextActionLoading}
        selectedTextAction={selectedTextAction}
        selectionRef={selectionRef}
        selectedText={selectedText}
        appendText={appendText}
        appendNotes={appendNotes}
        hideActions={() => setSelectedText(null)}
      />
    </div>
  );
};

export default MultiDocResults;
