import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip, Avatar, Flex, Menu, MenuButton, MenuList, MenuItem, VStack, Divider } from '@chakra-ui/react';

import { AuthContext } from '../helpers/AuthContext';

import AuditHistoryPageIcon from './AuditHistoryPageIcon';
import QuestionAnsweringIcon from './QuestionAnsweringIcon';
import DisputeIntelligenceIcon from './DisputeIntelligenceIcon';
import SettingsIcon from './SettingsIcon';
import DisputeIntelligencePageIcon from './DisputeIntelligencePageIcon';

const paths = [
    // {
    //     key: 'question_answering',
    //     route: '/dispute_analysis',
    //     title: 'Consensus for Disputes',
    //     icon_name: 'QuestionAnsweringIcon'
    // },
    {
        key: 'analysis_history',
        route: '/analysis_history',
        title: 'Cases',
        icon_name: 'AuditHistoryPageIcon'
    },
    // {
    //     key: 'dispute_intelligence',
    //     route: '/dispute_intelligence',
    //     title: 'Dispute Intelligence',
    //     icon_name: 'DisputeIntelligencePageIcon'
    // }
];

const hideHeaderPaths = ['/', '/logout', '/join', '/verify', '/onboarding']

const NavigationHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeItem, setActiveItem] = useState(paths[0].key);
    const [shouldHideHeader, setShouldHideHeader] = useState(false)
    const { userData } = useContext(AuthContext);

    useEffect(() => {
        const url = location.pathname;
        setShouldHideHeader(hideHeaderPaths.includes(url))
        let path_key = "";
        paths.forEach((path) => {
            if (path.route === url) path_key = path.key
        })
        setActiveItem(path_key)
    }, [location.pathname])

    const handleNavigation = (route, key) => {
        setActiveItem(key);
        navigate(route);
    };

    const renderIcon = (iconName, state) => {
        switch (iconName) {
            case 'QuestionAnsweringIcon':
                return <QuestionAnsweringIcon isActive={state === 'active'} />;
            case 'AuditHistoryPageIcon':
                return <AuditHistoryPageIcon isActive={state === 'active'} />;
            case 'SettingsIcon':
                return <SettingsIcon isActive={state === 'active'} />;
            case 'DisputeIntelligencePageIcon':
                return <DisputeIntelligencePageIcon isActive={state === 'active'} />;
            default:
                return null;
        }
    };

    return (
        <>
            {!shouldHideHeader && (
                <div className="navigation-header">
                    <div className="header-logo"></div>
                    <Flex py={5} alignItems={'center'} flexDirection={'column'} align={'center'} justify={'space-between'}>
                        <nav className="navigation-items">
                            {paths.map(({ key, route, title, icon_name }) => (
                                <Tooltip key={key} label={title} placement="right">
                                    <div
                                        className={`nav-item ${activeItem === key ? 'active' : ''}`}
                                        onClick={() => handleNavigation(route, key)}
                                    >
                                        {renderIcon(icon_name, activeItem === key ? 'active' : 'inactive')}
                                        {/* <span>{title}</span> */}
                                    </div>
                                </Tooltip>
                            ))}
                        </nav>

                        <VStack spacing={8} pb={6}>
                            <Divider variant={'default_strong'} />
                            <Tooltip label="Settings" placement="right">
                                <div
                                    className={`nav-item ${activeItem === 'settings' ? 'active' : ''}`}
                                    onClick={() => handleNavigation('/settings', 'settings')}
                                >
                                    {renderIcon('SettingsIcon', activeItem === 'settings' ? 'active' : 'inactive')}
                                </div>
                            </Tooltip>

                            <Menu placement="right">
                                <MenuButton as={Avatar} size="md" src={userData?.profile_picture_url} cursor="pointer" />
                                <MenuList>
                                    <MenuItem fontFamily={'Inter'} onClick={() => navigate('/support')}>Get Support</MenuItem>
                                    <MenuItem fontFamily={'Inter'} color={'red.500'} onClick={() => navigate('/logout')}>Logout</MenuItem>
                                </MenuList>
                            </Menu>
                        </VStack>
                    </Flex>
                </div>
            )}
        </>
    );
}

export default NavigationHeader;
