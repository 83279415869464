import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    HStack,
    Text,
    Avatar,
    Divider,
    Button,
    IconButton,
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Card,
    Tooltip
} from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';

import CommentBox from '../../components/CommentBox';

import { ReactComponent as OptionsIcon } from '../../images/options.svg';
import { ReactComponent as DownloadFileIcon } from '../../images/download.svg';
import { ReactComponent as ReplyIcon } from '../../images/reply.svg';
import activityBodyText from '../../constants/activity_body_text.json';


const ActivityTab = ({ caseID, handleActivityCountUpdate }) => {
    const [activities, setActivities] = useState([]);
    const [replyingTo, setReplyingTo] = useState(null);

    useEffect(() => {
        fetchComments();
    }, []);

    const fetchComments = async () => {
        try {
            const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/case_activities?case_id=${caseID}`;
            const response = await axios.get(url);
            setActivities(response.data);
            handleActivityCountUpdate(response.data.length);
        } catch (error) {
            console.error('Error fetching activities:', error);
        }
    };

    const handleDownloadFile = (fileUrl) => {
        window.open(fileUrl, '_blank');
    };

    const handleReply = (activityId) => {
        if (activityId) {
            setReplyingTo(activityId);
        } else {
            setReplyingTo(null);
        }
    };

    const renderActivityBody = (activity) => {
        if (activity.activity.type === "multiple_files_uploaded") {
            const files = activity.activity.metadata.files;
            const firstTag = files[0].tag;
            const remainingTagsCount = files.length - 1;

            return (
                <Box px={4}>
                    <HStack>
                        <Avatar size="sm" src={activity.profile_picture_url} />
                        <Text>
                            <b style={{ color: '#4b4b4b' }}>{activity.full_name}</b> added case files -{" "} 
                            <b style={{ color: '#4b4b4b' }}>
                            <Tooltip label={firstTag} placement="bottom" hasArrow>
                                <Text
                                    as="span"
                                    maxWidth="250px"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                    display="inline-block"
                                    verticalAlign="top"
                                >
                                    {firstTag}
                                </Text>
                            </Tooltip>
                            </b>
                            {remainingTagsCount > 0 && (
                                <>
                                    {" and "}
                                    <b style={{ color: '#4b4b4b' }}>
                                    <Tooltip label={files.slice(1).map(file => file.tag).join('\n')} placement="bottom" hasArrow>
                                        <Text
                                            as="span"
                                            textDecoration="underline"
                                            cursor="pointer"
                                        >
                                            {remainingTagsCount} other{remainingTagsCount > 1 ? 's' : ''}
                                        </Text>
                                    </Tooltip></b>
                                </>
                            )}
                        </Text>
                        <Text fontSize={'1.2rem'}>·</Text>
                        <Tooltip label={moment(activity.created_at * 1000).format('LLLL')} placement="bottom-start" hasArrow>
                            <Text display={'inline-block'} fontSize="sm" color="gray.500">{moment(activity.created_at * 1000).fromNow()}</Text>
                        </Tooltip>
                    </HStack>
                </Box>
            );
        } else {
            return (
                <Box px={4}>
                    <HStack>
                        <Avatar size="sm" src={activity.profile_picture_url} />
                        <Text>
                            {activityBodyText[activity.activity.type].split(/<\/?b>/).map((part, index) => {
                                const processedPart = part.replace(
                                    /%([^%]+)%/g,
                                    (match, key) => {
                                        const keys = key.split('.');
                                        return keys.reduce((obj, k) => obj?.[k], activity) || match;
                                    }
                                );
                                return index % 2 === 0 ? processedPart : <b style={{ color: '#4b4b4b' }} key={index}>{processedPart}</b>;
                            })}
                        </Text>
                        <Text fontSize={'1.2rem'}>·</Text>
                        <Tooltip label={moment(activity.created_at * 1000).format('LLLL')} placement="bottom-start" hasArrow>
                            <Text display={'inline-block'} fontSize="sm" color="gray.500">{moment(activity.created_at * 1000).fromNow()}</Text>
                        </Tooltip>
                    </HStack>
                </Box>
            );
        }
    }
    

    const renderComment = (activity, replies = [], isReply = false) => (
        isReply ? (
            <Box my={2}>
                <HStack spacing={3} align="start" justifyContent="space-between">
                    <HStack spacing={2} align="center" justifyContent="space-between">
                        <Avatar size="sm" src={activity.profile_picture_url} />
                        <VStack align="start" spacing={1}>
                            <HStack>
                                <Text fontWeight="bold" color={'#4b4b4b'}>{activity.full_name}</Text>
                                <Text fontSize={'1.2rem'}>·</Text>
                                <Text fontSize="sm" color="gray.500">
                                    {moment(activity.created_at * 1000).fromNow()}
                                </Text>
                            </HStack>
                        </VStack>
                    </HStack>
                </HStack>
                <Text mt={2} fontSize={'1.1rem'}>{activity.activity.metadata.comment_body}</Text>
                {activity.activity?.metadata?.attachments && <Flex mt={4}>
                    {activity.activity.metadata.attachments.map((attachment) => {
                        return <Card px={4} py={2} key={attachment.file_id} border={'1px solid #e0e0e0'} borderRadius={'md'}>
                            <HStack align={'center'} justify={'space-between'}>
                                <Text fontSize={'1.1rem'}>{attachment.file_name}</Text>
                                <Tooltip label="Download file" placement="top" hasArrow>
                                    <Button variant={'ghost'} size={'sm'} onClick={() => handleDownloadFile(attachment.presigned_url)}>
                                        <DownloadFileIcon height={'18px'} width={'18px'} fill='#a8a8a8' />
                                    </Button>
                                </Tooltip>
                            </HStack>
                        </Card>
                    })}
                </Flex>}
                {replyingTo === activity._id && (
                    <Box mt={4}>
                        <CommentBox
                            caseID={caseID}
                            fetchComments={fetchComments}
                            parentCommentID={activity._id}
                            onCommentAdded={() => setReplyingTo(null)}
                        />
                    </Box>
                )}
            </Box>
        ) : (
            <Card py={3} px={4} borderRadius="md">
                <HStack spacing={3} align="start" justifyContent="space-between">
                    <HStack spacing={2} align="center" justifyContent="space-between">
                        <Avatar size="sm" src={activity.profile_picture_url} />
                        <VStack align="start" spacing={1}>
                            <HStack>
                                <Text fontWeight="bold" color={'#4b4b4b'}>{activity.full_name}</Text>
                                <Text fontSize={'1.2rem'}>·</Text>
                                <Text fontSize="sm" color="gray.500">
                                    {moment(activity.created_at * 1000).fromNow()}
                                </Text>
                            </HStack>
                        </VStack>
                    </HStack>
                    <HStack>
                        <Button variant={'ghost'} size={'sm'} onClick={() => handleReply(activity._id)}>
                            <ReplyIcon fill='#a8a8a8' />
                        </Button>
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                aria-label='Options'
                                icon={<OptionsIcon fill='#a8a8a8' style={{ transform: 'rotate(90deg)' }} />}
                                variant='ghost'
                                size='sm'
                            />
                            <MenuList>
                                <MenuItem fontFamily={'Inter'}>Edit</MenuItem>
                                <MenuItem fontFamily={'Inter'} color={'red.500'}>Delete</MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>
                </HStack>
                <Text mt={2} fontSize={'1.1rem'}>{activity.activity.metadata.comment_body}</Text>
                {activity.activity?.metadata?.attachments && <Flex mt={4}>
                    {activity.activity.metadata.attachments.map((attachment) => {
                        return <Card px={4} py={2} key={attachment.file_id} border={'1px solid #e0e0e0'} borderRadius={'md'}>
                            <HStack align={'center'} justify={'space-between'}>
                                <Text fontSize={'1.1rem'}>{attachment.file_name}</Text>
                                <Tooltip label="Download file" placement="top" hasArrow>
                                    <Button variant={'ghost'} size={'sm'} onClick={() => handleDownloadFile(attachment.presigned_url)}>
                                        <DownloadFileIcon height={'18px'} width={'18px'} fill='#a8a8a8' />
                                    </Button>
                                </Tooltip>
                            </HStack>
                        </Card>
                    })}
                </Flex>}
                {replies.length > 0 && (
                    <Flex spacing={2} mt={4}>
                        <Box width="4px" borderRadius="full" bg="gray.200" ml={3} mr={6} />
                        <VStack spacing={4} align="stretch" width="100%">
                            {replies.map((reply) => (
                                <Box key={reply._id}>
                                    {renderComment(reply, [], true)}
                                </Box>
                            ))}
                        </VStack>
                    </Flex>
                )}
                {replyingTo === activity._id && (
                    <Box mt={4}>
                        <CommentBox
                            caseID={caseID}
                            fetchComments={fetchComments}
                            parentCommentID={activity._id}
                            onCommentAdded={() => setReplyingTo(null)}
                        />
                    </Box>
                )}
            </Card>
        )
    );

    const organizeActivities = (activities) => {
        const parentComments = [];
        const replies = {};

        activities.forEach(activity => {
            if (activity.activity.type === 'comment_added') {
                const parentId = activity.activity.metadata.parent_comment_id;
                if (parentId) {
                    if (!replies[parentId]) {
                        replies[parentId] = [];
                    }
                    replies[parentId].push(activity);
                } else {
                    parentComments.push(activity);
                }
            } else {
                parentComments.push(activity);
            }
        });

        return parentComments.map(comment => {
            if (comment.activity.type === 'comment_added') {
                return {
                    ...comment,
                    replies: (replies[comment._id] || []).sort((a, b) => a.created_at - b.created_at)
                };
            }
            return comment;
        });
    };

    const organizedActivities = organizeActivities(activities);

    return (
        <Box>
            <VStack spacing={4} align="stretch">
                {organizedActivities.length === 0 ? (
                    <Box textAlign="center" py={8}>
                        <Text fontSize="lg" color="gray.500">
                            No activities yet. Be the first to add a comment!
                        </Text>
                    </Box>
                ) : (
                    organizedActivities.map((activity, index) => (
                        <Box key={index}>
                            {activity.activity.type === 'comment_added' ? (
                                renderComment(activity, activity.replies)
                            ) : (
                                renderActivityBody(activity)
                            )}
                        </Box>
                    ))
                )}

                <Divider />
                <Box position="relative">
                    <CommentBox caseID={caseID} fetchComments={fetchComments} parentCommentID={null} />
                </Box>
            </VStack>
        </Box>
    );
};

export default ActivityTab;
