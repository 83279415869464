import React, { useState, useEffect } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const RenderInconsistencies = ({ data, party, additionalModifiers }) => {
  const [groupedData, setGroupedData] = useState({});
  const [filteredData, setFilteredData] = useState({});

  useEffect(() => {
    if(data && Array.isArray(data) && data.length) {
      const grouped = data.reduce((acc, item) => {
        if (!acc[item.source_document]) {
          acc[item.source_document] = [];
        }
        acc[item.source_document].push(item);
        return acc;
      }, {});
      setGroupedData(grouped);
    } else {
      setGroupedData({});
    }
  }, [data]);

  useEffect(() => {
    if (additionalModifiers && additionalModifiers.length > 0 && Object.keys(groupedData).length > 0) {
      const filtered = Object.keys(groupedData)
        .filter(key => additionalModifiers.includes(key))
        .reduce((obj, key) => {
          obj[key] = groupedData[key];
          return obj;
        }, {});
      setFilteredData(filtered);
    } else {
      setFilteredData(groupedData);
    }
  }, [groupedData, additionalModifiers]);

  return (
    <Box>
      {data?.length ? <>{Object.entries(filteredData).map(([sourceDocument, items]) => (
        <Box key={sourceDocument} mb={6}>
          <Heading as="h2" size="md" mb={4}>
            {sourceDocument} vs {party}'s Pleading
          </Heading>
          {items.map((item, index) => (
            <Box key={index} mb={4}>
              <Text fontWeight="bold" fontSize={'1.2rem'} color={'#004732'} data-section-title={item.contradiction_analysis.title}>
                {item.contradiction_analysis.title}
              </Text>
              <Text fontWeight="bold">Statement from {party}'s Pleading:</Text>
              <Text mb={2} data-section-type={party.toLowerCase()} data-section-document-source={`${party}'s Pleading`}>
                {item.contradiction_analysis.statement}
              </Text>
              <Text fontWeight="bold">Counter Statement from {sourceDocument}:</Text>
              <Text mb={2} data-section-type={sourceDocument} data-section-document-source={sourceDocument}>
                {item.contradiction_analysis.counter_statement}
              </Text>
              <Text fontWeight="bold">Explanation:</Text>
              <Text mb={2} data-section-type="explanation">
                {item.contradiction_analysis.explanation}
              </Text>
              {index < items.length - 1 && <Box as="hr" my={4} />}
            </Box>
          ))}
        </Box>
      ))}</> : <>
        <Text>No inconsistences found within {party}'s Pleading</Text>
      </>}
    </Box>
  );
};

export default RenderInconsistencies;
