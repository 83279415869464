const statusLabels = [
    {
        "id": "case_details_pending",
        "option": "Case Details Pending"
    },{
        "id": "assignee_pending",
        "option": "Assignee Pending"
    },{
        "id": "files_pending",
        "option": "Files Pending"
    },{
        "id": "tags_pending",
        "option": "Tags Pending"
    },{
        "id": "analysis_pending",
        "option": "Analysis Pending"
    }
]

export default statusLabels;