import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Container,
  Card,
  Text,
  Flex,
  HStack,
  VStack,
  Badge,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tooltip,
  Divider,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  List,
  ListItem,
  Avatar
} from '@chakra-ui/react';
import moment from 'moment';

import OverviewTab from './OverviewTab.jsx';
import CaseFilesTab from './CaseFilesTab.jsx';
import ActivityTab from './ActivityTab.jsx';

import SelectDropdown from '../../components/SelectDropdown/index.jsx';
import { ReactComponent as InfoIcon } from '../../images/tell_me_more.svg';
import { ReactComponent as ArrowBackIcon } from '../../images/arrow-back.svg';
import { ReactComponent as AddIcon } from '../../images/add.svg';
import { ReactComponent as HistoryIcon } from '../../images/history.svg';
import './index.scss';

const CaseView = () => {
  const navigate = useNavigate();
  const { caseID } = useParams();
  const [caseDetails, setCaseDetails] = useState(null);
  const [caseActivityCount, setCaseActivityCount] = useState(0);
  const [tabContent, setTabContent] = useState(null);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [externalCollaboratorOptions, setExternalCollaboratorOptions] = useState([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const initialFocusRef = useRef();
  const [analysisHistory, setAnalysisHistory] = useState([]);

  useEffect(() => {
    fetchCaseDetails();
    // TODO: Set it in the state in the case history page and fetch only if it isn't there in the state
    fetchAssignees();
  }, [caseID]);

  useEffect(() => {
    if (caseDetails) {
      fetchAnalysisHistory();
    }
  }, [caseDetails]);

  const handleViewAnalysis = async () => {
    navigate(`/prepare_analysis/${caseID}`);
  }

  const fetchCaseDetails = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/case_details?case_id=${caseID}`);
      setCaseDetails(response.data);
      if (response.data.assigned_to && Object.keys(response.data.assigned_to).length > 0) {
        const transformedSelectedAssignee = {
          id: response.data.assigned_to.id,
          option: response.data.assigned_to.full_name,
          profilePicture: response.data.assigned_to.profile_picture_url
        }
        setSelectedAssignee(transformedSelectedAssignee);
      }
    } catch (error) {
      console.error('Error fetching case details:', error);
    }
  };

  const fetchAnalysisHistory = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/analysis_history?case_id=${caseID}`);
      setAnalysisHistory(response.data);
    } catch (error) {
      console.error('Error fetching analysis history:', error);
    }
  };

  const fetchAssignees = async () => {
    const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_assignees`, {
      params: {
        type: ['member', 'external_collaborator']
      }
    });
    const transformedAssignees = response.data.members.map(member => ({
      id: member._id,
      option: member.full_name,
      profilePicture: member.profile_picture_url
    }));
    const transformedExternalCollaborators = response.data.external_collaborators.map(collaborator => ({
      id: collaborator._id,
      option: collaborator.full_name,
      profilePicture: collaborator.profile_picture_url
    }));
    setAssigneeOptions(transformedAssignees);
    setExternalCollaboratorOptions(transformedExternalCollaborators);
  }

  const handleAssigneeChange = async (assigneeID) => {
    const selectedAssignee = assigneeOptions.find(assignee => assignee.id === assigneeID);
    setSelectedAssignee(selectedAssignee);
    try {
      await axios.put(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/update_case_assignee`, {
        type: 'member',
        case_id: caseID,
        assignee_id: assigneeID
      });
    } catch (error) {
      console.error('Error updating assignee:', error);
    }
  }
  const handleAnalysisHistoryClick = (analysisID) => {
    navigate(`/dispute_analysis_new/${caseID}/${analysisID}`);
  }

  useEffect(() => {
    if (caseDetails) {
      setTabContent(
        <Tabs mt={8} isFitted variant="default">
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Case Files</Tab>
            <Tab>Activity
              {caseActivityCount > 0 && <Badge variant={'default_strong'} ml={2}>{caseActivityCount}</Badge>}
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0} mt={4}>
              <OverviewTab caseID={caseID} caseDetails={caseDetails || []} />
            </TabPanel>
            <TabPanel p={0} mt={10}>
              <CaseFilesTab caseName={caseDetails.case_name} caseID={caseID} />
            </TabPanel>
            <TabPanel p={0} mt={4}>
              <ActivityTab handleActivityCountUpdate={handleActivityCountUpdate} caseID={caseID} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      );
    }
  }, [caseDetails, caseActivityCount]);

  if (!caseDetails) {
    return <div>Loading...</div>;
  }

  const handleActivityCountUpdate = (count) => {
    setCaseActivityCount(count);
  };

  return (
    <div className="page-content-margin">
      <Container maxW={'container.lg'} mt={8}>
        <Flex mb={2}>
          <HStack>
            <Button variant={'ghost'} onClick={() => window.history.back()}>
              <ArrowBackIcon fill='var(--chakra-colors-gray-600)' />
              <Text ml={2} fontSize={'1.1rem'} fontWeight={'600'} color={'gray.600'}>Go Back</Text>
            </Button>
          </HStack>
        </Flex>
        <Card p={[4, 6]}>
          <Box>
            <HStack justifyContent={'space-between'}>
              <VStack alignItems={'flex-start'}>
                <Text variant={'page_title'}>
                  {caseDetails.case_name}
                </Text>
                <Tooltip
                  hasArrow
                  placement="bottom-start"
                  label="Add Case Overview to move to the next step"
                >
                  <Badge mt={2} variant={'case_status'}>
                    <HStack>
                      <InfoIcon fill="#000000" />
                      <Text fontSize={'0.85rem'} fontWeight={'bold'}>
                        {caseDetails.status.text}
                      </Text>
                    </HStack>
                  </Badge>
                </Tooltip>
              </VStack>
              <VStack spacing={3}>
                <Button variant={'secondary'} onClick={handleViewAnalysis} leftIcon={<AddIcon fill={'#fff'} />}>
                  New Analysis
                </Button>
                <Popover
                  isOpen={isPopoverOpen}
                  onClose={() => setIsPopoverOpen(false)}
                  placement='bottom-end'
                  closeOnBlur={false}
                  initialFocusRef={initialFocusRef}
                >
                  <PopoverTrigger>
                    <Button variant={'ghost'} onClick={() => setIsPopoverOpen(true)} leftIcon={<HistoryIcon fill={'var(--chakra-colors-gray-600)'} />}>
                      Analysis History
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent width={'400px'}>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody mt={3}>
                      <List spacing={3}>
                        {analysisHistory.map((analysis, index) => (
                          <ListItem key={index}>
                            <HStack justifyContent={'space-between'}>
                              <VStack alignItems={'flex-start'}>
                                <Text fontWeight={'600'} fontSize={'1.1rem'} color={'black'}>Complete Dispute</Text>
                                <HStack spacing={2}>
                                  <Avatar size={'xs'} src={analysis?.created_by?.profile_picture_url} />
                                  <Text fontSize={'0.9rem'} color={'gray.600'}>{analysis?.created_by?.full_name}</Text>
                                </HStack>

                              </VStack>
                              <Button size="sm" mt={2} onClick={() => handleAnalysisHistoryClick(analysis._id)} ref={index === 0 ? initialFocusRef : null}>
                                View Analysis
                              </Button>
                            </HStack>
                          </ListItem>
                        ))}
                      </List>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </VStack>
            </HStack>
          </Box>
          <Divider mt={4} variant={'default'} />
          <Flex mt={6}>
            <HStack spacing={6}>
              <VStack spacing={1} alignItems={'flex-start'}>
                <Text fontWeight={'600'}>Assignee</Text>
                <SelectDropdown
                  options={assigneeOptions}
                  onChange={(userID) => handleAssigneeChange(userID, 'member')}
                  selectedValue={selectedAssignee}
                />
              </VStack>
              <VStack spacing={1} alignItems={'flex-start'}>
                <Text fontWeight={'600'}>External Collaborators</Text>
                <SelectDropdown
                  isMultiSelect={true}
                  options={externalCollaboratorOptions}
                  onChange={() => { }}
                  value={caseDetails.external_collaborators}
                />
              </VStack>
            </HStack>
          </Flex>
        </Card>
        {tabContent}
      </Container>
    </div>
  );
};

export default CaseView;
