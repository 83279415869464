import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import axios from 'axios';
import {
  EditorState,
  Plugin,
  PluginKey,
  TextSelection,
} from 'prosemirror-state';
import { EditorView, Decoration, DecorationSet } from 'prosemirror-view';

import 'prosemirror-view/style/prosemirror.css';
import 'prosemirror-menu/style/menu.css';
import 'prosemirror-example-setup/style/style.css';
import { useNoteTakerState, useNoteTakerDispatch } from './NoteTakerState';
import { DOMParser } from 'prosemirror-model';
import ToolbarMenuItems from './ToolbarMenu/index.jsx';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  VStack,
  HStack,
  Text,
  Box,
} from '@chakra-ui/react';

import './index.scss';

import { mySchema, plugins } from './editorConfig.js';
import { createSlashMenuPlugin } from './SlashMenuPlugin.js';
import { createPlaceholderPlugin } from './PlaceholderPlugin.js';

const ProseMirrorEditor = ({ caseID, isMock }) => {
  const editorRef = useRef(null);
  const viewRef = useRef(null);
  const toolbarMenuRef = useRef(null);

  const menuButtonRef = useRef(null);
  const { notes } = useNoteTakerState();
  const dispatch = useNoteTakerDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [menuWidth, setMenuWidth] = useState('auto');
  const [selectedText, setSelectedText] = useState('');
  const [isAnswerLoading, setIsAnswerLoading] = useState(false);
  const [isAnswerShown, setIsAnswerShown] = useState(false); // Menu is expanded when answer is shown
  const [answerBody, setAnswerBody] = useState(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [selectedCommand, setSelectedCommand] = useState('');

  useEffect(() => {
    dispatch({
      type: 'SET_CASE_ID',
      payload: caseID
    });
  }, [caseID, dispatch]);

  const initEditor = useCallback(() => {
    const slashMenuPlugin = createSlashMenuPlugin(
      setShowMenu,
      setMenuPosition,
      editorRef,
      menuButtonRef
    );
    const placeholderPlugin = createPlaceholderPlugin();
    const allPlugins = [...plugins, slashMenuPlugin];

    let placeholderPluginExists = allPlugins.find(obj =>
      /placeholder\$\d+$/.test(obj.key)
    );
    if (!placeholderPluginExists) {
      allPlugins.push(placeholderPlugin);
    }

    const state = EditorState.create({
      doc: DOMParser.fromSchema(mySchema).parse(editorRef.current),
      plugins: allPlugins,
    });

    viewRef.current = new EditorView(editorRef.current, {
      state,
      dispatchTransaction(transaction) {
        const newState = viewRef.current.state.apply(transaction);
        viewRef.current.updateState(newState);

        const currentDoc = newState.doc.toJSON();
        const serializedCurrentDoc = JSON.stringify(currentDoc);
        if (serializedCurrentDoc !== notes) {
          dispatch({ type: 'SET_NOTES', payload: serializedCurrentDoc });
        }
      },
    });

    const handleSelection = () => {
      const { from, to } = viewRef?.current?.state?.selection;
      if (from !== to) {
        const { top, bottom, left } = viewRef.current.coordsAtPos(to);
        const editorRect = editorRef.current.getBoundingClientRect();
        setMenuPosition({
          top: bottom - editorRect.top + 78,
          left: left - editorRect.left,
        });
        setShowMenu(true);
        menuButtonRef.current?.focus();
      }
    };

    viewRef.current.dom.addEventListener('mouseup', () => {
      setTimeout(handleSelection, 0);
    });
  }, [setShowMenu, setMenuPosition]);

  const slashMenuPlugin = useMemo(
    () =>
      createSlashMenuPlugin(
        setShowMenu,
        setMenuPosition,
        editorRef,
        menuButtonRef
      ),
    [setShowMenu, setMenuPosition]
  );

  useEffect(() => {
    if (!editorRef.current) return;

    if (!viewRef.current) {
      initEditor();
    }

    return () => {
      if (viewRef.current) {
        const state = viewRef.current.state;
        const newPlugins = state.plugins.filter(
          p => p.key !== slashMenuPlugin.key
        );
        const newState = state.reconfigure({ plugins: newPlugins });
        viewRef.current.updateState(newState);

        viewRef.current.destroy();
        viewRef.current = null;
      }
    };
  }, [initEditor, slashMenuPlugin]);

  useEffect(() => {
    if (editorRef.current && isAnswerShown) {
      setMenuWidth(editorRef.current.offsetWidth - 60);
    }
  }, [editorRef.current, isAnswerShown]);

  // Hide menu when the user hovers over the toolbar
  useEffect(() => {
    const handleMouseEnter = () => {
      if (showMenu && !isAnswerShown) setShowMenu(false);
    };

    const handleEditorClick = () => {
      if (showMenu) setShowMenu(false);
    };

    const toolbar = toolbarMenuRef.current;
    const editor = editorRef.current;

    if (toolbar) {
      toolbar.addEventListener('mouseenter', handleMouseEnter);
    }
    if (editor) {
      editor.addEventListener('click', handleEditorClick);
    }

    return () => {
      if (toolbar) {
        toolbar.removeEventListener('mouseenter', handleMouseEnter);
        editor.removeEventListener('click', handleEditorClick);
      }
    };
  }, [showMenu]);

  useEffect(() => {
    if (viewRef.current && notes) {
      const currentDocJson = JSON.stringify(viewRef.current.state.doc.toJSON());
      if (currentDocJson !== notes) {
        const parsedNotes = typeof notes === 'object' ? notes : JSON.parse(notes);
        const updateState = EditorState.create({
          doc: mySchema.nodeFromJSON(parsedNotes),
          plugins: viewRef.current.state.plugins,
        });
        viewRef.current.updateState(updateState);
      }
    }
  }, [notes]);

  
  const acceptAnswer = async () => {
    const { from, to } = viewRef.current.state.selection;
    const tr = viewRef.current.state.tr;

    try {
      let selectedAnswer;
      if (selectedCommand === 'quote_from_typeset') {
        selectedAnswer = answerBody[carouselIndex].sentence;
        const paragraphNode = mySchema.nodes.paragraph.create(
          null,
          mySchema.text(selectedAnswer)
        );
        const quoteNode = mySchema.nodes.blockquote.create(null, paragraphNode);

        tr.replaceWith(from, to, quoteNode);
      } else {
        selectedAnswer = Array.isArray(answerBody)
          ? answerBody[carouselIndex].sentence
          : answerBody;
        const textNode = mySchema.text(selectedAnswer);
        tr.replaceWith(from, to, textNode);
      }

      viewRef.current.dispatch(tr);
      setShowMenu(false);
      setIsAnswerShown(false);
      setMenuWidth('auto');
    } catch (e) {
      console.error(e);
    }
  };

  const handleMenuSelect = async option => {
    setSelectedCommand(option);
    const { from, to } = viewRef.current.state.selection;
    const selectedText = viewRef.current.state.doc.textBetween(from, to, ' ');
    setSelectedText(selectedText);
    await getAnwerResponse(option, selectedText);
  };

  const getAnwerResponse = async (option, selectedText) => {
    setIsAnswerShown(true);
    setIsAnswerLoading(true);
    const payload = {
      case_id: caseID,
      selected_text: selectedText,
      mock: isMock,
    };
    if (option === 'fill_details') {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/fill_details`;
      const response = await axios.post(url, payload);
      setAnswerBody(response.data.suggestion_body);
      setIsAnswerLoading(false);
    } else if (option === 'quote_from_typeset') {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/quote_from_typeset`;
      const response = await axios.post(url, payload);
      setAnswerBody(response.data.sentences);
      setIsAnswerLoading(false);
    }
  };

  const handleMenuKeyDown = event => {
    if (
      event.key === 'Backspace' ||
      event.key === 'Delete' ||
      event.key === 'Escape'
    ) {
      setShowMenu(false);
      viewRef.current.focus();
    }
  };

  const handleCarouselNavigation = direction => {
    if (direction === 'next') {
      setCarouselIndex(prevIndex => (prevIndex + 1) % answerBody.length);
    } else if (direction === 'prev') {
      setCarouselIndex(
        prevIndex => (prevIndex - 1 + answerBody.length) % answerBody.length
      );
    }
  };

  useEffect(() => {
    const handleKeyDown = async event => {
      if (event.key === 'Enter' && isAnswerShown) {
        await acceptAnswer();
        event.preventDefault();
      } else if (event.key === 'ArrowRight' && isAnswerShown) {
        handleCarouselNavigation('next');
        event.preventDefault();
      } else if (event.key === 'ArrowLeft' && isAnswerShown) {
        handleCarouselNavigation('prev');
        event.preventDefault();
      } else if (event.key === 'Escape' && isAnswerShown) {
        setAnswerBody(null);
        setMenuWidth('auto');
        setShowMenu(false);
        setIsAnswerShown(false);
        setCarouselIndex(0);
      } else if (event.key === 'Escape' && showMenu) {
        setMenuWidth('auto');
        setShowMenu(false);
      }
    };

    const updateTransform = () => {
      if (isAnswerShown && menuButtonRef.current) {
        const siblingElement = menuButtonRef.current.nextElementSibling;
        if (siblingElement && siblingElement.style.transform) {
          const transform = siblingElement.style.transform;
          const regex =
            /translate3d\(([^,]+),([^,]+),([^)]+)\)|translate\(([^,]+),([^)]+)\)/;
          const match = transform.match(regex);

          if (match) {
            const [, x3d, y3d, z3d, x2d, y2d] = match;
            const x = x3d || x2d || '0px';
            const y = y3d || y2d || '0px';
            const z = z3d || '0px';
            let updatedY = Number(y.split('px')[0].trim()) + 24;
            siblingElement.style.setProperty(
              'transform',
              `translate3d(48px, ${updatedY}px, 0px)`,
              'important'
            );
          }
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    const timeoutId = setTimeout(updateTransform, 100);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      clearTimeout(timeoutId);
    };
  }, [
    isAnswerShown,
    carouselIndex,
    showMenu,
    answerBody,
    handleCarouselNavigation,
  ]);

  return (
    <div className="editor-wrapper">
      <div className="toolbar-menu-wrapper" ref={toolbarMenuRef}>
        <ToolbarMenuItems schema={mySchema} view={viewRef} editor={editorRef} />
      </div>
      <div ref={editorRef} className="prosemirror-editor" />
      {showMenu && (
        <Menu
          className={`command-menu`}
          closeOnSelect={true}
          closeOnBlur={true}
          isOpen={showMenu}
          placement="bottom"
          autoSelect={false}
          aria-describedby="command_menu"
        >
          <MenuButton
            ref={menuButtonRef}
            as={Button}
            id="command_menu_button"
            style={{
              position: 'absolute',
              top: menuPosition.top,
              left: menuPosition.left,
              width: 0,
              height: 0,
              opacity: 0,
            }}
            onKeyDown={handleMenuKeyDown}
          />
          <MenuList
            className="options"
            style={{
              width: menuWidth - 40,
              // transform: isAnswerShown ? 'translateX(120px)' : undefined
            }}
          >
            {isAnswerShown ? (
              <div className="note-assistant-answer">
                {isAnswerLoading ? (
                  <div className="answer-loader">
                    <div className="loading-items">
                      <div className="loading">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <Text className="loading-text">
                      Referring documents in typeset
                    </Text>
                  </div>
                ) : (
                  <>
                    {Array.isArray(answerBody) ? (
                      <div className="carousel">
                        <Text className="answer">
                          {answerBody[carouselIndex].sentence}
                        </Text>
                        <Text className="source">
                          Source: {answerBody[carouselIndex].tags.join(' / ')}
                        </Text>
                        <div className="option-navigation">
                          <HStack>
                            <button
                              className="carousel-arrow"
                              onClick={() => handleCarouselNavigation('prev')}
                            >
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.3333 12.6L6.77478 8L11.3333 3.4L9.94595 2L4 8L9.94595 14L11.3333 12.6Z"
                                  fill="black"
                                />
                              </svg>
                            </button>
                            <Text>{carouselIndex + 1} / 5</Text>
                            <button
                              className="carousel-arrow"
                              onClick={() => handleCarouselNavigation('next')}
                            >
                              <svg
                                style={{ transform: 'rotate(180deg)' }}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.3333 12.6L6.77478 8L11.3333 3.4L9.94595 2L4 8L9.94595 14L11.3333 12.6Z"
                                  fill="black"
                                />
                              </svg>
                            </button>
                          </HStack>
                        </div>
                        <div className="instructions">
                          <div className="icon">
                            <svg
                              role="graphics-symbol"
                              viewBox="0 0 16 16"
                              className="enter"
                            >
                              <path d="M5.38965 14.1667C5.81812 14.1667 6.10156 13.8767 6.10156 13.468C6.10156 13.2571 6.01587 13.0989 5.89062 12.967L4.18994 11.3125L3.02979 10.3369L4.55908 10.4028H12.7922C14.4402 10.4028 15.1389 9.65796 15.1389 8.04297V4.13403C15.1389 2.48608 14.4402 1.78735 12.7922 1.78735H9.13379C8.70532 1.78735 8.4021 2.11035 8.4021 2.50586C8.4021 2.90137 8.69873 3.22437 9.13379 3.22437H12.7593C13.4316 3.22437 13.7151 3.50781 13.7151 4.17358V7.99683C13.7151 8.67578 13.425 8.95923 12.7593 8.95923H4.55908L3.02979 9.03174L4.18994 8.04956L5.89062 6.39502C6.01587 6.26978 6.10156 6.11157 6.10156 5.89404C6.10156 5.48535 5.81812 5.19531 5.38965 5.19531C5.21167 5.19531 5.01392 5.27441 4.8689 5.41943L1.08521 9.1438C0.933594 9.28882 0.854492 9.48657 0.854492 9.68433C0.854492 9.87549 0.933594 10.0732 1.08521 10.2183L4.8689 13.9492C5.01392 14.0876 5.21167 14.1667 5.38965 14.1667Z"></path>
                            </svg>
                          </div>
                          <span>Accept Suggestion</span>
                        </div>
                      </div>
                    ) : (
                      <Box>
                        <Text className="answer">{answerBody}</Text>
                        <div className="instructions">
                          <div className="icon">
                            <svg
                              role="graphics-symbol"
                              viewBox="0 0 16 16"
                              className="enter"
                            >
                              <path d="M5.38965 14.1667C5.81812 14.1667 6.10156 13.8767 6.10156 13.468C6.10156 13.2571 6.01587 13.0989 5.89062 12.967L4.18994 11.3125L3.02979 10.3369L4.55908 10.4028H12.7922C14.4402 10.4028 15.1389 9.65796 15.1389 8.04297V4.13403C15.1389 2.48608 14.4402 1.78735 12.7922 1.78735H9.13379C8.70532 1.78735 8.4021 2.11035 8.4021 2.50586C8.4021 2.90137 8.69873 3.22437 9.13379 3.22437H12.7593C13.4316 3.22437 13.7151 3.50781 13.7151 4.17358V7.99683C13.7151 8.67578 13.425 8.95923 12.7593 8.95923H4.55908L3.02979 9.03174L4.18994 8.04956L5.89062 6.39502C6.01587 6.26978 6.10156 6.11157 6.10156 5.89404C6.10156 5.48535 5.81812 5.19531 5.38965 5.19531C5.21167 5.19531 5.01392 5.27441 4.8689 5.41943L1.08521 9.1438C0.933594 9.28882 0.854492 9.48657 0.854492 9.68433C0.854492 9.87549 0.933594 10.0732 1.08521 10.2183L4.8689 13.9492C5.01392 14.0876 5.21167 14.1667 5.38965 14.1667Z"></path>
                            </svg>
                          </div>
                          <span>Accept Suggestion</span>
                        </div>
                      </Box>
                    )}
                  </>
                )}
              </div>
            ) : (
              <>
                <Text className="menu-title">
                  Document Actions for selection
                </Text>
                <MenuItem
                  className="option-item"
                  onClick={() => handleMenuSelect('fill_details')}
                >
                  <HStack>
                    <div className="icon">
                      <svg role="graphics-symbol" viewBox="0 0 16 16">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.2757 4.82358C7.57934 4.71847 7.57934 4.53161 7.2757 4.41483L5.62905 3.78419C5.33709 3.67908 4.99842 3.3404 4.88164 3.03676L4.25101 1.39009C4.1459 1.08644 3.95905 1.08644 3.84226 1.39009L3.21163 3.03676C3.10653 3.32872 2.76786 3.6674 2.46422 3.78419L0.817572 4.41483C0.513934 4.51994 0.513934 4.70679 0.817572 4.82358L2.46422 5.45422C2.75618 5.55933 3.09485 5.898 3.21163 6.20165L3.84226 7.84832C3.94737 8.15196 4.13422 8.15196 4.25101 7.84832L4.88164 6.20165C4.98674 5.90968 5.32541 5.571 5.62905 5.45422L7.2757 4.82358ZM15.2991 10.5929C16.2334 10.3593 16.2334 9.9739 15.2991 9.74032L13.2321 9.22647C12.2978 8.9929 11.3402 8.03526 11.1066 7.10097L10.5928 5.03387C10.3592 4.09959 9.97382 4.09959 9.74025 5.03387L9.2264 7.10097C8.99283 8.03526 8.03521 8.9929 7.10094 9.22647L5.03387 9.74032C4.09961 9.9739 4.09961 10.3593 5.03387 10.5929L7.10094 11.1067C8.03521 11.3403 8.99283 12.2979 9.2264 13.2322L9.74025 15.2993C9.97382 16.2336 10.3592 16.2336 10.5928 15.2993L11.1066 13.2322C11.3402 12.2979 12.2978 11.3403 13.2321 11.1067L15.2991 10.5929Z"
                        ></path>
                      </svg>
                    </div>
                    <VStack justify={'start'} align={'start'} spacing={0}>
                      <span className="heading">Fill Details</span>
                      <span className="subtext">
                        Rewrite to expand key details
                      </span>
                    </VStack>
                  </HStack>
                </MenuItem>
                <MenuItem
                  className="option-item"
                  onClick={() => handleMenuSelect('ask_question')}
                >
                  <HStack>
                    <div className="icon">
                      <svg role="graphics-symbol" viewBox="0 0 16 16">
                        <path d="M8 15.126C4.1377 15.126 0.938477 11.9336 0.938477 8.06445C0.938477 4.20215 4.13086 1.00293 7.99316 1.00293C11.8623 1.00293 15.0615 4.20215 15.0615 8.06445C15.0615 11.9336 11.8623 15.126 8 15.126ZM8 13.7383C11.1445 13.7383 13.6738 11.209 13.6738 8.06445C13.6738 4.91992 11.1377 2.39746 7.99316 2.39746C4.84863 2.39746 2.33301 4.91992 2.33301 8.06445C2.33301 11.209 4.85547 13.7383 8 13.7383ZM7.84277 9.4248C7.43262 9.4248 7.2002 9.22656 7.2002 8.84375V8.76855C7.2002 8.14648 7.55566 7.79102 8.04102 7.45605C8.62207 7.05273 8.90918 6.84082 8.90918 6.41016C8.90918 5.94531 8.5332 5.61719 7.97949 5.61719C7.56934 5.61719 7.24121 5.81543 7.01562 6.19141L6.90625 6.32812C6.76953 6.5127 6.60547 6.61523 6.35254 6.61523C6.0791 6.61523 5.81934 6.41016 5.81934 6.08203C5.81934 5.95898 5.83984 5.84277 5.88086 5.72656C6.09277 5.08398 6.89258 4.55078 8.06152 4.55078C9.27148 4.55078 10.2969 5.2002 10.2969 6.3418C10.2969 7.13477 9.85938 7.52441 9.18945 7.96875C8.74512 8.25586 8.49902 8.49512 8.47852 8.85059C8.47852 8.87109 8.47852 8.90527 8.47852 8.93262C8.45801 9.21289 8.21875 9.4248 7.84277 9.4248ZM7.83594 11.5234C7.39844 11.5234 7.03613 11.2021 7.03613 10.7715C7.03613 10.3477 7.3916 10.0264 7.83594 10.0264C8.27344 10.0264 8.63574 10.3408 8.63574 10.7715C8.63574 11.2021 8.27344 11.5234 7.83594 11.5234Z"></path>
                      </svg>
                    </div>
                    <VStack justify={'start'} align={'start'} spacing={0}>
                      <span className="heading">Ask a question</span>
                      <span className="subtext">
                        Surface the answer to a question
                      </span>
                    </VStack>
                  </HStack>
                </MenuItem>
                <MenuItem
                  className="option-item"
                  onClick={() => handleMenuSelect('quote_from_typeset')}
                >
                  <HStack>
                    <div className="icon">
                      <svg role="graphics-symbol" viewBox="0 0 16 16">
                        <path d="M2.87305 7.23047C1.79297 7.23047 0.96582 6.40332 0.96582 5.13184C0.96582 3.375 2.33984 2.06934 3.88477 2.06934C4.2334 2.06934 4.50684 2.25391 4.50684 2.5752C4.50684 2.83496 4.30176 3.04004 4.03516 3.06055C3.05078 3.1084 2.33301 3.7168 2.12109 4.63965C2.33984 4.33203 2.66797 4.18164 3.07812 4.18164C3.90527 4.18164 4.51367 4.77637 4.51367 5.67188C4.51367 6.6084 3.77539 7.23047 2.87305 7.23047ZM7.13867 7.23047C6.06543 7.23047 5.23828 6.40332 5.23828 5.13184C5.23828 3.375 6.61914 2.06934 8.15723 2.06934C8.5127 2.06934 8.7793 2.25391 8.7793 2.5752C8.7793 2.83496 8.57422 3.04004 8.30078 3.06055C7.32324 3.1084 6.60547 3.7168 6.40039 4.63965C6.6123 4.3252 6.94727 4.18164 7.35059 4.18164C8.17773 4.18164 8.78613 4.77637 8.78613 5.67188C8.78613 6.6084 8.04785 7.23047 7.13867 7.23047ZM10.6045 3.27246C10.2695 3.27246 10.0029 3.00586 10.0029 2.6709C10.0029 2.33594 10.2695 2.06934 10.6045 2.06934H14.4395C14.7744 2.06934 15.0479 2.33594 15.0479 2.6709C15.0479 3.00586 14.7744 3.27246 14.4395 3.27246H10.6045ZM10.6045 6.85449C10.2695 6.85449 10.0029 6.58789 10.0029 6.25293C10.0029 5.91797 10.2695 5.65137 10.6045 5.65137H14.4395C14.7744 5.65137 15.0479 5.91797 15.0479 6.25293C15.0479 6.58789 14.7744 6.85449 14.4395 6.85449H10.6045ZM1.55371 10.4365C1.21875 10.4365 0.952148 10.1699 0.952148 9.83496C0.952148 9.5 1.21875 9.2334 1.55371 9.2334H14.4395C14.7744 9.2334 15.0479 9.5 15.0479 9.83496C15.0479 10.1699 14.7744 10.4365 14.4395 10.4365H1.55371ZM1.55371 14.0117C1.21875 14.0117 0.952148 13.752 0.952148 13.417C0.952148 13.0752 1.21875 12.8154 1.55371 12.8154H14.4395C14.7744 12.8154 15.0479 13.0752 15.0479 13.417C15.0479 13.752 14.7744 14.0117 14.4395 14.0117H1.55371Z"></path>
                      </svg>
                    </div>
                    <VStack justify={'start'} align={'start'} spacing={0}>
                      <span className="heading">Quote from Typeset</span>
                      <span className="subtext">
                        Surface related text from the document
                      </span>
                    </VStack>
                  </HStack>
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      )}
    </div>
  );
};

export default ProseMirrorEditor;
