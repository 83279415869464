import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
  UnderlineType,
} from 'docx';
import { saveAs } from 'file-saver';

export const handleExportNotes = async notes => {
  if (notes) {
    try {
      const doc = new Document({
        sections: [],
        numbering: {
          config: [
            {
              reference: 'my-numbering',
              levels: [
                {
                  level: 0,
                  format: 'decimal',
                  text: '%1.',
                  alignment: AlignmentType.START,
                  style: {
                    paragraph: {
                      indent: { left: 720, hanging: 360 },
                    },
                  },
                },
              ],
            },
          ],
        },
      });

      // Parse the JSON if it's a string
      const content =
        typeof notes === 'string' ? JSON.parse(notes).content : notes.content;

      // Filter out any undefined or null elements
      const filteredContent = content.filter(node => node != null);

      // Helper function to convert ProseMirror marks to DOCX text formatting
      const applyMarksToTextRun = (text, marks) => {
        let options = { text };
        marks.forEach(mark => {
          switch (mark.type) {
            case 'bold':
              options.bold = true;
              break;
            case 'italic':
              options.italic = true;
              break;
            case 'underline':
              options.underline = { type: UnderlineType.SINGLE };
              break;
            case 'backgroundColor':
              options.highlight = 'yellow'; // docx doesn't support exact color
              break;
            default:
              break;
          }
        });
        return new TextRun(options);
      };

      console.log('CONTENT HERE', filteredContent);

      // Convert ProseMirror JSON to docx elements
      const docxElements = filteredContent.map(node => {
        try {
          if (!node || !node.type) {
            console.warn('Encountered invalid node:', node);
            return new Paragraph('');
          }

          switch (node.type) {
            case 'paragraph':
              return new Paragraph({
                children: (node.content || []).map(inline => {
                  if (inline && inline.type === 'text') {
                    return applyMarksToTextRun(inline.text, inline.marks || []);
                  }
                  return new TextRun('');
                }),
              });
            case 'heading':
              return new Paragraph({
                heading: HeadingLevel[`HEADING_${node.attrs.level}`],
                children: (node.content || []).map(inline => {
                  if (inline && inline.type === 'text') {
                    return applyMarksToTextRun(inline.text, inline.marks || []);
                  }
                  return new TextRun('');
                }),
              });
            case 'blockquote':
              return new Paragraph({
                style: 'Quote',
                children: (node.content || []).flatMap(para =>
                  (para.content || []).map(inline => {
                    if (inline && inline.type === 'text') {
                      return applyMarksToTextRun(
                        inline.text,
                        inline.marks || []
                      );
                    }
                    return new TextRun('');
                  })
                ),
              });
            case 'bullet_list':
              return (node.content || []).map(
                item =>
                  new Paragraph({
                    bullet: {
                      level: 0,
                    },
                    children: (item.content || []).flatMap(para =>
                      (para.content || []).map(inline => {
                        if (inline && inline.type === 'text') {
                          return applyMarksToTextRun(
                            inline.text,
                            inline.marks || []
                          );
                        }
                        return new TextRun('');
                      })
                    ),
                  })
              );
            case 'ordered_list':
              return (node.content || []).map(
                item =>
                  new Paragraph({
                    numbering: {
                      reference: 'my-numbering',
                      level: 0,
                    },
                    children: (item.content || []).flatMap(para =>
                      (para.content || []).map(inline => {
                        if (inline && inline.type === 'text') {
                          return applyMarksToTextRun(
                            inline.text,
                            inline.marks || []
                          );
                        }
                        return new TextRun('');
                      })
                    ),
                  })
              );
            default:
              return new Paragraph('');
          }
        } catch (error) {
          console.error('Error processing node:', node, error);
          return new Paragraph('');
        }
      });

      // Flatten the array of elements (for nested structures like lists)
      const flatDocxElements = docxElements.flat();

      doc.addSection({
        properties: {},
        children: flatDocxElements,
      });

      // Generate and save the file
      const blob = await Packer.toBlob(doc);
      saveAs(blob, 'notes.docx');
    } catch (e) {
      console.log('ERROR HERE', e);
    }
  } else {
    alert('No notes to export');
  }
};
