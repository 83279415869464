import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Flex,
  Input,
  Stack,
  Button,
  FormControl,
  FormHelperText,
  PinInput,
  PinInputField,
  useToast
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import ButtonForwardIcon from './../../components/ButtonForwardIcon';
import { setCookie, getCookie } from './../../helpers/cookies';
import './index.scss';

import AdidemLogoFull from '../../images/adidem-logo-full.svg'

const Home = () => {
  const navigate = useNavigate();
  const toast = useToast();
  
  const [isLoading, setIsLoading] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [enteredEmail, setEnteredEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [linkRequestCount, setLinkRequestCount] = useState(0);
  const [isRequestDisabled, setRequestDisabled] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (linkRequestCount > 0) {
      setRequestDisabled(true);
      timeoutId = setTimeout(() => setRequestDisabled(false), 300000); // 5 minutes timeout
    }
    return () => clearTimeout(timeoutId);
  }, [linkRequestCount]);

  useEffect(() => {
    const doesAuthCookieExist = getCookie('auth-token');
    if(doesAuthCookieExist) {
      navigate('/dispute_analysis')
    }
  }, [navigate])

  const handleEmailChange = e => {
    setEnteredEmail(e.target.value);
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  const handleLoginClick = async () => {
    setIsLoading(true);
    if (!emailError && enteredEmail !== '' && linkRequestCount < 3) {
      setLinkRequestCount(prevCount => prevCount + 1);
      try {
        const url = `${process.env.REACT_APP_BASE_PATH}/v1/login`;
        const payload = {
          email: enteredEmail,
        };
        await axios.post(url, payload);
        setIsLinkSent(true);
      } catch (e) {
        console.log("RESPONSE ERROR");
        console.log('Error details:', e.response?.data, e.message);
        
        const errorMessage = e.response?.data?.error || 'Unable to send magic link. Please try again.';
        
        toast({
          title: 'Error sending magic link',
          description: errorMessage,
          status: 'error',
          duration: 10000,
          isClosable: false,
        });
      }
    } else if (linkRequestCount >= 3) {
      toast({
        title: 'Too many requests',
        description: 'You have reached the maximum number of magic link requests. Please try again later.',
        status: 'error',
        duration: 10000,
        isClosable: false,
      });
    }
    setIsLoading(false);
  };

  const RenderEmailLogin = () => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !emailError && enteredEmail !== '') {
        handleLoginClick();
      }
    };
  
    return (
      <Box width={'100%'} maxWidth={'450px'}>
        <Text fontSize="2em" fontFamily={'Garnett'} fontWeight={600} color={'#000'}>Welcome to Consensus</Text>
        <Text fontSize="1em" marginBottom={12}>Dispute Intelligence platform to accelerate case comprehension and resolution</Text>
        <Stack spacing={6}>
          <FormControl isInvalid={emailError}>
            <Input
              variant={'default_highlighted'}
              type="email"
              placeholder="Email"
              value={enteredEmail}
              onChange={handleEmailChange}
              onKeyDown={handleKeyDown}
              isInvalid={emailError}
              errorBorderColor="red.300"
            />
            <FormHelperText mt={4} mb={4}>
              By signing up to the service, you agree to be subject to our
              privacy policy.
            </FormHelperText>
          </FormControl>
          <Button
            isLoading={isLoading}
            variant={'default'}
            rightIcon={<ButtonForwardIcon />}
            onClick={handleLoginClick}
            p={6}
            isDisabled={emailError || enteredEmail === '' || isRequestDisabled}
          >
            Get started
          </Button>
        </Stack>
      </Box>
    );
  };
  
  const RenderLinkSentMessage = () => {
    return (
      <Box width={'100%'} maxWidth={'450px'}>
        <Text fontSize="2em" fontFamily={'Garnett'} fontWeight={600} color={'#000'} mb={4}>Check your email</Text>
        <Text fontSize="1em" mb={8}>
          We've sent a magic link to <Text as={'b'} fontWeight={700}>{enteredEmail}</Text>. 
          Click the link in the email to sign in to your account.
        </Text>
        <Text fontSize="1em" mb={4}>
          {isRequestDisabled ? (
            "You can request a new magic link in a few minutes."
          ) : (
            <>
              Didn't receive the email?{' '}
              <Text as="span" textDecoration="underline" cursor="pointer" onClick={handleLoginClick}>
                Send it again
              </Text>
            </>
          )}
        </Text>
      </Box>
    );
  };

  return (
    <Flex className='login' w={'100%'}>
      <Box className="login-left-container">
        <VStack spacing={2} maxWidth={'66%'}>
          {/* You can add any content for the left container here */}
        </VStack>
      </Box>
      <Flex className='login-right-container'>
        <img className='adidem-logo-full' alt='Adidem Logo' src={AdidemLogoFull} />
        {isLinkSent ? RenderLinkSentMessage() : RenderEmailLogin()}
      </Flex>
    </Flex>
  );
};

export default Home;
