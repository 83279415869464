
import {
    Plugin,
    PluginKey,
    TextSelection,
  } from 'prosemirror-state';

  const createSlashMenuPlugin = (setShowMenu, setMenuPosition, editorRef, menuButtonRef) => {
    return new Plugin({
      key: new PluginKey('slashMenu'),
      props: {
        handleKeyDown(view, event) {
          if (event.key === '/') {
            event.preventDefault();
            const { state } = view;
            const { tr } = state;
            const { $from } = tr.selection;
            const start = $from.start($from.depth);
            const end = $from.pos;
            tr.setSelection(TextSelection.create(tr.doc, start, end));
            view.dispatch(tr);
            setTimeout(() => {
              const { bottom, left } = view.coordsAtPos(end);
              if (editorRef.current) {
                const editorRect = editorRef.current.getBoundingClientRect();
                setMenuPosition({
                  top: bottom - editorRect.top + 78,
                  left: left - editorRect.left,
                });
              }
              setShowMenu(true);
              menuButtonRef.current?.focus();
            }, 50);
            return true;
          }
          return false;
        },
      },
    });
  };

export {createSlashMenuPlugin};