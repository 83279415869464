import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    VStack,
    HStack,
    Input,
    Text,
    Image,
    Button,
    useToast,
    Container,
    Heading,
    FormControl,
    Card,
} from '@chakra-ui/react';
import { useContext } from 'react';
import { AuthContext } from '../../helpers/AuthContext';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

import { ReactComponent as BusyWorkIllustration } from '../../images/onboarding/busy_work.svg';
import { ReactComponent as OrganiseDisputesIllustration } from '../../images/onboarding/organise_disputes.svg';
import { ReactComponent as UnifiedPlatformIllustration } from '../../images/onboarding/unified_platform.svg';
import { ReactComponent as ArrowBackIcon } from '../../images/arrow-back.svg';

const Onboarding = () => {
    const { userData, setUserData } = useContext(AuthContext);
    const [step, setStep] = useState(1);
    const [fullName, setFullName] = useState('');
    const [role, setRole] = useState('');
    const toast = useToast();
    const navigate = useNavigate();
    
    const profilePictureUrl = useMemo(() => {
        const encodedFullName = encodeURIComponent(fullName.trim());
        return `https://api.dicebear.com/9.x/notionists/svg?seed=${encodedFullName}&backgroundColor=b6e3f4,c0aede,d1d4f9`;
    }, [fullName]);

    const handleNextStep = () => {
        if (step < 4) {
            setStep(step + 1);
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        try {
            const formData = {
                profile_picture_url: profilePictureUrl,
                full_name: fullName,
                role: role
            };
            console.log(formData)

            await axios.post(`${process.env.REACT_APP_BASE_PATH}/v1/onboard`, formData);

            setUserData({ ...userData, is_onboarded: true });
            toast({
                title: 'Onboarding complete',
                description: 'Your profile has been updated successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            navigate('/');
        } catch (error) {
            console.error('Error during onboarding:', error);
            toast({
                title: 'Onboarding failed',
                description: 'There was an error updating your profile. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const pageVariants = {
        initial: { opacity: 0, x: -50 },
        in: { opacity: 1, x: 0 },
        out: { opacity: 0, x: 50 },
    };

    const pageTransition = {
        type: 'tween',
        ease: 'anticipate',
        duration: 0.5,
    };

    const getHeading = () => {
        switch (step) {
            case 1:
                return "Tell us about yourself";
            case 2:
                return "What Consensus can do for you";
            case 3:
                return "What Consensus can do for you";
            case 4:
                return "What Consensus can do for you";
            default:
                return "Let's set up your profile";
        }
    };

    return (
        <Container maxW="container.sm" py={10}>
            <VStack spacing={8} align="stretch">
                <motion.div
                    key={step}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                    <Heading fontFamily={'Garnett'} fontSize={'3xl'} fontWeight={'600'} textAlign="left">{getHeading()}</Heading>
                </motion.div>
                <Box position="relative" height="12px" width="100%" bg="gray.300" borderRadius="full" overflow="hidden">
                    <motion.div
                        initial={{ width: '0%' }}
                        animate={{ width: `${(step / 4) * 100}%` }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            height: '100%',
                            backgroundColor: '#00B870',
                            borderRadius: 'full',
                        }}
                    />
                </Box>
                <Card px={6} py={8} borderRadius="lg" boxShadow="md">
                    <AnimatePresence exitBeforeEnter mode="popLayout" >
                        {step === 1 && (
                            <motion.div
                                key="step1"
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={pageVariants}
                                transition={pageTransition}
                            >
                                <FormControl>
                                    <VStack spacing={4}>
                                        <Box position="relative" width="150px" height="150px">
                                            <Image
                                                src={profilePictureUrl}
                                                alt="Profile Preview"
                                                boxSize="150px"
                                                objectFit="cover"
                                                borderRadius="full"
                                            />
                                        </Box>
                                        <VStack align={'start'} spacing={0} mt={4}>
                                            <Text variant={'input_label'}>Full Name</Text>
                                            <Input
                                                variant={'default'}
                                                placeholder="John Doe"
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                                size="lg"
                                            />

                                        </VStack>
                                        <VStack align={'start'} spacing={0} mt={4}>
                                            <Text variant={'input_label'}>Role</Text>
                                            <Input
                                                variant={'default'}
                                                placeholder="Eg: Paralegal"
                                                value={role}
                                                onChange={(e) => setRole(e.target.value)}
                                                size="lg"
                                            />
                                        </VStack>
                                    </VStack>
                                </FormControl>
                            </motion.div>
                        )}
                        {step === 2 && (
                            <motion.div
                                key="step2"
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={pageVariants}
                                transition={pageTransition}
                            >
                                <VStack justify={'start'} align={'start'}>
                                    <Box width={'100%'} height={'auto'} borderRadius={'md'}>
                                        <UnifiedPlatformIllustration width={'100%'} height={'auto'} style={{ borderRadius: '8px' }} />
                                    </Box>
                                    <VStack mt={4} align={'start'} spacing={1}>
                                        <Text fontSize={'2xl'} fontFamily={'Garnett'} color={'#000'} fontWeight={'bold'}>Bring everyone on the same page</Text>
                                        <Text fontSize={'md'} fontFamily={'Inter'} color={'gray.600'}>Transform the legal team into stewards of data-driven decision making and operational efficiency.</Text>
                                    </VStack>
                                </VStack>
                            </motion.div>
                        )}
                        {step === 3 && (
                            <motion.div
                                key="step3"
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={pageVariants}
                                transition={pageTransition}
                            >
                                <VStack justify={'start'} align={'start'}>
                                    <Box width={'100%'} height={'auto'} borderRadius={'md'}>
                                        <OrganiseDisputesIllustration width={'100%'} height={'auto'} style={{ borderRadius: '8px' }} />
                                    </Box>
                                    <VStack mt={4} align={'start'} spacing={1}>
                                        <Text fontSize={'2xl'} fontFamily={'Garnett'} color={'#000'} fontWeight={'bold'}>Stay on top of your Disptues</Text>
                                        <Text fontSize={'md'} fontFamily={'Inter'} color={'gray.600'}>Organize your disputes, extract key insights and actionable analytics from your disputes.</Text>
                                    </VStack>
                                </VStack>
                            </motion.div>
                        )}
                        {step === 4 && (
                            <motion.div
                                key="step4"
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={pageVariants}
                                transition={pageTransition}
                            >
                                <VStack justify={'start'} align={'start'}>
                                    <Box width={'100%'} height={'auto'} borderRadius={'md'}>
                                        <BusyWorkIllustration width={'100%'} height={'auto'} style={{ borderRadius: '8px' }} />
                                    </Box>
                                    <VStack mt={4} align={'start'} spacing={1}>
                                        <Text fontSize={'2xl'} fontFamily={'Garnett'} color={'#000'} fontWeight={'bold'}>Let AI do the busy work for you</Text>
                                        <Text fontSize={'md'} fontFamily={'Inter'} color={'gray.600'}>Extract factual summaries, generate case timelines, and more - in seconds, not in days.</Text>
                                    </VStack>
                                </VStack>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <HStack mt={8} justifyContent={step > 1 ? 'space-between' : 'flex-end'}>
                        {step > 1 && (
                            <Button onClick={() => setStep(step - 1)} size="lg" variant="default_muted" p={2}>
                                <ArrowBackIcon fill='#000' />
                            </Button>
                        )}
                        <Box overflow="hidden">
                            <motion.div
                                animate={step === 4 ? { width: 'auto' } : { width: '48px' }}
                                transition={{ duration: 0.3 }}
                            >
                                <Button
                                    variant={'default'}
                                    onClick={handleNextStep}
                                    size="lg"
                                    px={step === 4 ? 6 : 0}
                                    whiteSpace="nowrap"
                                    isDisabled={step == 1 && (!fullName || !role)}
                                >
                                    <motion.span
                                        style={{ marginRight: step < 4 ? '0px' : '8px' }}
                                        initial={{ opacity: 0, width: 0 }}
                                        animate={step === 4 ? { opacity: 1, width: 'auto' } : { opacity: 0, width: 0 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        Get Started
                                    </motion.span>
                                    <ArrowBackIcon fill='#fff' style={{ transform: 'rotate(180deg)' }} />
                                </Button>
                            </motion.div>
                        </Box>
                    </HStack>
                </Card>
            </VStack>
        </Container>
    );
};

export default Onboarding;
