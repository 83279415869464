import React, { useState, useEffect, useCallback } from 'react';
import { HStack, VStack, Text, Button, IconButton, Spinner } from '@chakra-ui/react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios'

import DocSourceIcon from '../../images/doc_source.svg';
import { ReactComponent as OptionsIcon } from '../../images/options.svg';
import { ReactComponent as EditIcon } from '../../images/edit.svg';
import { ReactComponent as DeleteIcon } from '../../images/delete.svg';
import AutoResizeTextarea from '../../components/AutoResizeTextarea';
import useFillSnippet from '../../hooks/useFillSnippet';



const SnippetNode = ({ data, id, goToDoc }) => {
  const [state, setState] = useState({
    showMenu: false,
    editedContent: data.snippet_body || '',
    fillContent: '',
    isLoading: false,
    showResponse: false,
    showReferences: false,
    snippetBody: data.snippet_body || '',
    referencesContext: data.references_context || {}
  });

  const { setNodes } = useReactFlow();
  const fillSnippet = useFillSnippet();

  useEffect(() => {
    if (data.snippet_body && data.snippet_body !== state.snippetBody) {
      setState(prev => ({
        ...prev,
        snippetBody: data.snippet_body,
        editedContent: data.snippet_body,
      }));
    }
  }, [data, state.snippetBody]);

  const updateNode = useCallback((updates) => {
    setNodes(nds => nds.map(node => 
      node.id === id ? { ...node, data: { ...node.data, ...updates } } : node
    ));
  }, [id, setNodes]);

  const handleFillSubmit = useCallback(async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const result = await fillSnippet(id, data.fillType, data.analysisID, state.fillContent);
      const updatedSnippetBody = result.complete_context || '';
      setState(prev => ({
        ...prev,
        snippetBody: updatedSnippetBody,
        editedContent: updatedSnippetBody,
        isLoading: false,
        showResponse: true,
        showReferences: true,
        referencesContext: result.references_context
      }));
      // We don't need to call updateNode here as it's already done in the fillSnippet function
    } catch (error) {
      console.error('Error in fillSnippet:', error);
      setState(prev => ({ ...prev, isLoading: false }));
    }
  }, [id, data.fillType, data.caseID, state.fillContent, fillSnippet]);
  


  const handleSave = useCallback(async () => {
    console.log("SAVE THE SNIPPET CONTENT NOW", state.editedContent, id);
    try {
      const response = await axios.put(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/update_snippet`, {
        snippet_id: id,
        snippet_body: state.editedContent,
        show_references: state.showReferences,
        references_context: state.referencesContext.updatedSnippetRefernces
      });
      console.log("RESPONSE", response.data)
    } catch (e) {
      console.log("ISSUE", e);
    }
    updateNode({ snippet_body: state.editedContent, isEditing: false });
    setState(prev => ({ ...prev, showResponse: false, showMenu: false }));
  }, [state.editedContent, updateNode, id]);

  const handleCancel = useCallback(() => {
    updateNode({ isEditing: false, fillType: null });
    setState(prev => ({
      ...prev,
      editedContent: prev.snippetBody,
      fillContent: '',
      showResponse: false,
    }));
  }, [updateNode]);

  const handleDelete = () => {
    console.log('Delete node:', id);
    setState(prev => ({ ...prev, showMenu: false }));
  };

  const handleFill = (fillType) => {
    updateNode({ fillType });
  };

  const handleEdit = () => {
    if (!data.snippet_body) {
      updateNode({ showOptions: true });
    } else {
      updateNode({ isEditing: true });
      setState(prev => ({ ...prev, editedContent: data.snippet_body, showMenu: false }));
    }
  };

  const handleEditInstruction = useCallback(() => {
    updateNode({ fillType: 'instructions', isEditing: false, showOptions: true });
    setState(prev => ({ 
      ...prev, 
      showResponse: false, 
      fillContent: prev.editedContent,
    }));
  }, [updateNode]);

  
  const renderContent = () => {
    if (state.isLoading) {
      return (
        <VStack justify="center" align="center" h="100%">
          <Spinner size="xl" />
          <Text>Loading...</Text>
        </VStack>
      );
    }

    if (data.showOptions) {
      return data.fillType ? (
        <VStack spacing={2} align="stretch">
          <Text>{data.fillType === 'manual' ? 'Enter snippet content' : 'Enter instructions'}</Text>
          <AutoResizeTextarea
            value={state.fillContent}
            onChange={(e) => setState(prev => ({ ...prev, fillContent: e.target.value }))}
          />
          <HStack justify="flex-end" mt={2}>
            <Button mr={2} onClick={handleCancel} size="sm" variant="ghost">Go back</Button>
            <Button onClick={handleFillSubmit} size="sm" variant={'default'}>Submit</Button>
          </HStack>
        </VStack>
      ) : (
        <VStack spacing={2} align="stretch">
          <Text mb={6}>How would you like to fill this snippet?</Text>
          <Button mb={2} onClick={() => handleFill('instructions')} size="sm">Fill with Instructions</Button>
          <Button variant={'ghost'} onClick={() => handleFill('manual')} size="sm">Fill Manually</Button>
        </VStack>
      );
    }

    if (data.isEditing || state.showResponse) {
      return (
        <VStack spacing={2} align="stretch">
          <AutoResizeTextarea
            value={state.editedContent}
            onChange={(e) => setState(prev => ({ ...prev, editedContent: e.target.value }))}
          />
        </VStack>
      );
    }

    return <Text>{state.snippetBody || 'NA'}</Text>;
  };





const renderReferences = () => {
  if (!data.references_context) return null;
  
  const { references, text } = data.references_context;
  
  if (!references || !text) {
    console.error('Invalid references_context structure:', data.references_context);
    // If references are invalid, just return the text without processing
    return <div className="references-section">{data.snippet_body}</div>;
  }

  const referenceMap = new Map(references.map(ref => [ref.id, { doc_id: ref.doc_id, text: ref.text }]));

  const renderText = (text) => {
    const parts = text.split(/(<ref id='\d+'>.+?<\/ref>)/);
    return parts.map((part, index) => {
      const match = part.match(/<ref id='(\d+)'>(.*?)<\/ref>/);
      if (match) {
        const [, id, content] = match;
        return (
          <React.Fragment key={index}>
            {content}
            <span 
              className="citation-button" 
              data-reference-id={id}
              onClick={() => handleReferenceClick(id)}
              style={{ cursor: 'pointer' }}
            >
              {id}
            </span>
          </React.Fragment>
        );
      }
      return part;
    });
  };

  const handleReferenceClick = (referenceId) => {
    const reference = referenceMap.get(referenceId);
    if (reference && data.goToDoc) {
      data.goToDoc(reference.doc_id, reference.text);
    }
  };

  return (
    <div className="references-section">
      <div>
        {renderText(text)}
      </div>
    </div>
  );
};
  


  return (
    <div className={`custom-node ${data.isFiltered ? '' : 'faded-node'}`}>
      <Handle type="target" position={Position.Top} />
      <Handle type="target" position={Position.Left} />
      <div className="snippet-header">
        {data.showOptions ? 'New Snippet' : (data?.snippet_metadata?.sectionTitle || 'Untitled')}
      </div>
      <div className="snippet-body">
      {(state.showReferences || data.show_references) ? renderReferences() : renderContent()}
      </div>

      {!data.showOptions && !data.isEditing && !state.showResponse && (
        <VStack align={'flex-start'}>
          <HStack my={4}>
            <img src={DocSourceIcon} alt="" />
            <Text color={'#000'} cursor={'pointer'}>
              {data?.snippet_metadata?.documentSource || 'NA'}
            </Text>
          </HStack>
        </VStack>
      )}
      <Handle type="source" position={Position.Right} />
      <Handle type="source" position={Position.Bottom} />
      
      {(!data.isEditing && !data.showOptions && !state.showResponse) && (
        <IconButton
          icon={<OptionsIcon fill="#929292" />}
          aria-label="More options"
          variant="ghost"
          size="sm"
          position="absolute"
          bottom="2"
          right="2"
          onClick={() => setState(prev => ({ ...prev, showMenu: !prev.showMenu }))}
        />
      )}
      <AnimatePresence>
        {(state.showMenu || state.showResponse || data.isEditing) && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: '8px',
              background: 'white',
              borderTop: '1px solid #e2e8f0',
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            {state.showResponse || data.isEditing ? (
              <>
                <Button onClick={handleEditInstruction} size="sm" variant="ghost">
                  Edit Instruction
                </Button>
                <Button onClick={handleSave} size="sm" colorScheme="blue">
                  Save Snippet
                </Button>
              </>
            ) : (
              <>
                <Button
                  leftIcon={<EditIcon fill="#929292" />}
                  aria-label="Edit"
                  variant="ghost"
                  onClick={handleEdit}
                >
                  Edit
                </Button>
                <Button
                  color="red.500"
                  leftIcon={<DeleteIcon fill="#E53E3E" />}
                  aria-label="Delete"
                  onClick={handleDelete}
                  variant="ghost"
                >
                  Delete
                </Button>
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SnippetNode;