import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { renderAsync } from 'docx-preview';
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Text, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, VStack, HStack, Input, Flex, Card, Tooltip, IconButton, Avatar, InputGroup, InputLeftElement, Menu, MenuButton, MenuList, MenuItem, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Divider } from '@chakra-ui/react';
import axios from 'axios';
import moment from 'moment';

import SelectDropdown from '../../components/SelectDropdown';

import { ReactComponent as LabelIcon } from '../../images/label.svg';
import { ReactComponent as DownloadFileIcon } from '../../images/download.svg';
import { ReactComponent as UploadIcon } from '../../images/upload.svg';
import { ReactComponent as OptionsIcon } from '../../images/options.svg';
import { ReactComponent as NewVersionIcon } from '../../images/new-version.svg';
import { ReactComponent as DeleteIcon } from '../../images/delete.svg';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;


const CaseFilesTab = ({ caseName, caseID }) => {
    const [caseFiles, setCaseFiles] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileTag, setFileTag] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const previewRef = useRef(null);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [fileParty, setFileParty] = useState([]);
    const [versionName, setVersionName] = useState('');
    const cancelRef = useRef();

    const onDrop = (acceptedFiles) => {
        setSelectedFile(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleUpload = () => {
        setIsModalOpen(true);
        setSelectedFileId(null);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedFile(null);
        setFileTag('');
        setSelectedFileId(null);
        setVersionName('');
    };

    useEffect(() => {
        fetchCaseFiles();
    }, []);


    const fetchCaseFiles = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/list_casefiles?case_id=${caseID}`);
            setCaseFiles(response.data);
        } catch (error) {
            console.error('Error fetching case files:', error);
        }
    };

    useEffect(() => {
        if (selectedFile && previewRef.current) {
            if (selectedFile.type === 'application/pdf') {
                // PDF preview is handled by react-pdf
            } else if (selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                renderAsync(selectedFile, previewRef.current);
            }
        }
    }, [selectedFile]);

    const fetchCaseFileUrl = async (fileID) => {
        try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/get_casefile_url?file_id=${fileID}&case_id=${caseID}`);
            setIsLoading(false);
            return response.data.presigned_url;
        } catch (error) {
            console.error('Error fetching case file URL:', error);
        }
    };

    const handleDownload = async (fileID) => {
        const url = await fetchCaseFileUrl(fileID);
        window.open(url, '_blank');
    };

    const handlePreview = async (fileID) => {
        const url = await fetchCaseFileUrl(fileID);
        window.open(url, '_blank');
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleFileUpload = async () => {
        if (!selectedFile) return;
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('case_id', caseID);
        formData.append('party', fileParty);
        if (fileTag) formData.append('tag', fileTag);
        if (selectedFileId) {
            formData.append('file_id', selectedFileId);
            if (versionName) formData.append('version_name', versionName);
        }

        try {
            await axios.put(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/upload_casefile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // Handle successful upload (e.g., show success message, refresh file list)
            handleCloseModal();
            fetchCaseFiles();
            setIsUploading(false);
        } catch (error) {
            // Handle error (e.g., show error message)
            console.error('Error uploading file:', error);
            setIsUploading(false);
        }
    };

    const handleDeleteFile = async (fileID) => {
        try {
            await axios.delete(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/delete_casefile?file_id=${fileID}&case_id=${caseID}`);
            fetchCaseFiles();
            setIsDeleteAlertOpen(false);
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };

    const handleUploadNewVersion = async (fileID) => {
        setSelectedFileId(fileID);
        setIsModalOpen(true);
    };

    const openDeleteConfirmation = (file) => {
        setFileToDelete(file);
        setIsDeleteAlertOpen(true);
    };

    return (
        <Box>
            <Flex mb={4}>
                <Button onClick={handleUpload} variant={'secondary'}>Upload File</Button>
            </Flex>
            {caseFiles.map((file, index) => (
                <Card key={index} shadow={'none'} my={2} px={6} py={4} border={'1px solid'} borderColor={'gray.200'}>
                    <Flex justifyContent={'space-between'} align={'center'}>
                        <HStack align="center" spacing={1} width={'calc(100% - 90px)'} justifyContent={'space-between'}>
                            <VStack align="start" spacing={2}>
                                <Tooltip label={"File tag"} hasArrow>
                                    <HStack spacing={2} bg={'gray.200'} py={1} px={3} borderRadius={'md'}>
                                        <LabelIcon height={'20px'} width={'20px'} fill='#2d3748' />
                                        <Text color={'gray.700'} fontSize={'0.95rem'} fontWeight={'600'}>
                                            {file.tag}
                                        </Text>
                                    </HStack>
                                </Tooltip>
                                {file.version_count === 1 ? <Text fontSize={'0.9rem'} fontWeight={'600'} color={'#4b4b4b'}>
                                    {file.original_filename}
                                </Text> : <Text fontSize={'0.9rem'} fontWeight={'600'} color={'#4b4b4b'}>
                                    {file.original_filename} + {file.version_count - 1} other {file.version_count === 2 ? 'version' : 'versions'}
                                </Text>}
                            </VStack>
                            <HStack minW={'230px'} spacing={2} align="center" justifyContent="flex-end">
                                <HStack>
                                    <Avatar size="sm" src={file.uploaded_by.profile_picture_url} />
                                    <Text fontSize={'sm'} fontWeight="bold" color={'#4b4b4b'}>{file.uploaded_by.full_name}</Text>
                                </HStack>
                                <HStack>

                                    <Text fontSize={'1.2rem'}>·</Text>
                                    <Text fontSize="sm" color="gray.500">
                                        {moment(file.created_at * 1000).fromNow()}
                                    </Text>
                                </HStack>

                            </HStack>
                        </HStack>
                        <Menu variant={'default'}>
                            <MenuButton
                                as={IconButton}
                                aria-label='Options'
                                icon={<OptionsIcon style={{ transform: 'rotate(90deg)' }} fill={'#a8a8a8'} />}
                                variant='ghost'
                            />
                            <MenuList>
                                <MenuItem onClick={() => handleDownload(file.file_id)}>
                                    <DownloadFileIcon fill={'var(--chakra-colors-gray-500)'} />
                                    Download file
                                </MenuItem>
                                <MenuItem onClick={() => handleUploadNewVersion(file.file_id)}>
                                    <NewVersionIcon fill={'var(--chakra-colors-gray-500)'} />
                                    Upload new version
                                </MenuItem>
                                <MenuItem onClick={() => openDeleteConfirmation(file)} color={'red.500'}>
                                    <DeleteIcon fill='var(--chakra-colors-red-500)' />
                                    Delete file
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </Card>
            ))}
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} closeOnOverlayClick={false} closeOnEsc={false} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{selectedFileId ? 'Upload New Version' : 'Upload Case Files'} - {caseName}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody position="relative">
                        <VStack spacing={4} height="80vh" maxHeight={'800px'}>
                            {!selectedFile ? (
                                <Box {...getRootProps()} w="100%" h="100%" border="2px dashed" borderColor={isDragActive ? "blue.500" : "gray.300"} borderRadius="md" p={4} display="flex" alignItems="center" justifyContent="center">
                                    <input {...getInputProps()} />
                                    <VStack spacing={4} alignItems="center">
                                        <Text textAlign="center" fontSize="lg" fontWeight="medium" color={isDragActive ? "blue.500" : "gray.600"}>
                                            {isDragActive ? "Drop the files here..." : "Drag and drop files"}
                                        </Text>
                                        <Text textAlign="center" fontSize="sm" color="gray.500">
                                            OR
                                        </Text>
                                        <Button variant="outline" leftIcon={<UploadIcon fill={'#00995D'} />}>
                                            Select File
                                        </Button>
                                    </VStack>
                                </Box>
                            ) : (
                                <>
                                    <Box width="100%" height="100%" overflowY="auto" borderRadius={'md'}>
                                        {selectedFile.type === 'application/pdf' ? (
                                            <Document
                                                file={selectedFile}
                                                onLoadSuccess={onDocumentLoadSuccess}
                                            >
                                                {Array.from(new Array(numPages), (el, index) => (
                                                    <Page 
                                                        key={`page_${index + 1}`}
                                                        pageNumber={index + 1}
                                                        renderTextLayer={false}
                                                        renderAnnotationLayer={false}
                                                    />
                                                ))}
                                            </Document>
                                        ) : (
                                            <div className='docx-preview-upload-preview' ref={previewRef} style={{ width: '100%', height: '100%', marginBottom: '560px' }}></div>
                                        )}
                                    </Box>
                                    <Card
                                        position="absolute"
                                        bottom="4"
                                        left="50%"
                                        transform="translateX(-50%)"
                                        bg="white"
                                        boxShadow="lg"
                                        borderRadius="md"
                                        p={4}
                                        width="90%"
                                        zIndex={99}
                                        border="1px solid"
                                        borderColor="gray.200"
                                        backgroundColor="green.50"
                                    >
                                        <VStack w={'100%'} alignItems={'flex-start'} spacing={2}>
                                            <Text fontWeight="bold">{selectedFile.name}</Text>
                                            <Divider mb={1} variant={'default'} />
                                            <HStack w={'100%'} justifyContent={'space-between'}>
                                                {!selectedFileId && (
                                                    <HStack>
                                                        <SelectDropdown
                                                            options={[
                                                                { id: 'claimant', option: 'Claimant' },
                                                                { id: 'respondent', option: 'Respondent' }
                                                            ]}
                                                            onChange={(value) => setFileParty(value)}
                                                            placeholderText="Select party"
                                                            isClearable={false}
                                                            shouldShowSearch={false}
                                                            shouldShowAssignee={false}
                                                            shouldShowChevron={true}
                                                        />
                                                        <InputGroup>
                                                            <InputLeftElement
                                                                height={'100%'}
                                                                pointerEvents='none'
                                                            >
                                                                <LabelIcon width={'18px'} height={'18px'} fill={'#00B870'} />
                                                            </InputLeftElement>
                                                            <Input
                                                                paddingLeft={'34px'}
                                                                paddingTop={'22px'}
                                                                paddingBottom={'22px'}
                                                                size={'md'}
                                                                variant={'default_muted_3'}
                                                                placeholder="Add tag"
                                                                value={fileTag}
                                                                onChange={(e) => setFileTag(e.target.value)}
                                                            />
                                                        </InputGroup>
                                                    </HStack>
                                                )}
                                                {selectedFileId && (
                                                    <HStack>
                                                        <InputGroup>
                                                        <InputLeftElement
                                                            height={'100%'}
                                                            pointerEvents='none'
                                                        >
                                                            <NewVersionIcon width={'18px'} height={'18px'} fill={'#00B870'} />
                                                        </InputLeftElement>
                                                        <Input
                                                            paddingLeft={'34px'}
                                                            paddingTop={'22px'}
                                                            paddingBottom={'22px'}
                                                            size={'md'}
                                                            variant={'default_muted_3'}
                                                            placeholder="Version name"
                                                            value={versionName}
                                                            onChange={(e) => setVersionName(e.target.value)}
                                                        />
                                                        </InputGroup>
                                                    </HStack>
                                                )}
                                                {selectedFileId ? (
                                                    <Button isLoading={isUploading} variant={'secondary'} onClick={handleFileUpload}>
                                                        Upload as version
                                                    </Button>
                                                ) : (
                                                    <Button 
                                                        isLoading={isUploading} 
                                                        isDisabled={!fileParty.length || !fileTag} 
                                                        variant={'secondary'} 
                                                        onClick={handleFileUpload}
                                                    >
                                                        Upload
                                                    </Button>
                                                )}
                                            </HStack>
                                        </VStack>
                                    </Card>
                                </>
                            )}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            <AlertDialog
                size={'xl'}
                isOpen={isDeleteAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsDeleteAlertOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader pb={2} fontSize="lg" fontWeight="bold">
                            Confirm File Deletion
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            {fileToDelete && (
                                <Box>
                                    <Text>Are you sure you want to delete this file {fileToDelete.version_count > 1 ? <b style={{ color: '#000' }}>and {fileToDelete.version_count - 1} other {fileToDelete.version_count === 2 ? 'version' : 'versions'}?</b> : "?"}</Text>
                                    <Box border={'1px solid'} borderColor={'gray.200'} borderRadius={'md'} p={4} mt={2}>
                                        <VStack alignItems={'flex-start'} spacing={2}>
                                            <HStack spacing={2} bg={'gray.200'} py={1} px={3} borderRadius={'md'}>
                                                <LabelIcon height={'20px'} width={'20px'} fill='#2d3748' />
                                                <Text color={'gray.700'} fontSize={'0.95rem'} fontWeight={'600'}>
                                                    {fileToDelete.tag}
                                                </Text>
                                            </HStack>
                                            <Text fontSize={'0.9rem'} fontWeight={'600'} color={'#4b4b4b'}>
                                                {fileToDelete.original_filename} {fileToDelete.version_count > 1 && <> + {fileToDelete.version_count - 1} other {fileToDelete.version_count === 2 ? 'version' : 'versions'}</>}
                                            </Text>
                                        </VStack>
                                    </Box>
                                </Box>
                            )}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsDeleteAlertOpen(false)}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={() => handleDeleteFile(fileToDelete.file_id)} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    );
};

export default CaseFilesTab;