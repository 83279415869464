import React, { useEffect, useState } from 'react';
import { Text, Flex, Box } from '@chakra-ui/react';

const TypeSelect = ({ options, title, onChange }) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);

    const handleSelectOption = (index) => {
        setSelectedItemIndex(index);
    }

    useEffect(() => {
        onChange(options[selectedItemIndex].type);
    }, [selectedItemIndex, onChange, options]);

    return (
        <div className="type-select-wrapper">
            <Text textTransform={'uppercase'} fontSize={'1rem'} color={'#000'} fontWeight={'600'}>{title}</Text>
            {options.map((item, index) => (
                <Flex 
                    key={index} 
                    onClick={() => handleSelectOption(index)} 
                    className={`option${selectedItemIndex === index ? ' selected' : ''}`} 
                    justifyContent={'space-between'} 
                    alignItems={'center'}
                >
                    <Box w={'70%'}>
                        <Flex alignItems={'center'}>
                            {item.icon && (
                                <Box mr={2} width="28px" height="28px">
                                    <item.icon
                                        fill={'#000'}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            opacity: selectedItemIndex === index ? 1 : 0.3,
                                            transition: "opacity 0.2s"
                                        }}
                                    />
                                </Box>
                            )}
                            <Box ml={item.icon ? 2 : 4} width={'85%'}>
                                <Text color={'black'} fontWeight={'600'}>{item.title}</Text>
                                <Text color={'gray.500'} fontSize={'14px'} fontWeight={'500'}>{item.description}</Text>
                            </Box>
                        </Flex>
                    </Box>
                    <Box>
                        <div className="radio"></div>
                    </Box>
                </Flex>
            ))}
        </div>
    );
}

export default TypeSelect;