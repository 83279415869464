import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Tooltip, Text } from '@chakra-ui/react';
import axios from 'axios';

import SidebarOpen from '../../images/panel-open.svg'
import SidebarClose from '../../images/panel-close.svg'

import { renderAsync } from 'docx-preview';

// Animation timing constants
const EXPAND_DURATION = 0.25;
const COVER_DURATION = 0.15;

const DocxPreview = ({
  isExpanded,
  onToggle,
  documents,
  docPreviewRef,
  previewSelectedDocID,
  setPreviewSelectedDocID,
  currentTab,
  analysisID,
  caseID
}) => {
  const [isCoverVisible, setIsCoverVisible] = useState(!isExpanded);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if(isExpanded) setIsCoverVisible(false)
  }, [isExpanded])

  const handleToggle = () => {
    if (isExpanded) {
      setIsCoverVisible(true);
      setTimeout(() => {
        onToggle();
      }, COVER_DURATION * 1000);
    } else {
      onToggle();
      setTimeout(() => {
        setIsCoverVisible(false);
      }, EXPAND_DURATION * 1000);
    }
  };

  const fetchAndRenderDocument = async (fileId, versionId) => {
    try {
      setIsLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/stream_casefile`, {
        case_id: caseID,
        file_id: fileId,
        version_id: versionId
      }, {
        responseType: 'arraybuffer'
      });

      if (docPreviewRef.current) {
        await renderAsync(response.data, docPreviewRef.current, null, {
          ignoreLastRenderedPageBreak: false
        });
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (documents?.length > 0) {
      const firstDoc = documents[0];
      setPreviewSelectedDocID(firstDoc.doc_id);
      fetchAndRenderDocument(firstDoc.file_id, firstDoc.version_id);
    } else {
      console.log('NO DOCX FOUND');
    }
  }, [documents]);

  useEffect(() => {
    if (documents?.length && previewSelectedDocID) {
      const selectedDoc = documents.find(
        doc => doc.doc_id === previewSelectedDocID
      );
      if (selectedDoc) {
        fetchAndRenderDocument(selectedDoc.file_id, selectedDoc.version_id);
      }
    }
  }, [previewSelectedDocID, documents]);

  return (<div className='doc-sidebar'>
    <motion.div
      className={`doc-preview ${currentTab}`}
      initial={{ width: '5%' }}
      animate={{ width: isExpanded ? '40%' : '5%' }}
      transition={{ duration: EXPAND_DURATION, ease: 'easeInOut' }}
      style={{
      }}
    >
      <AnimatePresence>
        {isCoverVisible && (
          <motion.div
            className="content-cover"
            initial={{ width: isExpanded ? '0%' : '100%' }}
            animate={{ width: '100%' }}
            exit={{ width: '0%' }}
            transition={{ duration: COVER_DURATION }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              background: '#F7F5F0',
              zIndex: 1,
            }}
          />
        )}
      </AnimatePresence>
      <Box
        className="file-select-container"
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {documents.map(doc => (
          <div
            className={`file-select ${
              doc.doc_id === previewSelectedDocID ? 'selected' : ''
            }`}
            key={`${doc.doc_id}-${doc.version_id}`}
            onClick={() => setPreviewSelectedDocID(doc.doc_id)}
          >
            {doc.tag}
          </div>
        ))}
      </Box>
      <Box
        ref={docPreviewRef}
        className="doc-preview-container"
        height="100%"
        overflowY="auto"
        style={{ 
          position: 'relative', 
          zIndex: 0, 
          opacity: isExpanded && !isLoading ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out'
        }}
      ></Box>
      <div className={`sidebar-toggle${isExpanded ? ' expanded' : ''}`} onClick={handleToggle}>
        <Tooltip label={isExpanded ? 'Minimize document preview' : 'Maximize document preview'}>
          <img src={isExpanded ? SidebarClose : SidebarOpen} alt="Toggle sidebar" />
        </Tooltip>
      </div>
    </motion.div>
    </div>
  );
};

export default DocxPreview;
