import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { renderAsync } from 'docx-preview';
import axios from 'axios';

import { highlightAndScrollToText, removeAllSpaces } from './../../helpers/DocHighlightHelper'

const DocPreview = ({ docID, selectedText, caseID }) => {
  const docPreviewRef = useRef(null);
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    const fetchDocument = async () => {
      if (!docID) return;

      const [fileId, versionId] = docID.split('_%%_');
      
      try {
        const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/stream_casefile`, {
          case_id: caseID,
          file_id: fileId,
          version_id: versionId
        }, {
          responseType: 'arraybuffer'
        });

        setDocumentData(response.data);
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchDocument();
  }, [docID, caseID]);

  useEffect(() => {
    if (docPreviewRef.current && documentData) {
      renderAsync(documentData, docPreviewRef.current)
        .then(() => {
          goToDoc(selectedText);
        })
        .catch(err => console.error(err));
    }
  }, [documentData, selectedText]);

  const goToDoc = (doc_text) => {
    setTimeout(() => {
      const processedText = removeAllSpaces(doc_text);
      highlightAndScrollToText(docPreviewRef.current, processedText);
    }, 10);
  };

  return (
    <>
      <Box
        height={'calc(100vh - 124px)'}
        overflowY={'auto'}
        ref={docPreviewRef}
      ></Box>
    </>
  );
};

export default DocPreview;
