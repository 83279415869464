import React, { useRef, useEffect} from "react";


const AutoResizeTextarea = ({ value, onChange, ...props }) => {
    const textareaRef = useRef(null);
  
    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [value]);
  
    return (
      <textarea
        ref={textareaRef}
        value={value}
        onChange={onChange}
        style={{
          resize: 'none',
          overflow: 'hidden',
          minHeight: '100px',
          width: '100%',
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid #E2E8F0',
          outline: 'none',
        }}
        {...props}
      />
    );
  };

  export default AutoResizeTextarea