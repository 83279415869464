import { useEffect, useRef, useState } from 'react';

const useTextSelection = (selectionWrapperRef) => {
  const [selectedText, setSelectedText] = useState('');
  const [selectionPosition, setSelectionPosition] = useState({ top: 0, left: 0 });
  const [selectionMetadata, setSelectionMetadata] = useState();
  const [selectionInfo, setSelectionInfo] = useState(null);
  const selectionRef = useRef(null);

  useEffect(() => {
    const handleMouseUp = () => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();

      if (selectedText && selectionWrapperRef.current) {
        const range = selection.getRangeAt(0);
        const isSelectionWithinWrapper = isRangeWithinElement(range, selectionWrapperRef.current);
        if (isSelectionWithinWrapper) {
          const selectionRect = range.getBoundingClientRect();
          const wrapperRect = selectionWrapperRef.current.getBoundingClientRect();

          // Calculate position relative to the wrapper
          const top = selectionRect.bottom - wrapperRect.top;
          const left = selectionRect.left - wrapperRect.left;

          // Add scroll offset of the wrapper
          const scrollTop = selectionWrapperRef.current.scrollTop;
          const scrollLeft = selectionWrapperRef.current.scrollLeft;

          setSelectionPosition({
            top: top + scrollTop,
            left: left + scrollLeft + 290
          });
          setSelectedText(selectedText);

          // Capture selection info
          const info = captureSelectionInfo(range);
          setSelectionInfo(info);
        } else {
          resetSelection();
        }
      } else {
        resetSelection();
      }
    };

    // const handleMouseDown = () => {
    //   resetSelection();
    // };

    document.addEventListener('mouseup', handleMouseUp);
    // document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      // document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [selectionWrapperRef]);

  const resetSelection = () => {
    setSelectedText('');
    setSelectionPosition({ top: 0, left: 0 });
    setSelectionInfo(null);
  };

  const isRangeWithinElement = (range, element) => {
    const elementRange = document.createRange();
    elementRange.selectNodeContents(element);
    return (
      range.compareBoundaryPoints(Range.START_TO_START, elementRange) >= 0 &&
      range.compareBoundaryPoints(Range.END_TO_END, elementRange) <= 0
    );
  };
  const captureSelectionInfo = (range) => {
    const commonAncestor = range.commonAncestorContainer;
    let node = commonAncestor.nodeType === Node.TEXT_NODE ? commonAncestor.parentNode : commonAncestor;
    
    const getNodeInfo = (node) => {
      if (node.nodeType === Node.ELEMENT_NODE) {
        return {
          sectionId: node.getAttribute('data-section-id') || null,
          sectionTitle: node.getAttribute('data-section-title') || null,
          documentSource: node.getAttribute('data-section-document-source') || null,
          contentType: node.getAttribute('data-section-type') || null,
        };
      }
      return {};
    };
  
    let info = {};
    while (node && node !== selectionWrapperRef.current) {
      const nodeInfo = getNodeInfo(node);
      info = {
        sectionId: info.sectionId || nodeInfo.sectionId,
        sectionTitle: info.sectionTitle || nodeInfo.sectionTitle,
        documentSource: info.documentSource || nodeInfo.documentSource,
        contentType: info.contentType || nodeInfo.contentType,
      };
  
      // Check siblings for additional information
      let sibling = node.previousElementSibling;
      while (sibling) {
        const siblingInfo = getNodeInfo(sibling);
        info = {
          sectionId: info.sectionId || siblingInfo.sectionId,
          sectionTitle: info.sectionTitle || siblingInfo.sectionTitle,
          documentSource: info.documentSource || siblingInfo.documentSource,
          contentType: info.contentType || siblingInfo.contentType,
        };
        sibling = sibling.previousElementSibling;
      }
  
      // If we have all the information, break the loop
      if (info.sectionId && info.sectionTitle && info.contentType && info.documentSource) {
        break;
      }
  
      node = node.parentNode;
    }
  
    setSelectionMetadata(info);
  
    if (Object.values(info).some(value => value !== null)) {
      return {
        ...info,
        selectedText: range.toString()
      };
    }
  
    return null;
  };




  return { selectedText, selectionPosition, selectionInfo, setSelectedText, selectionRef, selectionMetadata, resetSelection };
};

export default useTextSelection;