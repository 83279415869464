import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  Card,
  CardHeader,
  CardBody,
  Stack,
  Badge,
  Avatar,
  Flex,
  Divider,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import { FiAlertTriangle, FiPieChart, FiList } from 'react-icons/fi';

const dummyDashboards = [
  {
    id: 1,
    name: 'Dispute Overview',
    description: 'High-level metrics and KPIs for all dispute cases',
    creator: {
      name: 'John Smith',
      avatar: 'https://i.pravatar.cc/150?img=1'
    },
    type: 'dashboard'
  },
  {
    id: 2, 
    name: 'Case Analysis',
    description: 'Detailed analysis of individual dispute cases with document intelligence',
    creator: {
      name: 'Sarah Johnson',
      avatar: 'https://i.pravatar.cc/150?img=2'
    },
    type: 'dashboard'
  }
];

const dummyAlerts = [
  {
    id: 1,
    name: 'High Priority Cases',
    description: 'Alerts for cases requiring immediate attention',
    severity: 'high',
    creator: {
      name: 'Mike Wilson',
      avatar: 'https://i.pravatar.cc/150?img=3'
    }
  },
  {
    id: 2,
    name: 'Document Updates',
    description: 'Notifications for new or updated case documents',
    severity: 'medium',
    creator: {
      name: 'Emily Davis',
      avatar: 'https://i.pravatar.cc/150?img=4'
    }
  }
];

const dummyTriageCases = [
  {
    id: 1,
    name: 'Case #4832',
    status: 'Needs Review',
    priority: 'high',
    lastUpdated: '2 hours ago',
    assignee: {
      name: 'Alex Turner',
      avatar: 'https://i.pravatar.cc/150?img=5'
    }
  },
  {
    id: 2,
    name: 'Case #4833',
    status: 'In Progress',
    priority: 'medium',
    lastUpdated: '4 hours ago',
    assignee: {
      name: 'Rachel Green',
      avatar: 'https://i.pravatar.cc/150?img=6'
    }
  },
  {
    id: 3,
    name: 'Case #4834',
    status: 'Pending Info',
    priority: 'low',
    lastUpdated: '1 day ago',
    assignee: {
      name: 'David Chen',
      avatar: 'https://i.pravatar.cc/150?img=7'
    }
  }
];

const DisputeIntelligence = () => {
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Container maxW="container.xl" py={8}>
      <Stack spacing={12}>
        <Box>
          <Flex align="center" mb={6}>
            <Icon as={FiAlertTriangle} w={6} h={6} color="red.500" mr={3} />
            <Heading size="lg">Alerts</Heading>
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {dummyAlerts.map(alert => (
              <Card 
                key={alert.id} 
                bg={cardBg}
                borderColor={borderColor}
                borderWidth="1px"
                borderRadius="lg"
                transition="all 0.2s"
                _hover={{ transform: 'translateY(-2px)', shadow: 'lg' }}
              >
                <CardHeader>
                  <Flex justify="space-between" align="center">
                    <Box>
                      <Heading size="md">{alert.name}</Heading>
                      <Badge 
                        colorScheme={alert.severity === 'high' ? 'red' : 'orange'}
                        mt={2}
                        borderRadius="full"
                        px={3}
                      >
                        {alert.severity} priority
                      </Badge>
                    </Box>
                    <Avatar src={alert.creator.avatar} size="sm" />
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Text color="gray.600">{alert.description}</Text>
                  <Text fontSize="sm" color="gray.500" mt={3}>
                    Created by {alert.creator.name}
                  </Text>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </Box>

        <Box>
          <Flex align="center" mb={6}>
            <Icon as={FiList} w={6} h={6} color="green.500" mr={3} />
            <Heading size="lg">Triage Queue</Heading>
          </Flex>
          <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={6}>
            {dummyTriageCases.map(triageCase => (
              <Card 
                key={triageCase.id}
                bg={cardBg}
                borderColor={borderColor}
                borderWidth="1px"
                borderRadius="lg"
                transition="all 0.2s"
                _hover={{ transform: 'translateY(-2px)', shadow: 'lg' }}
              >
                <CardHeader>
                  <Flex justify="space-between" align="center">
                    <Box>
                      <Heading size="md">{triageCase.name}</Heading>
                      <Badge 
                        colorScheme={
                          triageCase.priority === 'high' ? 'red' : 
                          triageCase.priority === 'medium' ? 'orange' : 'green'
                        }
                        mt={2}
                        borderRadius="full"
                        px={3}
                      >
                        {triageCase.status}
                      </Badge>
                    </Box>
                    <Avatar src={triageCase.assignee.avatar} size="sm" />
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Text color="gray.600">Assigned to {triageCase.assignee.name}</Text>
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    Last updated {triageCase.lastUpdated}
                  </Text>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </Box>

        <Box>
          <Flex align="center" mb={6}>
            <Icon as={FiPieChart} w={6} h={6} color="blue.500" mr={3} />
            <Heading size="lg">Dashboards</Heading>
          </Flex>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {dummyDashboards.map(dashboard => (
              <Card 
                key={dashboard.id}
                bg={cardBg}
                borderColor={borderColor}
                borderWidth="1px"
                borderRadius="lg"
                transition="all 0.2s"
                _hover={{ transform: 'translateY(-2px)', shadow: 'lg' }}
              >
                <CardHeader>
                  <Flex justify="space-between" align="center">
                    <Heading size="md">{dashboard.name}</Heading>
                    <Avatar src={dashboard.creator.avatar} size="sm" />
                  </Flex>
                </CardHeader>
                <CardBody>
                  <Text color="gray.600">{dashboard.description}</Text>
                  <Text fontSize="sm" color="gray.500" mt={3}>
                    Created by {dashboard.creator.name}
                  </Text>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </Box>
      </Stack>
    </Container>
  );
};

export default DisputeIntelligence;
