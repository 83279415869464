import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  VStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Grid,
  GridItem,
  HStack,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge
} from '@chakra-ui/react';
import { renderAsync } from 'docx-preview';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import * as pdfjsLib from 'pdfjs-dist/webpack';

import './index.scss';
import { useNavigate } from 'react-router-dom';
import { DownloadIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { useSearchParams } from 'react-router-dom';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs`;

const tagColors = [
  '#E2F5F7',
  '#F3F7E2',
  '#F7E2F2',
  '#FFB4B4',
  '#C5F6DE',
  '#87EDFB',
  '#CADAFB',
  '#CCC9F9',
];

const QuestionAnswering = ({ globalState, setGlobalState }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [claimantDocuments, setClaimantDocuments] = useState([]);
  const [respondentDocuments, setRespondentDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConversionLoading, setIsConversionLoading] = useState(false);
  const previewRef = useRef(null);
  const [isMock, setIsMock] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [showOcrMessage, setShowOcrMessage] = useState(false);

  const [previewFileType, setPreviewFileType] = useState('');

  useEffect(() => {
    const isMock = searchParams.get('is_mock');
    setIsMock(isMock);
  }, [searchParams]);

  useEffect(() => {
    if (selectedDocument) {
      const fileType = selectedDocument.file.name
        .split('.')
        .pop()
        .toLowerCase();
      if (fileType === 'docx') {
        setPreviewFileType('docx');
        const timer = setTimeout(() => {
          if (previewRef.current) {
            renderAsync(selectedDocument.file, previewRef.current);
          }
        }, 1);
        return () => clearTimeout(timer);
      } else if (fileType === 'pdf') {
        setPreviewFileType('pdf');
        const reader = new FileReader();
        reader.onload = function () {
          const typedarray = new Uint8Array(this.result);
          pdfjs
            .getDocument(typedarray)
            .promise.then(pdf => {
              setNumPages(pdf.numPages);
            })
            .catch(error => {
              console.error('Error loading PDF file:', error);
            });
        };
        reader.readAsArrayBuffer(selectedDocument.file);
      }
    }
  }, [selectedDocument, isConversionLoading]);

  useEffect(() => {
    const anyNeedsOCR = [...claimantDocuments, ...respondentDocuments].some(
      doc => doc.needsOCR
    );
    setShowOcrMessage(anyNeedsOCR);
  }, [claimantDocuments, respondentDocuments]);

  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const character = str.charCodeAt(i);
      hash = (hash << 5) - hash + character;
      hash |= 0;
    }
    return hash;
  }

  const handleDocumentUpload = async (event, documentType) => {
    const files = event.target.files;
    const updatedDocuments = await Promise.all(
      Array.from(files).map(async file => {
        const defaultTagName = cleanupFileName(file.name);
        let needsOCR = false;
        if (file.type === 'application/pdf') {
          needsOCR = await detectOCR(file);
        }
        return {
          id: uuidv4(),
          file,
          tags: [defaultTagName],
          showTagInput: false,
          needsOCR,
        };
      })
    );

    if (documentType === 'claimant') {
      setClaimantDocuments(prevDocs => [...prevDocs, ...updatedDocuments]);
    } else {
      setRespondentDocuments(prevDocs => [...prevDocs, ...updatedDocuments]);
    }
  };

  const cleanupFileName = fileName => {
    // Remove file extension
    let cleanName = fileName.replace(/\.[^/.]+$/, '');
    // Replace underscores and hyphens with spaces
    cleanName = cleanName.replace(/[_-]/g, ' ');
    // Capitalize each word
    cleanName = cleanName.replace(/\b\w/g, c => c.toUpperCase());
    // Trim extra spaces
    cleanName = cleanName.replace(/\s+/g, ' ').trim();

    return cleanName;
  };

  const detectOCR = async file => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        const uint8Array = new Uint8Array(arrayBuffer);

        try {
          // Load the PDF document
          const pdf = await pdfjsLib.getDocument({ data: uint8Array }).promise;
          const numPages = pdf.numPages;

          for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            // Get the page and its viewport
            const page = await pdf.getPage(pageNum);
            const viewport = page.getViewport({ scale: 1.0 });

            // Calculate page dimensions and area
            const pageWidth = viewport.width;
            const pageHeight = viewport.height;
            const pageArea = pageWidth * pageHeight;

            // Get the operator list, which contains information about page content
            const operatorList = await page.getOperatorList();
            // Extract image objects from the operator list
            const imageObjects = await getImageObjects(page, operatorList);

            for (const img of imageObjects) {
              // Calculate image size ratio (how much of the page width/height it covers)
              const widthRatio = img.width / pageWidth;
              const heightRatio = img.height / pageHeight;
              const sizeRatio = Math.max(widthRatio, heightRatio);

              // Calculate image coverage ratio (what fraction of the page area it covers)
              const coverageRatio = (img.width * img.height) / pageArea;

              // Check if the image is large enough to indicate a scanned document
              if (sizeRatio > 0.8 || coverageRatio > 0.7) {
                console.log('Large image detected, likely needs OCR');
                resolve(true); // Document likely needs OCR
                return;
              }
            }
          }
          resolve(false); // No large images found, likely doesn't need OCR
        } catch (error) {
          console.error('Error during PDF analysis:', error);
          resolve(true); // Assume OCR is needed in case of any error
        }
      };
      reader.onerror = error => {
        console.error('Error reading file:', error);
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  // Helper function to extract image objects from operatorList
  async function getImageObjects(page, operatorList) {
    const imageObjects = [];
    for (let i = 0; i < operatorList.fnArray.length; i++) {
      // Check for the paintImageXObject operation, which represents an image
      if (operatorList.fnArray[i] === pdfjsLib.OPS.paintImageXObject) {
        const imgName = operatorList.argsArray[i][0];
        if (imgName) {
          const img = await page.objs.get(imgName);
          if (img && img.width && img.height) {
            imageObjects.push({
              width: img.width,
              height: img.height,
            });
          }
        }
      }
    }
    return imageObjects;
  }

  const handleTagAdd = (documentId, tag, documentType) => {
    const updateDocuments = prevDocs =>
      prevDocs.map(doc =>
        doc.id === documentId ? { ...doc, tags: [...doc.tags, tag] } : doc
      );

    if (documentType === 'claimant') {
      setClaimantDocuments(updateDocuments);
    } else {
      setRespondentDocuments(updateDocuments);
    }
  };

  const toggleTagInput = (documentId, value, documentType) => {
    const updateDocuments = prevDocs =>
      prevDocs.map(doc =>
        doc.id === documentId ? { ...doc, showTagInput: value } : doc
      );

    if (documentType === 'claimant') {
      setClaimantDocuments(updateDocuments);
    } else {
      setRespondentDocuments(updateDocuments);
    }
  };

  const handleTagRemove = (documentId, tag, documentType) => {
    const updateDocuments = prevDocs =>
      prevDocs.map(doc =>
        doc.id === documentId
          ? { ...doc, tags: doc.tags.filter(t => t !== tag) }
          : doc
      );

    if (documentType === 'claimant') {
      setClaimantDocuments(updateDocuments);
    } else {
      setRespondentDocuments(updateDocuments);
    }
  };

  const handleAnalyze = async () => {
    try {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/${
        isMock ? 'mock_multi_doc_analysis' : 'multi_doc_analysis'
      }`;

      const batchSize = 5;
      let convertedDocuments = [...claimantDocuments, ...respondentDocuments];

      for (let i = 0; i < convertedDocuments.length; i += batchSize) {
        const batch = convertedDocuments.slice(i, i + batchSize);
        const convertedBatch = await Promise.all(
          batch.map(async doc => {
            if (doc.file.type === 'application/pdf' && !doc.needsOCR) {
              console.log('THIS WAS HIT!!!');
              const formData = new FormData();
              formData.append('docs', doc.file);

              const response = await axios.post(
                `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/convert_doc`,
                formData,
                {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                  responseType: 'blob',
                }
              );

              const newFile = new File(
                [response.data],
                doc.file.name.replace('.pdf', '.docx'),
                {
                  type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                }
              );

              return { ...doc, file: newFile };
            }
            return doc;
          })
        );

        convertedDocuments.splice(i, batchSize, ...convertedBatch);

        // Add a delay between batches
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      const formData = new FormData();
      convertedDocuments.forEach(doc => {
        formData.append('doc_id', doc.id);
        formData.append('docs', doc.file);
        formData.append('tags', JSON.stringify(doc.tags));
        formData.append('needs_ocr', doc.needsOCR);
      });

      // Add document metadata
      formData.append(
        'document_metadata',
        JSON.stringify(
          convertedDocuments.map(doc => ({
            id: doc.id,
            name: doc.file.name,
            tags: doc.tags,
            needsOCR: doc.needsOCR,
          }))
        )
      );

      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setGlobalState({
        ...response.data,
        documents: convertedDocuments,
      });
      navigate(
        `/dispute_analysis_new/${response.data.case_id}?is_mock=${isMock}`
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
    }
  };

  const handleConvertAll = async () => {
    try {
      setIsConversionLoading(true);
      const batchSize = 5;
      let updatedClaimantDocuments = [...claimantDocuments];
      let updatedRespondentDocuments = [...respondentDocuments];

      const convertBatch = async documents => {
        for (let i = 0; i < documents.length; i += batchSize) {
          const batch = documents.slice(i, i + batchSize);
          const convertedBatch = await Promise.all(
            batch.map(async doc => {
              if (doc.file.type === 'application/pdf') {
                const formData = new FormData();
                formData.append('docs', doc.file);

                let endpoint;
                if (doc.needsOCR) {
                  endpoint = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/ocr_test`;
                  formData.append('needs_ocr', true);
                } else {
                  endpoint = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/convert_doc`;
                }

                const response = await axios.post(endpoint, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                  responseType: 'blob',
                });

                const newFile = new File(
                  [response.data],
                  doc.file.name.replace('.pdf', '.docx'),
                  {
                    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  }
                );

                return { ...doc, file: newFile, needsOCR: false };
              }
              return doc;
            })
          );

          documents.splice(i, batchSize, ...convertedBatch);

          // Add a delay between batches
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
        return documents;
      };

      updatedClaimantDocuments = await convertBatch(updatedClaimantDocuments);
      updatedRespondentDocuments = await convertBatch(
        updatedRespondentDocuments
      );

      setClaimantDocuments(updatedClaimantDocuments);
      setRespondentDocuments(updatedRespondentDocuments);

      setShowOcrMessage(false);
      setIsConversionLoading(false);
    } catch (error) {
      setIsConversionLoading(false);
      console.error('Error during conversion:', error);
    }
  };

  const renderDocumentList = (documents, documentType) => (
    <VStack spacing={4} align="stretch" overflowY="auto">
      {documents.map(doc => (
        <Box
          key={doc?.id}
          className={
            doc.id === selectedDocument?.id ? 'doc-card active' : 'doc-card'
          }
          borderRadius="md"
          cursor="pointer"
          onClick={() => setSelectedDocument(doc)}
        >
          <Text fontWeight="bold">{doc.file.name}</Text>
          <Flex mt={2} wrap="wrap">
            {doc.tags.map((tag, index) => (
              <Tag
                key={tag}
                className="doc-tag"
                mr={2}
                mb={2}
                backgroundColor={
                  tagColors[Math.abs(hashCode(tag)) % tagColors.length]
                }
              >
                <TagLabel>{tag}</TagLabel>
                <TagCloseButton
                  onClick={e => {
                    e.stopPropagation();
                    handleTagRemove(doc.id, tag, documentType);
                  }}
                />
              </Tag>
            ))}
            {doc.showTagInput ? (
              <Input
                size="sm"
                placeholder="Add tag"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    handleTagAdd(doc.id, e.target.value, documentType);
                    e.target.value = '';
                    toggleTagInput(doc.id, false, documentType);
                  }
                }}
                onBlur={() => toggleTagInput(doc.id, false, documentType)}
                autoFocus
              />
            ) : (
              <Button
                size="sm"
                className="add-tag-btn"
                onClick={() => toggleTagInput(doc.id, true, documentType)}
              >
                + Add Tag
              </Button>
            )}
          </Flex>
          {doc.needsOCR && (
            <HStack ml={2}>
              <InfoOutlineIcon color="yellow.500" />
              <Text color="yellow.500">
                This file may be processed to extract text
              </Text>
            </HStack>
          )}
        </Box>
      ))}
    </VStack>
  );

  return (
    <div className="page-content-margin audit-view">
      <Grid className="page-card" templateColumns="4fr 6fr" gap={8}>
        <GridItem className="doc-card-wrapper">
          <Box p={4}>
            {/* <Heading as="h2" size="sm" textTransform={'uppercase'} mb={4}>
              Upload Case Documents
            </Heading> */}
            <Accordion variant={'default'} className='document-upload-accordion' defaultIndex={0}>
              <AccordionItem>
                <AccordionButton>
                  <HStack w={'100%'} justify={'space-between'} align={'center'}>
                    <Box flex="1" textAlign="left">
                      Claimant's Documents
                      <Badge ml={4} variant={'default'}>{claimantDocuments.length}</Badge>
                    </Box>

                    <AccordionIcon />
                  </HStack>
                </AccordionButton>
                <AccordionPanel className='doc-accordion-panel' pb={4}>
                  {renderDocumentList(claimantDocuments, 'claimant')}
                  <Flex my={4} w={'100%'} justify={'center'} align={'center'}>
                    <Button
                      leftIcon={<DownloadIcon className="upload-icon" />}
                      variant="default_muted"
                      mb={4}
                      as="label"
                      htmlFor="claimant-document-upload"
                      cursor="pointer"
                    >
                      Upload
                    </Button>
                    <Input
                      type="file"
                      accept=".docx,.pdf"
                      multiple
                      onChange={e => handleDocumentUpload(e, 'claimant')}
                      display="none"
                      id="claimant-document-upload"
                    />
                  </Flex>
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <h2>
                <AccordionButton>
                    <HStack
                      w={'100%'}
                      justify={'space-between'}
                      align={'center'}
                    >
                      <Box flex="1" textAlign="left">
                        Respondent's Documents
                        <Badge ml={4} variant={'default'}>{respondentDocuments.length}</Badge>
                      </Box>
                      <AccordionIcon />
                    </HStack>
                  </AccordionButton>
                </h2>
                <AccordionPanel className='doc-accordion-panel' pb={4}>
                  {renderDocumentList(respondentDocuments, 'respondent')}
                  <Flex my={4} w={'100%'} justify={'center'} align={'center'}>
                      <Button
                        leftIcon={<DownloadIcon className="upload-icon" />}
                        variant="default_muted"
                        mb={4}
                        as="label"
                        htmlFor="respondent-document-upload"
                        cursor="pointer"
                      >
                        Upload
                      </Button>
                    <Input
                      type="file"
                      accept=".docx,.pdf"
                      multiple
                      onChange={e => handleDocumentUpload(e, 'respondent')}
                      display="none"
                      id="respondent-document-upload"
                    />
                  </Flex>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>

            {(claimantDocuments.some(
              doc => doc.file.type === 'application/pdf'
            ) ||
              respondentDocuments.some(
                doc => doc.file.type === 'application/pdf'
              )) && (
              <Box bg={'yellow.500'} borderRadius={4} p={4} mb={4}>
                <Flex
                  direction={'row'}
                  mr={2}
                  align={'center'}
                  justify={'space-between'}
                >
                  <Text>PDF files will be converted to text for analysis</Text>
                  <Flex>
                    <Button
                      isLoading={isConversionLoading}
                      mt={2}
                      colorScheme="black"
                      variant={'outline'}
                      onClick={handleConvertAll}
                    >
                      Convert All
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            )}

            <Button
              isLoading={isLoading}
              mt={4}
              variant={'default'}
              onClick={handleAnalyze}
              width="100%"
            >
              Start Analysis
            </Button>
          </Box>
        </GridItem>
        <GridItem>
          <Box className="document-preview-wrapper" p={4}>
            {previewFileType === 'docx' ? (
              <Box height={'80vh'} overflowY={'auto'} ref={previewRef} />
            ) : (
              <>
                {selectedDocument &&
                  selectedDocument.file.name.split('.').pop().toLowerCase() ===
                    'pdf' && (
                    <Document
                      file={selectedDocument.file}
                      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ))}
                    </Document>
                  )}
              </>
            )}
          </Box>
        </GridItem>
      </Grid>
    </div>
  );
};

export default QuestionAnswering;
