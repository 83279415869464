import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { VStack, Button, Box } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { Handle, Position } from 'reactflow';

import { ReactComponent as AddIcon } from '../../images/add.svg'
import { ReactComponent as EditIcon } from '../../images/edit.svg'

const AutoResizeTextarea = ({ value, onChange, ...props }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [value]);

  return (
    <textarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      style={{
        resize: 'none',
        overflow: 'hidden',
        minHeight: '100px',
        width: '100%',
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #E2E8F0',
        outline: 'none',
      }}
      {...props}
    />
  );
};

const InstructionNode = ({ data }) => {
  const [instruction, setInstruction] = useState(data.instruction_body || '');
  const [isEditing, setIsEditing] = useState(false);

  const handleAddInstruction = async () => {
    if (instruction.trim()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/add_instruction`,
          {
            snippet_id: data._id.$oid,
            instruction_text: instruction,
          }
        );
        setIsEditing(false);
      } catch (error) {
        console.error('Error adding instruction:', error);
      }
    }
  };

  return (
    <div className={`custom-node instruction-node ${data.isFiltered ? '' : 'faded-node'}`}>
      <Handle type="target" position={Position.Top} />
      <Handle type="target" position={Position.Left} />
      <div className="instruction-header">Instruction</div>
      <VStack width="100%" align="stretch" spacing={2}>
        {isEditing ? (
          <AutoResizeTextarea
            value={instruction}
            onChange={(e) => setInstruction(e.target.value)}
            placeholder="Add Instructions"
          />
        ) : (
          <Box
            minHeight="100px"
            p={2}
            borderWidth={1}
            borderRadius="md"
            onClick={() => setIsEditing(true)}
            _hover={{ background: "gray.100", cursor: "pointer" }}
            whiteSpace="pre-wrap"
          >
            {instruction || "Click to add instructions"}
          </Box>
        )}
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
          >
            {isEditing ? (
              <VStack>
                <Button
                  onClick={handleAddInstruction}
                  variant="solid"
                  colorScheme="green"
                  size="sm"
                  width="100%"
                >
                  Save
                </Button>
                <Button
                  onClick={() => setIsEditing(false)}
                  variant="outline"
                  colorScheme="red"
                  size="sm"
                  width="100%"
                >
                  Cancel
                </Button>
              </VStack>
            ) : (
              <Button
                onClick={() => setIsEditing(true)}
                variant="outline_muted"
                size="sm"
                leftIcon={instruction ? <AddIcon fill='#929292' /> : <EditIcon fill='#929292'/>}
                width="100%"
              >
                {instruction ? "Edit Instruction" : "Add Instruction"}
              </Button>
            )}
          </motion.div>
        </AnimatePresence>
      </VStack>
      <Handle type="source" position={Position.Bottom} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

export default InstructionNode;