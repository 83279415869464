
const removeAllSpaces = text => {
  // Remove all spaces from the text to be searched
  return text.replace(/\s+/g, '');
};


const flattenDocument = node => {
  let nodes = [];
  if (node.nodeType === Node.TEXT_NODE) {
    nodes.push(node);
  } else if (
    node.nodeType === Node.ELEMENT_NODE &&
    node.tagName !== 'STYLE' &&
    node.tagName !== 'SCRIPT'
  ) {
    for (let child of node.childNodes) {
      nodes = nodes.concat(flattenDocument(child));
    }
  }
  return nodes.filter(
    n => n.nodeType === Node.TEXT_NODE && n.textContent.trim() !== ''
  );
};

const normalizeTextWithOffsets = text => {
  const normalizedText = [];
  const originalOffsets = [];
  let offset = 0;
  let lastCharWasSpace = false;

  for (let i = 0; i < text.length; i++) {
    if (/\s/.test(text[i])) {
      if (!lastCharWasSpace) {
        normalizedText.push(' ');
        originalOffsets.push(offset);
        lastCharWasSpace = true;
      }
    } else {
      normalizedText.push(text[i]);
      originalOffsets.push(offset);
      lastCharWasSpace = false;
    }
    offset++;
  }

  return {
    normalizedText: normalizedText.join(''),
    originalOffsets,
  };
};

const findTextInFlattenedNodes = (nodes, text) => {
  const combinedTextContent = nodes.map(node => node.textContent).join('');
  const spaceFreeContent = removeAllSpaces(combinedTextContent);
  const spaceFreeText = removeAllSpaces(text);

  const startIndex = spaceFreeContent.indexOf(spaceFreeText);
  if (startIndex === -1) return null;

  const endIndex = startIndex + spaceFreeText.length;

  // Find the corresponding original offsets
  let startNodeIndex = null;
  let endNodeIndex = null;
  let startOffset = null;
  let endOffset = null;
  let charCount = 0;

  for (let i = 0; i < nodes.length; i++) {
    const nodeText = removeAllSpaces(nodes[i].textContent);
    const nodeTextLength = nodeText.length;

    if (startNodeIndex === null && charCount + nodeTextLength > startIndex) {
      startNodeIndex = i;
      startOffset = startIndex - charCount;
    }

    if (startNodeIndex !== null && charCount + nodeTextLength >= endIndex) {
      endNodeIndex = i;
      endOffset = endIndex - charCount;
      break;
    }
    charCount += nodeTextLength;
  }
  return { startNodeIndex, endNodeIndex, startOffset, endOffset };
};

const highlightTextInRange = (nodes, startNodeIndex, endNodeIndex, startOffset, endOffset) => {
  // Remove previous highlights
  const prevHighlights = document.querySelectorAll('.doc_location_highlight');
  prevHighlights.forEach(highlight => {
    const parent = highlight.parentNode;
    if (parent) {
      while (highlight.firstChild) {
        parent.insertBefore(highlight.firstChild, highlight);
      }
      parent.removeChild(highlight);
    }
  });

  for (let i = startNodeIndex; i <= endNodeIndex; i++) {
    const node = nodes[i];
    const nodeText = node.textContent;
    const localStart = i === startNodeIndex ? findOriginalOffset(nodeText, startOffset) : 0;
    const localEnd = i === endNodeIndex ? findOriginalOffset(nodeText, endOffset) : nodeText.length;

    if (localStart < localEnd) {
      const beforeText = nodeText.slice(0, localStart);
      const highlightText = nodeText.slice(localStart, localEnd);
      const afterText = nodeText.slice(localEnd);
      // Create new nodes
      const beforeNode = document.createTextNode(beforeText);
      const highlightNode = document.createElement('span');
      highlightNode.className = 'doc_location_highlight';
      highlightNode.textContent = highlightText;
      const afterNode = document.createTextNode(afterText);
      // Replace the original node
      if (node.parentNode) {
        node.parentNode.insertBefore(beforeNode, node);
        node.parentNode.insertBefore(highlightNode, node);
        node.parentNode.insertBefore(afterNode, node);
        node.parentNode.removeChild(node);
      }
    }
  }
};

const findOriginalOffset = (text, spaceFreeOffset) => {
  let spaceFreeCount = 0;
  for (let i = 0; i < text.length; i++) {
    if (text[i] !== ' ') {
      if (spaceFreeCount === spaceFreeOffset) {
        return i;
      }
      spaceFreeCount++;
    }
  }
  return text.length;
};

const highlightAndScrollToText = (container, text) => {
  if (!container) return;
  const nodes = flattenDocument(container);
  const result = findTextInFlattenedNodes(nodes, text);
  if (result) {
    const { startNodeIndex, endNodeIndex, startOffset, endOffset } = result;

    // Highlight the text
    highlightTextInRange(
      nodes,
      startNodeIndex,
      endNodeIndex,
      startOffset,
      endOffset
    );

    // Find and scroll to the highlighted element
    const highlightedElement = container.querySelector('.doc_location_highlight');
    if (highlightedElement) highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  } else {
    console.log(
      'Text not found. Normalized combined text:',
      normalizeTextWithOffsets(nodes.map(node => node.textContent).join(''))
        .normalizedText
    );
    console.log(
      'Normalized target text:',
      normalizeTextWithOffsets(text).normalizedText
    );
  }
};

export { highlightAndScrollToText, removeAllSpaces }