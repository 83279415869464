import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

import DisputeViewTabs from './DisputeViewTabs'

import { Box, Text } from '@chakra-ui/react';
import './index.scss'

const DisputeAnalysisResults = ({ globalState, setGlobalState }) => {
    const { caseID, analysisID } = useParams();
    return (
        <div className="page-content-margin dispute_analysis-page">
            <Text className='analysis-title'>{globalState?.case_title}</Text>
            <Box className="dispute_analysis-view">
            <DisputeViewTabs suggestedSearchTerms={globalState?.suggested_search_terms || []} documents={globalState?.documents} caseID={caseID} analysisID={analysisID}/>
            </Box>
        </div>
    )
}

export default DisputeAnalysisResults