import { useEffect, useRef, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Divider,
    Flex,
    Box,
    Text,
    Card,
    VStack,
    HStack,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    useToast,
    Editable,
    EditablePreview,
    EditableInput,
    EditableTextarea
} from '@chakra-ui/react';
import { ReactComponent as SearchIcon } from '../../images/search.svg';
import axios from 'axios';
import { renderAsync } from 'docx-preview';

import { highlightAndScrollToText, removeAllSpaces } from '../../helpers/DocHighlightHelper'

const VerifyMetadataModal = ({ isOpen, onClose, docPreview, metadataPayload = {} }) => {
    const containerRef = useRef(null);
    const [isRendered, setIsRendered] = useState(false);
    const [searchResults, setSearchResults] = useState([])
    const [newMetadataValue, setNewMetadataValue] = useState('')
    const [isUpdating, setIsUpdating] = useState(false)
    const toast = useToast()

    useEffect(() => {
        console.log(metadataPayload)
        if (metadataPayload?.metadata) {
            fetchAltenateFieldValues();
            setNewMetadataValue(metadataPayload.metadata.value || '');
        }
    }, [metadataPayload])

    const fetchAltenateFieldValues = async () => {
        const payload = {
            case_id: metadataPayload.caseID,
            file_id: metadataPayload.fileID,
            field_id: metadataPayload.metadata.id
        }
        const response = await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/search_doc_for_metadata`, payload);
        setSearchResults(response.data.results);
    }

    const handleUpdateMetadata = async () => {
        setIsUpdating(true)
        try {
            const payload = {
                case_id: metadataPayload.caseID,
                field_id: metadataPayload.metadata.id,
                value: newMetadataValue
            }
            await axios.post(`${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/update_metadata`, payload);
            toast({
                title: "Metadata updated",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            onClose();
        } catch (error) {
            console.error('Error updating metadata:', error);
            toast({
                title: "Error updating metadata",
                description: error.response?.data?.error || error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsUpdating(false)
        }
    }

    const goToDoc = (doc_text) => {
        setTimeout(() => {
            const processedText = removeAllSpaces(doc_text);
            highlightAndScrollToText(containerRef.current, processedText);
        }, 10);
    };

    useEffect(() => {
        setTimeout(() => {
            if (containerRef.current) {
                const renderDocument = async () => {
                    try {
                        await renderAsync(docPreview, containerRef.current, null, {
                            ignoreWidth: false,
                            ignoreHeight: false,
                        });
                        setIsRendered(true);
                    } catch (error) {
                        console.error('Error rendering document:', error);
                        // Handle error (e.g., show an error message to the user)
                    }
                };
                renderDocument();
            }
        }, 500);
    }, [isOpen, docPreview]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent className='verify-metadata-modal'>
                <ModalHeader>Manually Fill Metadata</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {!isRendered && <div>Loading document...</div>}
                    <Flex gap={8} height="70vh">
                        <Box width={'50%'} overflowY="auto">
                            <VStack align={'flex-start'} spacing={4}>
                                <Card variant={'outline'} p={[2, 4]} width={'100%'}>
                                    <Text fontWeight="bold">{metadataPayload?.metadata?.field}</Text>
                                    <HStack mt={4} spacing={2} justifyContent={'space-between'}>
                                        <Box width={'48%'}>
                                            <Text fontSize="sm" color="gray.500">Current Value</Text>
                                            <Text fontSize="lg" fontWeight="medium">{metadataPayload?.metadata?.value}</Text>
                                        </Box>
                                        <Text fontSize={'1.4rem'}>&#10230;</Text>
                                        <VStack width={'48%'} align={'flex-start'}>
                                            <Text fontSize="sm" color="gray.500" ml={4}>New Value</Text>
                                            <Editable
                                                value={newMetadataValue}
                                                onChange={(value) => setNewMetadataValue(value)}
                                                placeholder="New value"
                                                fontSize="lg"
                                                width="100%"
                                            >
                                                <EditablePreview
                                                    py={2}
                                                    px={4}
                                                    _hover={{
                                                        background: "gray.100"
                                                    }}
                                                />
                                                <EditableTextarea
                                                    py={2}
                                                    px={4}
                                                />
                                            </Editable>
                                        </VStack>
                                    </HStack>
                                    <Button
                                        mt={4}
                                        isDisabled={!newMetadataValue}
                                        isLoading={isUpdating}
                                        variant={'default'}
                                        size={'md'}
                                        onClick={handleUpdateMetadata}
                                    >
                                        Save changes
                                    </Button>
                                </Card>

                                <Text>Search document</Text>
                                <InputGroup>
                                    <InputLeftElement mt={2} ml={3} pointerEvents='none'>
                                        <SearchIcon fill='#a8a8a8' />
                                    </InputLeftElement>
                                    <Input value={"What is the name of the claimant?"} variant={'search'} placeholder='Ask a question' />
                                </InputGroup>
                                <VStack align="stretch" width="100%" spacing={2}>
                                    {searchResults.map((result, index) =>
                                        <Card onClick={() => goToDoc(result.text)} key={index} variant={'outline'} p={3} width={'100%'}>
                                            <Text fontSize="sm">{result.text}</Text>
                                        </Card>)}
                                </VStack>
                            </VStack>
                        </Box>
                        <Box width={'50%'} height="100%" overflowY="hidden">
                            <div className='verify-preview-container' ref={containerRef} style={{ height: '100%', overflowY: 'auto' }} />
                        </Box>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default VerifyMetadataModal;