import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Text, Flex } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import './index.scss';

const config = {
  actions: [
    {
      type: 'show_references',
      label: 'Show References',
      variant: 'default',
    },
    {
      type: 'add_to_notes',
      label: 'Add to Notes',
      variant: 'default',
    },
  ],
};



const ActionButtons = ({
  isTextActionLoading,
  selectedText,
  selectionPosition,
  onElaborateSelect,
  onAddToSnippetsSelect,
  hideActions,
}) => {
  const [selectedAction, setSelectedAction] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const tooltipRef = useRef(null);

  useEffect(() => {
    const dynamicContentBox = document.querySelector('.dynamic-content-box');
    if (dynamicContentBox && tooltipRef.current) {
      const dynamicContentRect = dynamicContentBox.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      setTooltipPosition({
        top: `${selectionPosition.top}px`,
        left: `${dynamicContentRect.left - 330}px`,
      });
    }
  }, [selectionPosition]);

  const handleActionClick = (actionType) => {
    console.log("Action clicked:", actionType);
    setSelectedAction(actionType);
    if (actionType === 'show_references') {
      onElaborateSelect(actionType);
    } else if (actionType === 'add_to_notes') {
      onAddToSnippetsSelect();
      setIsDone(true);

      setTimeout(() => {
        setIsDone(false);
        hideActions();
      }, 2500);
    }
  };

  if (!selectedText) return null;

  return (
    <div
      ref={tooltipRef}
      style={{
        position: 'absolute',
        zIndex: 1000,
        top: tooltipPosition.top,
        left: tooltipPosition.left,
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      }}
    >
      <AnimatePresence>
        <motion.div
          className="text-actions-wrapper"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
        >
          <motion.div
            className="action-item"
            initial={{ opacity: 1, flex: 1 }}
            animate={{
              opacity: isTextActionLoading || isDone ? 1 : 1,
              flex: isTextActionLoading || isDone ? 1 : 1,
              width: isTextActionLoading || isDone ? '150px' : '290px',
              padding: isTextActionLoading || isDone ? '10px 16px' : '10px',
              margin: isTextActionLoading || isDone ? '0 5px' : 0,
            }}
            exit={{ opacity: 0, flex: 0, width: 0, padding: 0, margin: 0, transition: { delay: 1 } }}
            transition={{ ease: 'easeOut', duration: 0.3, delay: 0.2 }}
          >
            {isTextActionLoading ? (
              <div className="action-loader">
                <div className="answer-loader">
                  <div className="loading-items">
                    <div className="loading">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                  <Text className="loading-text">Loading..</Text>
                </div>
              </div>
            ) : isDone ? (
              <AnimatePresence>
                <Flex className="done-state">
                  <motion.div
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    exit={{
                      opacity: 0,
                      y: -10,
                      transition: { ease: 'easeOut', duration: 0.25 },
                    }}
                  >
                    <CheckIcon mr={4} />
                    <Text display={'inline-block'} fontWeight={600}>
                      Added
                    </Text>
                  </motion.div>
                </Flex>
              </AnimatePresence>
            ) : (
              config.actions.map((action) => (
                <Button
                  key={action.type}
                  onClick={() => handleActionClick(action.type)}
                  variant={action.variant}
                  size="sm"
                  mr={2}
                >
                  {action.label}
                </Button>
              ))
            )}
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default ActionButtons;
