import React, {useEffect} from 'react';
import { deleteCookie } from '../../helpers/cookies';
import { useNavigate } from "react-router-dom";

const Logout = ({globalState, setGlobalState}) => {
    const navigate = useNavigate();
    useEffect(() => {
        deleteCookie('auth-token');
        setGlobalState({});
        console.log("LOGOUT HIT")
        navigate("/")
    }, [])
    return (
        <>
            <h1>Logging user out</h1>
        </>
    )
}

export default Logout;