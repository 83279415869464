import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import {
  Box,
  Flex,
  VStack,
  Text,
  Link,
  Container,
  Skeleton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import axios from 'axios';

import useTextSelection from '../../hooks/useTextSelection';
import VisualTimeline from '../MultiDocResults/VisualTimeline';
import ModifiersBar from './ModifiersBar';
import RenderInconsistencies from './RenderInconsistencies';
import RenderKeyIssues from './RenderKeyIssues';
import ActionButtons from '../../components/TextActions';
import { showReferences } from '../../helpers/ShowReferences';

const sections = [
  { id: 'timeline', title: 'Timeline', fetch_key: 'date_events', show_for_partial_analysis: true },
  { id: 'summary', title: 'Summary', fetch_key: 'summaries', show_for_partial_analysis: true },
  { id: 'key_issues', title: 'Key issues', fetch_key: 'key_issues', show_for_partial_analysis: false },
  {
    id: 'inconsistencies',
    title: 'Inconsistencies',
    fetch_key: 'inconsistencies',
    show_for_partial_analysis: true
  },
];

const modifiers = {
  timeline: {
    mode: 'additive',
    values: [
      { title: 'Transactions', key: 'transaction' },
      { title: 'Correspondence', key: 'correspondence' },
      { title: 'Milestones', key: 'milestone' },
      { title: 'Miscellaneous', key: 'miscellaneous' },
    ],
  },
  summary: {
    mode: 'exclusive',
    values: [
      { title: 'Complete Dispute', key: 'complete_dispute' },
      { title: "Claimant's Pleading", key: 'claimant_pleading' },
      { title: "Respondent's Pleading", key: 'respondent_pleading' },
    ],
  },
  key_issues: {},
  inconsistencies: {
    mode: 'tiered',
    values: [
      { title: 'Claimant', key: 'claimant_inconsistencies' },
      { title: 'Respondent', key: 'respondent_inconsistencies' },
    ],
  },
};

const AnalysisTab = ({ analysisID, documents, goToDoc }) => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  const [sectionData, setSectionData] = useState({});
  const [loading, setLoading] = useState({});
  const [appliedModifiers, setAppliedModifiers] = useState([]);
  const [isTextActionLoading, setIsTextActionLoading] = useState(false);
  const [analysisMode, setAnalysisMode] = useState({});
  const containerRef = useRef(null);
  const sectionRefs = useRef({});
  const selectionWrapperRef = useRef(null);

  const {
    selectedText,
    selectionPosition,
    selectedSection,
    setSelectedText,
    selectionMetadata,
    resetSelection,
  } = useTextSelection(selectionWrapperRef, sectionRefs);

  const currentSectionModifiers = useMemo(() => modifiers[activeSection], [activeSection]);

  const fetchSectionData = useCallback(async (id) => {
    const section = sections.find(s => s.id === id);
    if (section.fetch_key && !sectionData[id]) {
      setLoading(prev => ({ ...prev, [id]: true }));
      try {
        const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/fetch_result`;
        const response = await axios.get(url, {
          params: {
            analysis_id: analysisID,
            key: section.fetch_key,
            mock: false,
          },
        });
        const { data } = response;
        setAnalysisMode(data.analysis_mode);
        setSectionData(prev => ({ ...prev, [id]: data }));
      } catch (error) {
        console.error(`Error fetching data for ${id}:`, error);
      } finally {
        setLoading(prev => ({ ...prev, [id]: false }));
      }
    }
  }, [analysisID, sectionData]);

  useEffect(() => {
    fetchSectionData(sections[0].id);
  }, [fetchSectionData]);

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const containerTop = container.getBoundingClientRect().top;
      const containerHeight = container.clientHeight;

      let currentSection = sections[0].id;

      for (const section of sections) {
        const sectionElement = sectionRefs.current[section.id];
        if (!sectionElement) continue;

        const sectionTop =
          sectionElement.getBoundingClientRect().top - containerTop;
        const sectionBottom = sectionTop + sectionElement.clientHeight;

        if (
          sectionTop <= containerHeight / 3 &&
          sectionBottom > containerHeight / 3
        ) {
          currentSection = section.id;
          break;
        }
      }

      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
        fetchSectionData(currentSection);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [activeSection, fetchSectionData]);

  const handleSectionLinkClick = useCallback((id) => {
    const sectionElement = document.getElementById(id);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    fetchSectionData(id);
  }, [fetchSectionData]);

  const handleExportCompleteAnalysis = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/export`;
      const response = await axios.get(url, {
        params: {
          analysis_id: analysisID
        },
        responseType: 'blob',
      });

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;

      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'case_analysis.docx';

      link.download = filename;
      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error exporting analysis:', error);
    }
  }, [analysisID]);

  const onElaborateSelect = useCallback(async (actionType) => {
    try {
      setIsTextActionLoading(true);
      const referenceData = await showReferences(
        selectedText,
        'fact_check',
        analysisID,
        false,
        setIsTextActionLoading,
        () => {},
        goToDoc,
        setSelectedText,
        selectionMetadata
      );

      if (referenceData) {
        resetSelection();
      }
    } catch (error) {
      console.error('Error showing references:', error);
    } finally {
      setIsTextActionLoading(false);
    }
  }, [selectedText, analysisID, goToDoc, setSelectedText, selectionMetadata, resetSelection]);

  const onAddToSnippetsSelect = useCallback(async () => {
    setIsTextActionLoading(true);
    const payload = {
      analysis_id: analysisID,
      snippet_body: selectedText,
      snippet_metadata: selectionMetadata,
    };
    const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/add_to_snippets`;
    await axios.post(url, payload);
    setIsTextActionLoading(false);
  }, [analysisID, selectedText, selectionMetadata]);

  const getSecondLayerModifiers = useCallback((appliedFirstLevelModifier) => {
    if (!sectionData || !activeSection || !sectionData[activeSection]) return [];

    const currentSectionContent =
      sectionData[activeSection]?.[appliedFirstLevelModifier];
    return [
      ...new Set(
        currentSectionContent?.map(item => item.source_document) || []
      ),
    ];
  }, [sectionData, activeSection]);

  const renderSectionContent = useMemo(() => (id) => {
    if (loading[id]) {
      return (
        <VStack align="stretch" spacing={4}>
          {[...Array(6)].map((_, index) => (
            <Skeleton key={index} height="20px" />
          ))}
        </VStack>
      );
    }
    if (sectionData[id]) {
      let timelineFilterMode = appliedModifiers.filter(modifier =>
        modifiers.timeline.values.some(value => value.key === modifier)
      );

      if (id === 'timeline') {
        return (
          <VisualTimeline
            goToDoc={goToDoc}
            timelineData={sectionData[id].timeline}
            filterMode={timelineFilterMode}
          />
          // <Text>Timeline</Text>
        );
      } else if (id === 'inconsistencies') {
        const additionalModifiers = appliedModifiers.slice(1);

        if (appliedModifiers[0] === 'claimant_inconsistencies') {
          return (
            <RenderInconsistencies
              data={sectionData[id]?.claimant_inconsistencies}
              party="Claimant"
              additionalModifiers={additionalModifiers}
            />
          );
        } else if (appliedModifiers[0] === 'respondent_inconsistencies') {
          return (
            <RenderInconsistencies
              data={sectionData[id]?.respondent_inconsistencies}
              party="Respondent"
              additionalModifiers={additionalModifiers}
            />
          );
        }
      } else if (id === 'key_issues') {
        return <RenderKeyIssues data={sectionData[id].key_issues} />;
      } else if (id === 'summary') {
        const summaryContent = appliedModifiers[0] === 'claimant_pleading'
          ? sectionData[id]?.claimant
          : appliedModifiers[0] === 'respondent_pleading'
          ? sectionData[id]?.respondent
          : sectionData[id]?.complete;

        const sectionType = appliedModifiers[0] === 'claimant_pleading'
          ? 'claimant'
          : appliedModifiers[0] === 'respondent_pleading'
          ? 'respondent'
          : 'complete_summary';

        const documentSource = appliedModifiers[0] === 'claimant_pleading'
          ? "Claimant's Pleading"
          : appliedModifiers[0] === 'respondent_pleading'
          ? "Respondent's Pleading"
          : "Pleadings";

        return (
          <div
            className='summary-container'
            data-section-type={sectionType}
            data-section-document-source={documentSource}
            dangerouslySetInnerHTML={{ __html: summaryContent }}
          />
        );
      }
    }
    return (
      <VStack>
        {[...Array(3)].map((_, index) => (
          <Skeleton key={index} height="20px" />
        ))}
      </VStack>
    );
  }, [loading, sectionData, appliedModifiers, goToDoc]);

  return (
    <Flex h="85vh" position={'relative'}>
      <Flex w={'100%'} borderRight="1px solid" borderColor="gray.200">
        <Box w="200px" p={4}>
          <VStack
            justify={'space-between'}
            h={'100%'}
            alignItems={'flex-start'}
          >
            <VStack align="stretch" spacing={4}>
              {sections.map(({ id, title, show_for_partial_analysis }) => (
                (!analysisMode.is_partial || show_for_partial_analysis) && (
                  <Link
                    key={id}
                    onClick={() => handleSectionLinkClick(id)}
                    textTransform={'uppercase'}
                    fontWeight={'600'}
                    color={activeSection === id ? '#00B870' : '#B9B9B9'}
                    _hover={{ textDecoration: 'none', color: '#00B870' }}
                  >
                    {title}
                  </Link>
                )
              ))}
            </VStack>
            <VStack>
              <Menu>
                <MenuButton
                  w={'100%'}
                  variant={'outline'}
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  Export
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={handleExportCompleteAnalysis} minH="48px">
                    <span>Export Complete Analysis</span>
                  </MenuItem>
                  <MenuItem minH="40px" onClick={() => {}}>
                    <span>Export Notes</span>
                  </MenuItem>
                </MenuList>
              </Menu>
            </VStack>
          </VStack>
        </Box>

        <Box flex={1} overflowY="auto" position="relative" ref={containerRef}>
          <Container
            maxW="container.md"
            py={8}
            className="selection-wrapper-ref"
            ref={selectionWrapperRef}
          >
            {sections.map(({ id, title, show_for_partial_analysis }) => (
              (!analysisMode.is_partial || show_for_partial_analysis) && (
                <Box
                  className="dynamic-content-box"
                  key={id}
                  id={id}
                  ref={el => (sectionRefs.current[id] = el)}
                  minH="80vh"
                  mb={8}
                >
                  <Text fontSize="2xl" fontWeight="bold" mb={4}>
                    {title}
                  </Text>
                  <Box data-section-id={id}>{renderSectionContent(id)}</Box>
                </Box>
              )
            ))}
          </Container>

          {currentSectionModifiers['values']?.length && (
            <ModifiersBar
              availableModifiers={currentSectionModifiers['values']}
              mode={currentSectionModifiers['mode']}
              onModifiersChange={setAppliedModifiers}
              getSecondLayerModifiers={getSecondLayerModifiers}
            />
          )}

          <ActionButtons
            isTextActionLoading={isTextActionLoading}
            selectedText={selectedText}
            selectionPosition={selectionPosition}
            onElaborateSelect={onElaborateSelect}
            onAddToSnippetsSelect={onAddToSnippetsSelect}
            hideActions={() => setSelectedText('')}
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default AnalysisTab;
