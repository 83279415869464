
import {
    Plugin,
    PluginKey
} from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

const createPlaceholderPlugin = () => {
    return new Plugin({
        key: new PluginKey('placeholder'),
        state: {
            init(_, { doc }) {
                return DecorationSet.create(doc, []);
            },
            apply(tr, old) {
                return old.map(tr.mapping, tr.doc);
            },
        },
        props: {
            decorations(state) {
                const { doc, selection } = state;
                const decorations = [];
                const isEmpty =
                    doc.childCount === 1 &&
                    doc.firstChild.isTextblock &&
                    doc.firstChild.content.size === 0;
                const isNewBlock =
                    selection.empty &&
                    selection.$cursor &&
                    selection.$cursor.parentOffset === 0;

                if (isEmpty) {
                    decorations.push(
                        Decoration.widget(1, () => {
                            const span = document.createElement('span');
                            span.className = 'placeholder';
                            span.textContent =
                                "Write something or select text to 'Add to Notes'";
                            return span;
                        })
                    );
                } else if (isNewBlock) {
                    let pos = doc.content.size - 1;

                    decorations.push(
                        Decoration.widget(pos, () => {
                            const span = document.createElement('span');
                            span.className = 'placeholder';
                            span.innerHTML = " Press '/' for AI Commands";
                            span.style.display = 'inline-block';
                            span.style.pointerEvents = 'none';
                            return span;
                        })
                    );
                }

                return DecorationSet.create(doc, decorations);
            },
        },
    });
};

export { createPlaceholderPlugin };