import React, { useState, useEffect } from 'react';
import { Schema } from 'prosemirror-model';
import { undo, redo } from 'prosemirror-history';
import { schema } from 'prosemirror-schema-basic';
import { addListNodes } from 'prosemirror-schema-list';
import { baseKeymap, toggleMark, setBlockType } from 'prosemirror-commands';
import { wrapInList } from 'prosemirror-schema-list';
import { wrapIn } from 'prosemirror-commands';
import { Tooltip, HStack } from '@chakra-ui/react';
import './index.scss';

// Function to create SVG icons
const createSVGIcon = pathDataArray => (
  <svg viewBox="0 0 24 24" width="24" height="24">
    {pathDataArray.map((pathData, index) => (
      <path key={index} d={pathData} />
    ))}
  </svg>
);

// Toolbar component
const ToolbarMenuItems = ({ view, editor, schema }) => {
  const [isHeadingMenuExpanded, setIsHeadingMenuExpanded] = useState(false);



useEffect(() => {

  const handleEditorClick = () => {
    setIsHeadingMenuExpanded(false)
  }
  const editorRef = editor.current

  if(editorRef) {
    editorRef.addEventListener('click', handleEditorClick)
  }

  return () => {
    if (editorRef) {
      editorRef.removeEventListener('click', handleEditorClick);
    }
  };
}, []);



  const toggleHighlight = () => (state, dispatch) => {
    let { from, to } = state.selection;
    let hasBackgroundColor = false;
    state.doc.nodesBetween(from, to, node => {
      if (node.marks.some(mark => mark.type === schema.marks.backgroundColor)) {
        hasBackgroundColor = true;
      }
    });

    if (dispatch) {
      let tr = state.tr;
      if (hasBackgroundColor) {
        tr = tr.removeMark(from, to, schema.marks.backgroundColor);
      } else {
        tr = tr.addMark(from, to, schema.marks.backgroundColor.create());
      }
      dispatch(tr);
    }

    return true;
  };

  const toggleHeadingMenu = () => {
    setIsHeadingMenuExpanded(prevState => !prevState);
  };

  const menuItems = [
    {
      command: toggleMark(schema.marks.bold),
      label: 'Bold',
      className: 'menu-button',
      icon: createSVGIcon([
        'M15.2504 11.8C15.74 11.5018 16.1532 11.0934 16.4571 10.6073C16.7609 10.1212 16.9469 9.57077 17.0004 9C17.0097 8.48388 16.9172 7.971 16.7282 7.49063C16.5392 7.01027 16.2575 6.57184 15.899 6.20039C15.5406 5.82894 15.1124 5.53174 14.6391 5.32578C14.1658 5.11981 13.6565 5.00911 13.1404 5H6.65039V19H13.6504C14.1415 18.9948 14.6269 18.8929 15.0786 18.7001C15.5304 18.5073 15.9397 18.2274 16.2833 17.8764C16.6269 17.5253 16.898 17.1101 17.0811 16.6543C17.2642 16.1985 17.3557 15.7112 17.3504 15.22V15.1C17.3507 14.4071 17.1533 13.7285 16.7813 13.144C16.4093 12.5594 15.8782 12.0931 15.2504 11.8ZM8.65039 7H12.8504C13.2766 6.98681 13.6966 7.10428 14.0541 7.33665C14.4116 7.56902 14.6894 7.90517 14.8504 8.3C15.0133 8.82779 14.9606 9.39859 14.7039 9.88765C14.4472 10.3767 14.0073 10.7443 13.4804 10.91C13.2758 10.97 13.0636 11.0003 12.8504 11H8.65039V7ZM13.2504 17H8.65039V13H13.2504C13.6766 12.9868 14.0966 13.1043 14.4541 13.3367C14.8116 13.569 15.0894 13.9052 15.2504 14.3C15.4133 14.8278 15.3606 15.3986 15.1039 15.8877C14.8472 16.3767 14.4073 16.7443 13.8804 16.91C13.6758 16.97 13.4636 17.0003 13.2504 17Z',
      ]),
    },
    {
      command: toggleMark(schema.marks.italic),
      label: 'Italic',
      className: 'menu-button',
      icon: createSVGIcon([
        'M12.2176 9H14.2176L12.0176 19H10.0176L12.2176 9ZM13.8976 5C13.6998 5 13.5065 5.05865 13.342 5.16853C13.1776 5.27841 13.0494 5.43459 12.9737 5.61732C12.898 5.80004 12.8782 6.00111 12.9168 6.19509C12.9554 6.38907 13.0506 6.56725 13.1905 6.70711C13.3303 6.84696 13.5085 6.9422 13.7025 6.98079C13.8965 7.01937 14.0975 6.99957 14.2803 6.92388C14.463 6.84819 14.6192 6.72002 14.729 6.55557C14.8389 6.39112 14.8976 6.19778 14.8976 6C14.8976 5.73478 14.7922 5.48043 14.6047 5.29289C14.4171 5.10536 14.1628 5 13.8976 5Z',
      ]),
    },
    {
      command: toggleMark(schema.marks.underline),
      label: 'Underline',
      className: 'menu-button',
      icon: createSVGIcon([
        'M19.9141 20V22H5.91406V20H19.9141ZM16.9141 13.215C16.8811 13.875 16.6852 14.5165 16.3438 15.0823C16.0024 15.6481 15.5261 16.1205 14.9576 16.4572C14.389 16.794 13.7459 16.9847 13.0857 17.0122C12.4254 17.0397 11.7687 16.9033 11.1741 16.615C10.4887 16.3185 9.90747 15.8241 9.50483 15.1952C9.10219 14.5663 8.89648 13.8315 8.91406 13.085V5.005H6.91406V13.215C6.94789 14.1564 7.20291 15.0766 7.65848 15.9012C8.11404 16.7257 8.75735 17.4314 9.53633 17.9611C10.3153 18.4908 11.2081 18.8296 12.1423 18.9502C13.0766 19.0707 14.0261 18.9696 14.9141 18.655C16.0952 18.2613 17.1199 17.5012 17.8393 16.485C18.5587 15.4689 18.9352 14.2498 18.9141 13.005V5.005H16.9141V13.215ZM16.9141 5H18.9141H16.9141ZM8.91406 5H6.91406H8.91406Z',
      ]),
    },
    {
      command: toggleHighlight(),
      label: 'Highlight',
      className: 'menu-button highlight-icon',
      icon: createSVGIcon([
        'M10.1998 12.2404L17.9477 6.85027L19.1271 8.14618L13.0331 15.3536L10.1998 12.2404ZM7.86968 12.6516L12.405 17.6349C12.4989 17.7279 12.6115 17.7999 12.7353 17.8462C12.859 17.8925 12.9912 17.912 13.1231 17.9034C13.255 17.8948 13.3836 17.8583 13.5003 17.7964C13.617 17.7344 13.7193 17.6484 13.8003 17.544L21.2517 8.73102C21.4002 8.56791 21.4842 8.35635 21.4881 8.13584C21.492 7.91532 21.4156 7.70091 21.273 7.53262L18.76 4.77146C18.6059 4.61374 18.3996 4.51749 18.1797 4.50069C17.9598 4.48388 17.7413 4.54767 17.5649 4.68014L8.09127 11.271C7.97971 11.3418 7.88446 11.4356 7.81181 11.546C7.73915 11.6564 7.69076 11.7809 7.66981 11.9114C7.64887 12.0419 7.65585 12.1754 7.69031 12.303C7.72476 12.4306 7.7859 12.5494 7.86968 12.6516V12.6516ZM3.07617 18.5273L8.69353 19.4996L10.1727 18.1534L7.14377 14.8254L3.07617 18.5273Z',
        'M3 20.5H22V22.5H3V20.5Z',
      ]),
    },
    {
      command: toggleHeadingMenu,
      label: 'Heading',
      className: 'menu-button',
      icon: createSVGIcon([
        'M13.5 11H5.63333V5H3.5V11V13V19H5.63333V13H13.5V19H15.5V13V11V5H13.5V11Z',
        'M18.241 10.56C18.4515 10.77 18.7221 10.91 19.0127 10.97C19.3034 11.03 19.604 11 19.8846 10.88C20.1552 10.77 20.3957 10.57 20.5561 10.33C20.7164 10.09 20.8066 9.79 20.8066 9.5C20.8066 9.1 20.6463 8.72 20.3657 8.44C20.0851 8.16 19.7042 8 19.3034 8C19.0027 8 18.7121 8.09 18.4715 8.25C18.221 8.41 18.0306 8.65 17.9203 8.92C17.8101 9.19 17.78 9.5 17.8301 9.79C17.8903 10.08 18.0306 10.35 18.241 10.56Z',
        'M19.3034 16C19.0027 16 18.7121 16.09 18.4715 16.25C18.221 16.41 18.0306 16.65 17.9203 16.92C17.8101 17.19 17.78 17.5 17.8301 17.79C17.8903 18.08 18.0306 18.35 18.241 18.56C18.4515 18.77 18.7221 18.91 19.0127 18.97C19.3034 19.03 19.604 19 19.8846 18.88C20.1552 18.77 20.3957 18.57 20.5561 18.33C20.7164 18.09 20.8066 17.79 20.8066 17.5C20.8066 17.1 20.6463 16.72 20.3657 16.44C20.0851 16.16 19.7042 16 19.3034 16Z',
        'M19.3034 12C19.0027 12 18.7121 12.09 18.4715 12.25C18.221 12.41 18.0306 12.65 17.9203 12.92C17.8101 13.19 17.78 13.5 17.8301 13.79C17.8903 14.08 18.0306 14.35 18.241 14.56C18.4515 14.77 18.7221 14.91 19.0127 14.97C19.3034 15.03 19.604 15 19.8846 14.88C20.1552 14.77 20.3957 14.57 20.5561 14.33C20.7164 14.09 20.8066 13.79 20.8066 13.5C20.8066 13.1 20.6463 12.72 20.3657 12.44C20.0851 12.16 19.7042 12 19.3034 12Z',
      ]),
    },
    {
      command: wrapInList(schema.nodes.blockquote),
      label: 'Quote',
      className: 'menu-button',
      icon: createSVGIcon([
        'M10.6877 5L11.6258 6.3226C10.4281 7.16565 9.38283 8.20679 8.53509 9.40125C7.90354 10.3053 7.55605 11.3773 7.53709 12.4799V12.8172C7.77768 12.7854 7.9979 12.7574 8.19776 12.7332C8.38525 12.7097 8.57398 12.6974 8.76295 12.6963C9.15464 12.6849 9.54453 12.7537 9.90861 12.8986C10.2727 13.0436 10.6032 13.2615 10.8799 13.539C11.1558 13.8118 11.3729 14.1383 11.5178 14.4982C11.6627 14.8581 11.7324 15.2439 11.7226 15.6318C11.7447 16.0793 11.6774 16.5267 11.5246 16.9478C11.3718 17.369 11.1366 17.7555 10.8328 18.0848C10.5246 18.3923 10.156 18.6324 9.75013 18.79C9.3443 18.9475 8.91017 19.019 8.47526 19C7.95277 19.0112 7.43473 18.9019 6.96124 18.6807C6.48775 18.4596 6.07152 18.1324 5.74479 17.7245C5.00497 16.7989 4.62474 15.6369 4.67421 14.453C4.67141 12.6341 5.19821 10.8538 6.1903 9.32933C7.36354 7.58203 8.89697 6.10589 10.6877 5V5ZM19.0801 5L20.017 6.3226C18.8192 7.16565 17.774 8.20679 16.9262 9.40125C16.2947 10.3053 15.9472 11.3773 15.9282 12.4799V12.8172C16.1523 12.7854 16.3649 12.7574 16.566 12.7332C16.7615 12.7091 16.9584 12.6968 17.1554 12.6963C17.5445 12.681 17.9326 12.7469 18.2949 12.8898C18.6572 13.0327 18.9857 13.2495 19.2596 13.5263C19.5277 13.8057 19.7371 14.1359 19.8755 14.4975C20.0139 14.8592 20.0784 15.2448 20.0654 15.6318C20.1108 16.5315 19.7998 17.4129 19.1997 18.0848C18.8973 18.3933 18.5331 18.6343 18.131 18.792C17.7288 18.9497 17.2979 19.0206 16.8664 19C16.3407 19.0093 15.8198 18.8994 15.3427 18.6785C14.8656 18.4576 14.4447 18.1314 14.1118 17.7245C13.3579 16.8052 12.9683 15.6409 13.017 14.453C13.0231 12.6526 13.5539 10.8931 14.5445 9.3898C15.734 7.62645 17.2788 6.1312 19.0801 5V5Z',
      ]),
    },
    {
      command: wrapInList(schema.nodes.ordered_list),
      label: 'Numbered List',
      className: 'menu-button',
      icon: createSVGIcon([
        'M2.5 16H4.5V16.5H3.5V17.5H4.5V18H2.5V19H5.5V15H2.5V16ZM3.5 9H4.5V5H2.5V6H3.5V9ZM2.5 11H4.3L2.5 13.1V14H5.5V13H3.7L5.5 10.9V10H2.5V11ZM7.5 6V8H21.5V6H7.5ZM7.5 18H21.5V16H7.5V18ZM7.5 13H21.5V11H7.5V13Z',
      ]),
    },
    {
      command: wrapInList(schema.nodes.bullet_list),
      label: 'Bullet Points',
      className: 'menu-button',
      icon: createSVGIcon([
        'M4.45703 10.5C3.65703 10.5 2.95703 11.2 2.95703 12C2.95703 12.8 3.65703 13.5 4.45703 13.5C5.25703 13.5 5.95703 12.8 5.95703 12C5.95703 11.2 5.25703 10.5 4.45703 10.5ZM4.45703 5.5C3.65703 5.5 2.95703 6.2 2.95703 7C2.95703 7.8 3.65703 8.5 4.45703 8.5C5.25703 8.5 5.95703 7.8 5.95703 7C5.95703 6.2 5.25703 5.5 4.45703 5.5ZM4.45703 15.5C3.65703 15.5 2.95703 16.2 2.95703 17C2.95703 17.8 3.65703 18.5 4.45703 18.5C5.25703 18.5 5.95703 17.8 5.95703 17C5.95703 16.2 5.25703 15.5 4.45703 15.5ZM7.95703 6V8H21.957V6H7.95703ZM7.95703 18H21.957V16H7.95703V18ZM7.95703 13H21.957V11H7.95703V13Z',
      ]),
    },
    {
      command: (state, dispatch, view) => {
        let href = prompt('Enter the URL');
        if (!href) return false;
        return toggleMark(schema.marks.link, { href })(state, dispatch);
      },
      label: 'Add Link',
      className: 'menu-button',
      icon: createSVGIcon([
        'M11 17.001H7C5.67392 17.001 4.40215 16.4742 3.46447 15.5365C2.52678 14.5988 2 13.3271 2 12.001C2 10.6749 2.52678 9.40312 3.46447 8.46544C4.40215 7.52776 5.67392 7.00098 7 7.00098H11V9.00098H7C6.20435 9.00098 5.44129 9.31705 4.87868 9.87966C4.31607 10.4423 4 11.2053 4 12.001C4 12.7966 4.31607 13.5597 4.87868 14.1223C5.44129 14.6849 6.20435 15.001 7 15.001H11V17.001ZM17 7.00098H13V9.00098H17C17.7956 9.00098 18.5587 9.31705 19.1213 9.87966C19.6839 10.4423 20 11.2053 20 12.001C20 12.7966 19.6839 13.5597 19.1213 14.1223C18.5587 14.6849 17.7956 15.001 17 15.001H13V17.001H17C18.3261 17.001 19.5979 16.4742 20.5355 15.5365C21.4732 14.5988 22 13.3271 22 12.001C22 10.6749 21.4732 9.40312 20.5355 8.46544C19.5979 7.52776 18.3261 7.00098 17 7.00098V7.00098ZM16 11.001H8V13.001H16V11.001Z',
      ]),
    },
  ];

  const subMenuOptions = [
    {
      command: undo,
      label: 'Undo',
      className: 'menu-button',
      icon: createSVGIcon([
        'M10.4 9.4C8.7 9.7 7.2 10.3 5.8 11.4L3 8.5V15.5H10L7.3 12.8C11 10.2 16.1 11 18.8 14.7C19 15 19.2 15.2 19.3 15.5L21.1 14.6C18.9 10.8 14.7 8.7 10.4 9.4Z',
      ]),
    },
    {
      command: redo,
      label: 'Redo',
      className: 'menu-button',
      icon: createSVGIcon([
        'M13.6 9.4C15.3 9.7 16.8 10.3 18.2 11.4L21 8.5V15.5H14L16.7 12.8C13 10.1 7.9 11 5.3 14.7C5.1 15 4.9 15.2 4.8 15.5L3 14.6C5.1 10.8 9.3 8.7 13.6 9.4Z',
      ]),
    },
  ];

  const setHeading = level => {
    const { state, dispatch } = view.current;
    const type = schema.nodes.heading;

    if (setBlockType(type, { level })(state, dispatch)) {
      console.log(`Heading set to level ${level}`);
    } else {
      console.log(`Failed to set heading to level ${level}`);
    }

    view.current.focus();
  };

  return (
    <div className="menu-bar">
      <div className="menu-actions">
        <HStack className="menu-left">
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              {index === 4 || index === 6 || index === 8 ? (
                <div className="toolbar-rule" />
              ) : null}
              <Tooltip label={item.label}>
                <button
                  onClick={() =>
                    item.command(view.current.state, view.current.dispatch)
                  }
                  className={`action-icon ${item.className}`}
                >
                  {item.icon}
                </button>
              </Tooltip>
            </React.Fragment>
          ))}
        </HStack>

        <HStack className="menu-right">
          {subMenuOptions.map((item, index) => (
            <React.Fragment key={index}>
              <Tooltip label={item.label}>
                <button
                  onClick={() =>
                    item.command(view.current.state, view.current.dispatch)
                  }
                  className={`action-icon ${item.className}`}
                >
                  {item.icon}
                </button>
              </Tooltip>
            </React.Fragment>
          ))}
        </HStack>
      </div>
      <div
        className={`heading-menu ${isHeadingMenuExpanded ? 'expanded' : ''}`}
      >
        <HStack justify={'start'} align={'baseline'} spacing={6}>
          <button className="heading-1" onClick={() => setHeading(1)}>
            Heading 1
          </button>
          <button className="heading-2" onClick={() => setHeading(2)}>
            Heading 2
          </button>
          <button className="heading-3" onClick={() => setHeading(3)}>
            Heading 3
          </button>
        </HStack>
      </div>
    </div>
  );
};

export default ToolbarMenuItems;
