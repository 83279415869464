import React from "react"

const AuditHistoryPageIcon = ({ isActive }) => {
    const fillColour = isActive ? '#004732' : '#979797'
    return (<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.1168 0.320312C15.4125 0.320312 14.8362 0.896428 14.8362 1.60057C14.8362 2.30471 15.4125 2.88082 16.1168 2.88082C23.5245 2.88082 29.5557 8.90443 29.5557 16.3171C29.5557 23.7298 23.5309 29.7534 16.1168 29.7534C8.70259 29.7534 2.67778 23.7298 2.67778 16.3171C2.67778 12.662 4.17598 9.16688 6.80103 6.63838L7.12756 8.57156C7.23 9.19889 7.77421 9.64058 8.38886 9.64058C8.45929 9.64058 8.52972 9.64058 8.60014 9.62137C9.29802 9.50615 9.76541 8.84682 9.65016 8.14908L8.79222 3.05366C8.7346 2.71439 8.54893 2.41353 8.26722 2.22149C7.98551 2.02945 7.63976 1.95264 7.30043 2.01025L2.21039 2.93203C1.51251 3.06006 1.05153 3.72579 1.17958 4.41713C1.30763 5.11487 1.9735 5.57576 2.66498 5.44774L4.74581 5.07006C1.78783 8.05306 0.110352 12.0987 0.110352 16.3235C0.110352 25.1445 7.28763 32.3203 16.1104 32.3203C24.9331 32.3203 32.1104 25.1445 32.1104 16.3235C32.1104 7.50255 24.9395 0.320312 16.1168 0.320312Z" fill={fillColour}/>
        <path d="M6.22512 22.027C6.4428 22.4879 6.90379 22.7567 7.38398 22.7567C7.56965 22.7567 7.75533 22.7183 7.9346 22.6351L17.5769 18.0646C17.9802 17.8726 18.2555 17.4821 18.3003 17.0404L19.1519 8.76994C19.2223 8.0658 18.7101 7.43847 18.0058 7.36806C17.3015 7.28484 16.6741 7.80975 16.6037 8.51389L15.829 16.0674L6.83976 20.3307C6.1995 20.6315 5.9306 21.3997 6.23152 22.0334L6.22512 22.027Z" fill={fillColour}/>
        </svg>
        
    )
}

export default AuditHistoryPageIcon;
