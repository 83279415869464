import React, { useState, useEffect } from 'react';
import { Box, Spinner, Text, VStack, Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { setCookie, getCookie } from './../../helpers/cookies';

const VerifyLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyMagicLink = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!token) {
        setError('Invalid magic link. Please request a new one.');
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/v1/verify_magic_link`, { token });
        
        if (response.data && response.data.token) {
          const authToken = response.data.token
          axios.defaults.headers.common['auth-token'] = authToken;
          setCookie('auth-token', authToken, 30);
          navigate("/dispute_analysis")
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (err) {
        setError(err.response?.data?.error || 'An error occurred during login. Please try again.');
        setIsLoading(false);
      }
    };

    verifyMagicLink();
  }, [location, navigate]);

  if (error) {
    return (
      <Box maxWidth="400px" margin="auto" marginTop="50px">
        <Alert status="error">
          <AlertIcon />
          <VStack align="start">
            <AlertTitle>Login Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </VStack>
        </Alert>
      </Box>
    );
  }

  return (
    <Box maxWidth="400px" margin="auto" marginTop="50px">
      <VStack spacing={4}>
        <Spinner size="xl" color="blue.500" />
        <Text fontSize="xl" fontWeight="bold">
          Logging you in...
        </Text>
      </VStack>
    </Box>
  );
};

export default VerifyLogin;