import React, { createContext, useReducer, useContext } from 'react';
import { Node } from 'prosemirror-model';
import { schema } from 'prosemirror-schema-basic';

function appendParagraph(docJson, textToAdd) {
  const newParagraph = {
    type: "paragraph",
    content: [
      {
        type: "text",
        text: textToAdd
      }
    ]
  };

  // Clone the document JSON to avoid mutating the original directly
  let newDocJson = JSON.parse(JSON.stringify(docJson));

  // Assume the document JSON has a 'content' array at the top-level
  if (newDocJson.content) {
    // Append new paragraph
    newDocJson.content.push(newParagraph);
  } else {
    // Initialize content if not present
    newDocJson.content = [newParagraph];
  }

  return newDocJson;
}

const initialState = {
  notes: null,
  caseID: null
};

const NoteTakerStateContext = createContext();
const NoteTakerDispatchContext = createContext(() => { });

const noteTakerReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CASE_ID':
      let storedNotes;
      try {
        storedNotes = localStorage.getItem(`notes_${action.payload}`);
        if (storedNotes) {
          storedNotes = JSON.parse(storedNotes);
        } else {
          // Create an empty note structure if no stored notes exist
          storedNotes = { type: "doc", content: [{
            "type": "paragraph"
          }] };
        }
      } catch (error) {
        storedNotes = { type: "doc", content: [] };
      }
      console.log('Loaded notes:', storedNotes);
      return {
        ...state,
        caseID: action.payload,
        notes: storedNotes
      };

    case 'SET_NOTES':
      const notesToSave = action.payload || { type: "doc", content: [{
        "type": "paragraph"
      }] };
      try {
        localStorage.setItem(`notes_${state.caseID}`, JSON.stringify(notesToSave));
      } catch (error) {
        console.error('Error saving notes:', error);
      }
      return {
        ...state,
        notes: notesToSave
      };

    case 'ADD_TO_NOTES':
      let notes;
      try {
        notes = state.notes === "" ? { type: "doc", content: [{
          "type": "paragraph"
        }] } : (typeof state.notes === 'string' ? JSON.parse(state.notes) : state.notes);
      } catch (error) {
        notes = { type: "doc", content: [] };
      }

      const updatedNotes = appendParagraph(notes, action.payload);

      try {
        localStorage.setItem(`notes_${state.caseID}`, JSON.stringify(updatedNotes));
      } catch (error) {
        console.error('Error saving updated notes:', error);
      }

      return {
        ...state,
        notes: updatedNotes,
      };

    default:
      return state;
  }
};

export const NoteTakerStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(noteTakerReducer, initialState);

  return (
    <NoteTakerStateContext.Provider value={state}>
      <NoteTakerDispatchContext.Provider value={dispatch}>
        {children}
      </NoteTakerDispatchContext.Provider>
    </NoteTakerStateContext.Provider>
  );
};

export const useNoteTakerState = () => useContext(NoteTakerStateContext);
export const useNoteTakerDispatch = () => useContext(NoteTakerDispatchContext);
