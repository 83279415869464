import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Card,
  Text,
  Box,
  VStack,
  HStack,
  Select,
  Button,
  useDisclosure,
  Divider,
  Flex,
  Switch,
  Avatar
} from '@chakra-ui/react';
import moment from 'moment';
import { ReactComponent as InviteUserIcon } from '../../images/invite-user.svg';
import { ReactComponent as OptionsIcons } from '../../images/options.svg';
import InviteMembersModal from './InviteMembersModal';
import { useStatsigClient } from '@statsig/react-bindings';
import { useGateValue } from '@statsig/react-bindings'
import { formatDistanceToNow } from 'date-fns'

const Settings = () => {
  const [members, setMembers] = useState([]);
  const [orgDomain, setOrgDomain] = useState('');
  const { client } = useStatsigClient();
  const isEmailPrefsEnabled = useGateValue("email_prefs_enabled");
  const [inviteMode, setInviteMode] = useState(null)

  useEffect(() => {
    fetchAnalysisHistory()
  }, [])


  const fetchAnalysisHistory = async () => {
    const url = `${process.env.REACT_APP_BASE_PATH}/members`;
    const response = await axios.get(url)
    setMembers(response.data.members)
    setOrgDomain(response.data.org_domain)
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [invitedMembers, setInvitedMembers] = useState([]);

  useEffect(() => {
    fetchInvitedMembers();
  }, []);

  const fetchInvitedMembers = async () => {
    try {
      const url = `${process.env.REACT_APP_BASE_PATH}/invited_members`;
      const response = await axios.get(url);
      setInvitedMembers(response.data.invited_members);
    } catch (error) {
      console.error("Error fetching invited members:", error);
    }
  };

  const formatTimeLeft = (expirationTime) => {
    const now = new Date();
    const expiration = new Date(expirationTime);
    const timeLeft = expiration - now;

    if (timeLeft <= 0) {
      return "Expired";
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} left`;
    } else {
      return `${hours} hour${hours > 1 ? 's' : ''} left`;
    }
  };

  const handleInvite = (mode) => {
    setInviteMode(mode)
    onOpen()
  }

  const handleInviteModalClose = () => {
    setInviteMode(null)
    onClose()
  }

  return (
    <div className="page-content-margin">
      <Container maxW="container.md">
        {isEmailPrefsEnabled && <Card bg="white" mt={10} p={[4, 6]} borderRadius={4}>
          <Text variant={'section_heading'} mb={4}>
            Email Preferences
          </Text>

          <Flex align={'center'} justify={'space-between'}>
            <VStack spacing={0} align={'start'} display={'flex'}>
              <Text color={'gray.800'} fontWeight={'600'}>New member</Text>
              <Text>Notify me when a new member joins</Text>
            </VStack>
            <Switch variant={'default'} size={'lg'}></Switch>
          </Flex>


          <Divider variant={'default'} my={4} />

          <Flex align={'center'} justify={'space-between'}>
            <VStack spacing={0} align={'start'} display={'flex'}>
              <Text color={'gray.800'} fontWeight={'600'}>New Case Added</Text>
              <Text>Allow members from the same domain</Text>
            </VStack>
            <Switch variant={'default'} size={'lg'}></Switch>
          </Flex>
        </Card>}

        <Card bg="white" mt={10} p={[4, 6]} borderRadius={4}>
          <Text variant={'section_heading'} mb={4}>
            Invitations
          </Text>

          <Flex align={'center'} justify={'space-between'}>
            <VStack spacing={0} align={'start'} display={'flex'}>
              <Text color={'gray.800'} fontWeight={'600'}>Whitelist domain</Text>
              <Text maxW={'95%'}>
                Allow anyone from <b>{orgDomain}</b> to join as a <b>Read Only Member</b>
              </Text>
            </VStack>
            <Switch variant={'default'} size={'lg'}></Switch>
          </Flex>

          {invitedMembers && invitedMembers.length > 0 && (
            <Box>
              <Divider variant={'default'} my={4} />
              <Text color={'gray.800'} fontWeight="bold" mb={2}>Pending Invitations</Text>
              <VStack spacing={2} align="stretch">
                {invitedMembers.map((member, index) => (
                  <Flex key={index} justify="space-between" align="center">
                    <Text>{member.email}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {formatDistanceToNow(new Date(member.expires_at), { addSuffix: false })} left to accept
                    </Text>
                  </Flex>
                ))}
              </VStack>
            </Box>
          )}
        </Card>


        <Card bg="white" mt={6} p={[4, 6]} borderRadius={4}>
          <HStack width={'100%'} justify={'space-between'} alignItems={'flex-start'}>
            <Box width={'60%'}>
              <Flex justify={'space-between'} alignItems={'baseline'}>
                <Text variant={'section_heading'} mb={0}>
                Workspace Members
                </Text>
              </Flex>
              <Text mb={4}>
              Members who have access to all cases in the workspace
              </Text>
            </Box>
            
            <Button
                variant={'secondary'}
                onClick={() => handleInvite('member')}
              >
                <InviteUserIcon fill="#fff" />
              </Button>
          </HStack>
          <VStack spacing={2} align="stretch" divider={<Divider />}>
            {members.filter(member => member?.role?.id !== 'WORKSPACE_EXTERNAL_COLLABORATOR').map((member, index) => (
              <Box key={index} py={2}>
                <Flex justify="space-between" align="center" width="100%">
                  <Flex align="center" width="50%">
                    <Avatar size="md" src={member?.profile_picture_url} mr={4} />
                    <Box>
                      <Text fontWeight="bold">{member?.full_name}</Text>
                      <Text fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}>{member?.email}</Text>
                    </Box>
                  </Flex>
                  <Box width="40%">
                    <Text>{member?.role?.name}</Text>
                  </Box>
                  <Box width="10%" textAlign="right">
                    <OptionsIcons style={{ cursor: 'pointer', rotate: '90deg' }} fill="#a8a8a8" />
                  </Box>
                </Flex>
              </Box>
            ))}
          </VStack>
        </Card>

        <Card bg="white" mt={6} p={[4, 6]} borderRadius={4}>
          <HStack width={'100%'} justify={'space-between'} alignItems={'flex-start'}>
            <Box width={'60%'}>
              <Flex justify={'space-between'} alignItems={'baseline'}>
                <Text variant={'section_heading'} mb={0}>
                  External Collaborators
                </Text>
              </Flex>
              <Text mb={4}>
                External members who can be granted access to specific cases
              </Text>
            </Box>
            
            <Button
                variant={'secondary'}
                onClick={() => handleInvite('external_collaborator')}
              >
                <InviteUserIcon fill="#fff" />
              </Button>
          </HStack>
          {members.filter(member => member?.role?.id === 'WORKSPACE_EXTERNAL_COLLABORATOR').length > 0 ? (
            <VStack spacing={2} align="stretch" divider={<Divider />}>
              {members.filter(member => member?.role?.id === 'WORKSPACE_EXTERNAL_COLLABORATOR').map(member => (
                <Box key={member.member_id} py={2}>
                  <HStack justify="space-between" align="center">
                    <VStack align="start" spacing={0}>
                      <HStack spacing={4} mb={1}>
                        <Avatar size="md" src={member.profile_picture_url} />
                        <VStack align={'start'} spacing={0}>
                          <Text fontWeight="bold">{member.full_name}</Text>
                          <Text fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}>{member.email}</Text>
                        </VStack>
                      </HStack>
                    </VStack>
                  </HStack>
                </Box>
              ))}
            </VStack>
          ) : (
            <Box textAlign="center" py={4}>
              <Text fontSize={'lg'} color={'gray.500'} mb={4}>No external collaborators added</Text>
            </Box>
          )}
        </Card>
      </Container>

      <InviteMembersModal inviteMode={inviteMode} orgDomain={orgDomain} isOpen={isOpen} onClose={handleInviteModalClose} />
    </div>
  );
};

export default Settings;
