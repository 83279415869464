import React, { useState, useEffect } from 'react';
import { Box, Button, HStack, Text, Container, VStack } from '@chakra-ui/react';
import { CloseIcon, AddIcon, MinusIcon } from '@chakra-ui/icons';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);
const MotionButton = motion(Button);

const ModifiersBar = ({
  availableModifiers,
  mode = 'exclusive',
  onModifiersChange,
  getSecondLayerModifiers,
}) => {
  const [selectedModifiers, setSelectedModifiers] = useState([]);
  const [secondLayerModifiers, setSecondLayerModifiers] = useState([]);
  const [animatingParent, setAnimatingParent] = useState(false);

  useEffect(() => {
    onModifiersChange(selectedModifiers);
  }, [selectedModifiers]);

  useEffect(() => {
    if (mode === 'exclusive' || mode === 'tiered') {
      const initializedModifiers = [availableModifiers[0].key];
      setSelectedModifiers(initializedModifiers);
      if (mode === 'tiered') {
        const secondLayerModifiers = getSecondLayerModifiers(initializedModifiers);
        if (secondLayerModifiers?.length) {
          setSecondLayerModifiers(secondLayerModifiers);
        }
      }
    }
  }, [availableModifiers, mode]);

  const handleModifierClick = async (modifier) => {
    if (mode === 'exclusive' || mode === 'tiered') {
      setAnimatingParent(true);
      await new Promise(resolve => setTimeout(resolve, 300)); // Wait for exit animation
      setSelectedModifiers([modifier]);
      if (mode === 'tiered') {
        setSecondLayerModifiers(getSecondLayerModifiers([modifier]));
      }
      setAnimatingParent(false);
    } else if (mode === 'additive') {
      setSelectedModifiers(prev =>
        prev.includes(modifier)
          ? prev.filter(m => m !== modifier)
          : [...prev, modifier]
      );
    }
  };

  const handleSecondLayerModifierClicked = (modifier) => {
    setSelectedModifiers(prev =>
      prev.includes(modifier)
        ? prev.filter(m => m !== modifier)
        : [...prev, modifier]
    );
  };

  const handleClearAll = () => {
    setSelectedModifiers([]);
  };

  const parentVariants = {
    exit: { y: -20, scale: 0.8, opacity: 0 },
    enter: { y: 0, scale: 1, opacity: 1 },
  };

  const childVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: { delay: i * 0.1 },
    }),
  };

  return (
    <Box
      className="modifiers-bar"
      position="sticky"
      bottom={0}
      p={[2, 4]}
      bg="white"
      borderTopWidth={1}
      boxShadow="0 -2px 10px rgba(0,0,0,0.1)"
    >
      <Container p={0}>
        <Box overflowX="auto" whiteSpace="nowrap">
          <HStack spacing={4} alignItems="center">
            <AnimatePresence mode="popLayout">
              {availableModifiers.map((modifier, index) => (
                <MotionBox
                  key={modifier.key}
                  layout
                  variants={parentVariants}
                  initial="enter"
                  animate="enter"
                  exit="exit"
                  transition={{ duration: 0.3 }}
                >
                  <MotionButton
                    onClick={() => handleModifierClick(modifier.key)}
                    backgroundColor={
                      selectedModifiers.includes(modifier.key) ? '#F3EEF7' : '#F7F7F7'
                    }
                    color={
                      selectedModifiers.includes(modifier.key) ? '#5000B2' : '#6E6E6E'
                    }
                    size="md"
                    borderRadius="8px"
                    textTransform="capitalize"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {modifier.title}
                    {mode === 'additive' && selectedModifiers.includes(modifier.key) && (
                      <Text as="span" ml={2} fontWeight="bold">
                        <CloseIcon fill="#5000B2" w={3} />
                      </Text>
                    )}
                  </MotionButton>
                </MotionBox>
              ))}
            </AnimatePresence>

            {mode === 'additive' && selectedModifiers?.length > 0 && (
              <Button
              style={{
                position: 'absolute',
                right: '40px'
              }}
                onClick={handleClearAll}
                variant="ghost"
                size="sm"
                colorScheme="gray"
              >
                Clear All
              </Button>
            )}

            {mode === 'tiered' && secondLayerModifiers?.length > 0 && !animatingParent && (
              <AnimatePresence>
                {secondLayerModifiers.map((modifier, index) => (
                  <MotionBox
                    key={modifier}
                    custom={index}
                    variants={childVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                  >
                    <Button
                      onClick={() => handleSecondLayerModifierClicked(modifier)}
                      backgroundColor={
                        selectedModifiers.includes(modifier) ? '#E6F7FF' : '#F7F7F7'
                      }
                      color={
                        selectedModifiers.includes(modifier) ? '#1890FF' : '#6E6E6E'
                      }
                      size="sm"
                      borderRadius="8px"
                      textTransform="capitalize"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {modifier}
                      {selectedModifiers.includes(modifier) ? (
                        <MinusIcon ml={2} w={3} />
                      ) : (
                        <AddIcon ml={2} w={3} />
                      )}
                    </Button>
                  </MotionBox>
                ))}
              </AnimatePresence>
            )}
          </HStack>
        </Box>
      </Container>
    </Box>
  );
};

export default ModifiersBar;