import React, { useState, useEffect } from "react";
import axios from 'axios';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Text,
  Box
} from '@chakra-ui/react';
import roles from '../../constants/roles.json';
import SelectDropdown from '../../components/SelectDropdown/index.jsx';
import { ChevronDownIcon } from '@chakra-ui/icons';

const InviteMembersModal = ({ inviteMode, orgDomain, isOpen, onClose }) => {
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isExternalEmail, setIsExternalEmail] = useState(false);
  const [roles, setRoles] = useState([]);
  const toast = useToast();

  useEffect(() => {
  }, [inviteMode])

  useEffect(() => {
    if (isOpen) {
      setNewUserEmail('');
      setNewUserRole(null);
      setIsExternalEmail(false);
      if (inviteMode) {
        setNewUserRole(inviteMode === 'member' ? 'WORKSPACE_READ_ONLY_MEMBER' : 'WORKSPACE_EXTERNAL_COLLABORATOR');
      }
    }
  }, [isOpen, inviteMode])

  useEffect(() => {
    fetchRoles()
  }, [])


  const fetchRoles = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_PATH}/roles`)
    setRoles(response.data.roles)
  }

  useEffect(() => {
    validateEmail(newUserEmail);
  }, [newUserEmail, orgDomain]);

  const validateEmail = (email) => {
    if (email.includes('@')) {
      const emailDomain = email.split('@')[1];
      const isExternal = !orgDomain.includes(emailDomain);
      setIsExternalEmail(isExternal);
      if (isExternal) {
        setNewUserRole('WORKSPACE_EXTERNAL_COLLABORATOR');
      }
    }
  };

  const handleInviteUser = async () => {
    try {
      setIsLoading(true);
      const roleId = isExternalEmail ? 'WORKSPACE_EXTERNAL_COLLABORATOR' : newUserRole;
      const response = await axios.post(`${process.env.REACT_APP_BASE_PATH}/invite_member`, {
        email: newUserEmail,
        role_id: roleId
      });

      if (response.status === 200) {
        setIsLoading(false);
        toast({
          title: "User Invited",
          description: "The member has been invited via email.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while inviting the member.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }

    setNewUserEmail('');
    setNewUserRole('read_only_member');
    onClose();
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Invite New User</ModalHeader>
        <ModalBody>
          <Input
            variant={'default'}
            placeholder="Enter email address"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
            mb={4}
          />
          <Menu variant={'default'}>
            <MenuButton as={Button} isDisabled={isExternalEmail} fullWidth variant={'default_muted'} fontFamily={'Inter'} fontWeight={500} rightIcon={<ChevronDownIcon />}>
              {roles.find(role => role.id === newUserRole)?.name || newUserRole}
            </MenuButton>
            <MenuList>
              {roles.map((role) => (
                <MenuItem key={role.id} onClick={() => setNewUserRole(role.id)}>
                  {role.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          {isExternalEmail && <Box mt={2}>
            <Text>Email domain not in organization domain can only be external collaborator</Text>
          </Box>}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose} mr={2}>
            Cancel
          </Button>
          <Button variant={'secondary'} mr={3} onClick={handleInviteUser} isLoading={isLoading}>
            Send Invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default InviteMembersModal