import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Textarea,
  IconButton,
  HStack,
  Image,
  Icon,
  Text,
  VStack,
  Progress,
  useToast,
  Card,
  Flex,
  Button,
  Tooltip
} from '@chakra-ui/react';
import axios from 'axios';

import { ReactComponent as SendIcon } from '../../images/send.svg';
import { ReactComponent as AttachIcon } from '../../images/attach.svg';
import { ReactComponent as CancelFilledIcon } from '../../images/cancel_filled.svg';
import { ReactComponent as DocIcon } from '../../images/doc.svg';

const CommentBox = ({ caseID, fetchComments, parentCommentID }) => {
  const [commentText, setCommentText] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileID, setFileID] = useState(null);
  const fileInputRef = useRef(null);
  const toast = useToast();

  const handleTextChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      handleFileUpload(file);
    }
  };

  const handleAddComment = async () => {
    if (!commentText.trim() && !fileID) return;
    const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/add_comment`;
    try {
      await axios.post(url, {
        case_id: caseID,
        comment_body: commentText,
        file_id: fileID,
        parent_comment_id: parentCommentID
      });
      fetchComments();
      setCommentText('');
      setFileID(null);
      setSelectedFile(null);
    } catch (error) {
      toast({
        title: "Error adding comment",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddComment();
    }
  };

  const handleFileUpload = async (file) => {
    setIsUploading(true);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const url = `${process.env.REACT_APP_DOC_INTELLIGENCE_PATH}/comment_attachment?case_id=${caseID}`;
      const response = await axios.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
      setFileID(response.data.file_id);
      setIsUploading(false);
      toast({
        title: "File uploaded successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsUploading(false);
      setSelectedFile(null);
      toast({
        title: "Error uploading file",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFileID(null);
  };

  const renderFilePreview = () => {
    if (!selectedFile) return null;

    const isImage = selectedFile.type.startsWith('image/');
    const isPdfOrDocx = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(selectedFile.type);

    if (isImage) {
      return (
        <Flex position="relative" mt={2}>
          <Image
            src={URL.createObjectURL(selectedFile)}
            alt="Preview"
            maxH="100px"
            objectFit="contain"
          />
          <IconButton
            icon={<Icon as={CancelFilledIcon} />}
            size="xs"
            position="absolute"
            top={0}
            right={0}
            onClick={handleRemoveFile}
            opacity={0}
            _groupHover={{ opacity: 1 }}
          />
        </Flex>
      );
    } else if (isPdfOrDocx) {
      return (
        <Flex>
          <Card mx={2} my={2} p={2} borderRadius="md" border={'1px solid #e0e0e0'}>
            <HStack>
              <DocIcon fill='#7b7b7b' height={'24px'} width={'24px'} />
              <HStack justifyContent="space-between" spacing={0}>
                <Text>{selectedFile.name}</Text>
                <Button
                  variant={'ghost'}
                  size={'sm'}
                  onClick={handleRemoveFile}
                >
                  <CancelFilledIcon height={'16px'} width={'16px'} fill='#cfcfcf' />
                </Button>
              </HStack>
            </HStack>
            {isUploading && <Progress size="xs" value={uploadProgress} mt={2} />}
          </Card>
        </Flex>
      );
    }

    return null;
  };

  return (
    <Box className='comment-box' position="relative" border={"1px solid #e0e0e0"} borderRadius={"md"} bg={"white"}>
      <Box role="group">
        {renderFilePreview()}
      </Box>
      <Textarea
        value={commentText}
        onChange={handleTextChange}
        onKeyDown={handleKeyPress}
        placeholder={parentCommentID ? "Leave a reply" : "Add a comment"}
        rows={parentCommentID ? "2" : "3"}
        background={'transparent'}
        size={"md"}
        border={"none"}
        pr="80px"
      />
      <HStack position="absolute" zIndex={99} bottom="8px" right="8px" spacing={2}>
        <Tooltip label="Attach file">
          <IconButton
            icon={<Icon as={AttachIcon} fill="#A8A8A8" w="22px" h="22px" />}
            variant="ghost"
            cursor="pointer"
            onClick={handleAttachmentClick}
            size="sm"
          />
        </Tooltip>
        <Tooltip label="Post comment">
        <IconButton
          isDisabled={!commentText.length}
          icon={<Icon as={SendIcon} w="22px" h="22px" />}
          variant="default"
          cursor="pointer"
          onClick={handleAddComment}
          size="sm"
          />
        </Tooltip>
      </HStack>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        accept="image/*,.pdf,.docx"
      />
    </Box>
  );
};

export default CommentBox;
